import React, { useState } from 'react'
import { BaseLogo, getLogoColors, isDigital } from '../../../../core/models/entities/BaseLogo'
import IconButton from '../../../components/LogoButton/IconButton'
import { DigitalColorElement } from '../../../components/Logo/DigitalColorElement'
import { VectorColorElement } from '../../../components/Logo/VectorColorElement'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { LogoSize } from '../../../components/Logo/LogoSize'
import Tooltip from '@mui/material/Tooltip'
import { LogoPrices } from '../../../components/Logo/LogoPrices'
import { formatDate } from '../../../../utils/functions/DateUtil'
import { LogoStatusElement } from '../../../components/Logo/LogoStatusElement'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { LogoStorage } from '../../../components/Logo/LogoStorage'
import { ConfirmationGuard } from '../../../components/ConfirmationGuard'
import { LogoTransferMethod } from '../../../components/Logo/LogoTransferMethod'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { getLogoState, LogoState } from '../vmHooks/useFunctionalListLogosViewModel'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { ApprovalVoting, Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import {
  AddToCartIcon,
  EditIcon,
  NoteIcon,
  TrashcanCrossIcon
} from '../../../../resources/icons/Icons'
import { Stepper } from '../../../components/Buttons/Stepper'
import CustomerTooltip from '../../../components/Organization/CustomerTooltip'
import { useAddLogoToOrderDialog } from '../../../components/Dialogs/add-logo-to-design-order-dialog'

interface LogoListItemComponent {
  logo: BaseLogo
  columns: ColumnSettings
  onSeeRejectNote?: (logo: BaseLogo) => void
  onAddToCart: (logo: BaseLogo, quantity: number) => void
  onEditLogo: (logo: BaseLogo) => void
  onDeleteLogo: (logo: BaseLogo) => void
  onInitiateApproval: (logo: BaseLogo) => void
  onPlaceVerdict: (logo: BaseLogo, verdict: Verdict) => void
  onRejectAndDelete?: (logo: BaseLogo, note?: string) => void
}

export function LogoRowItem({
  logo,
  columns,
  onSeeRejectNote,
  onAddToCart,
  onEditLogo,
  onDeleteLogo,
  onInitiateApproval,
  onPlaceVerdict,
  onRejectAndDelete
}: LogoListItemComponent) {
  const hasCustomizableQuantity = ["NumberLogo", "NameLogo"].includes(logo?.logoType)
  const [quantityForOrder, setQuantityForOrder] = useState<number>(hasCustomizableQuantity ? 0 : 10)
  const { colors: colorOptions, colorsEmbroidery } = useLogoOptionsContext()
  const { role } = useProfileContext()

  // HACK: The performance is harrowing. These dialogs need to go...
  const { component, open } = useAddLogoToOrderDialog(undefined, logo)
  
  function SeeRejectNote(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    onSeeRejectNote?.call(null, logo)
  }

  function EditLogo(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    onEditLogo(logo)
  }

  function AddToCart(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    open()
    // We don't need to handle adding to cart here,
    //  because the AddLogoToOrderDialog component handles this internally...
    // onAddToCart(logo, quantityForOrder)
  }

  async function Delete(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    onDeleteLogo(logo)
  }

  function handleStartApproval(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    onInitiateApproval(logo)
  }

  const overallState = getLogoState(logo)
  const colors = getLogoColors(logo, colorOptions, colorsEmbroidery)

  let colorsElements = isDigital(logo) ? (
    <DigitalColorElement />
  ) : (
    <div className="grid grid-rows-4 grid-flow-col">
      {colors.map((co) => (
        <VectorColorElement key={co.id} showName={true} color={co} />
      ))}
    </div>
  )

  return (
    <StrucRow className={'h-[100px] items-start py-4'}>
      <StrucCol
        size={columns['thumbnail'].weight}
        className={'items-center !justify-around space-y-2 h-full'}>
        <div className={'flex align-middle pr-1.5 max-h-[80px] w-fit mr-5'}>
          <LogoImage
            logo={logo}
            useHighRes={false}
            className={
              'bg-white rounded hover:border hover:p-2 max-h-[74px] origin-top-left hover:scale-[3] hover:ease-out transition-all duration-150 ease-out'
            }
          />
        </div>
      </StrucCol>
      <StrucCol className={'space-y-0.5'} size={columns['product.sku'].weight}>
        <Tooltip title={logo.product?.sku ?? '-'} placement={'top-start'}>
          <span className={'font-medium whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
            {logo.product?.sku ?? '-'}
          </span>
        </Tooltip>
        <LogoSize logo={logo} format="WWxYYmm" />
      </StrucCol>
      <StrucCol className={'space-y-0.5'} size={columns[nameOf<BaseLogo>((x) => x.title)].weight}>
        <Tooltip title={logo.title} placement={'top-start'}>
          <span className={'font-bold whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
            {logo.title}
          </span>
        </Tooltip>
        <LogoTransferMethod logo={logo} />
      </StrucCol>
      <StrucCol size={columns['colors'].weight}>{colorsElements}</StrucCol>
      <StrucCol size={columns[nameOf<BaseLogo>((x) => x.customer)].weight}>
        <CustomerTooltip customer={logo.customer} />
      </StrucCol>
      <StrucCol size={columns['priceSort'].weight}>
        {logo.product ? <LogoPrices logo={logo} /> : <span>-</span>}
      </StrucCol>
      <StrucCol size={columns['product.storagecount'].weight}>
        <LogoStorage logo={logo} />
      </StrucCol>
      <StrucCol size={columns[nameOf<BaseLogo>((x) => x.createdAtUtc)].weight}>
        {formatDate(logo.createdAtUtc)}
      </StrucCol>
      <StrucCol size={columns['stateFilter'].weight}>
        <LogoStatusElement logo={logo} />
      </StrucCol>
      <StrucCol
        size={columns['actions'].weight}
        className={'items-start !justify-start space-y-2 h-full'}>
        {(() => {
          // Very bad way to render conditional actions, but it is better than freezing them in time during rendering pipeline.
          switch (overallState) {
            case LogoState.Rejected:
              return <RejectedActions deleteLogo={Delete} seeRejectionNote={SeeRejectNote} />
            case LogoState.Committed:
              return <CommittedActions handleStartApproval={handleStartApproval} />
            case LogoState.Approved:
              return <ApprovedActions addToCart={AddToCart} hasCustomizableQuantity={hasCustomizableQuantity} quantityForOrder={quantityForOrder} setQuantityForOrder={setQuantityForOrder} />
            case LogoState.Pending:
              return <PendingActions deleteLogo={Delete} editLogo={EditLogo} logo={logo} onPlaceVerdict={onPlaceVerdict} onRejectAndDelete={onRejectAndDelete} />
            case LogoState.Draft:
              return <DraftActions deleteLogo={Delete} editLogo={EditLogo} />
          }
        })()}
        {component}
      </StrucCol>
    </StrucRow>
  )
}

function RejectedActions({ deleteLogo, seeRejectionNote }: {
  deleteLogo: (e?: React.MouseEvent<HTMLElement>) => void
  seeRejectionNote?: (e?: React.MouseEvent<HTMLElement>) => void
}) {
  return (
    <>
      <IconButton key={'see-note'} onClick={seeRejectionNote} icon={<NoteIcon />}>
        See Note
      </IconButton>
      <ConfirmationGuard
        anchor={
          <IconButton icon={<TrashcanCrossIcon />} variant="error">
            Delete
          </IconButton>
        }
        text={'Do you want to Delete this logo?'}
        onAccept={deleteLogo}
      />
    </>
  )
}

function CommittedActions({ handleStartApproval }: {
  handleStartApproval: (e?: React.MouseEvent<HTMLElement>) => void
}) {
  return (
    <IconButton key={'see-note'} onClick={handleStartApproval} icon={<NoteIcon />}>
      {' '}
      Start Approval
    </IconButton>
  )
}

function ApprovedActions({ role, hasCustomizableQuantity, setQuantityForOrder, quantityForOrder, addToCart }: {
  role?: Role
  hasCustomizableQuantity: Boolean
  quantityForOrder: number,
  setQuantityForOrder: (value: number) => void
  addToCart: (e?: React.MouseEvent<HTMLElement>) => void
}) {
  if (role === Role.Admin) {
    return <></>
  }

  return (
    <>
      {!hasCustomizableQuantity && <Stepper onValueChange={setQuantityForOrder} min={0} value={quantityForOrder} />}
      <IconButton
        key={'add-order'}
        disabled={!hasCustomizableQuantity && quantityForOrder <= 0}
        onClick={addToCart}
        icon={<AddToCartIcon />}
        variant="success">
        Add to Order
      </IconButton>
    </>
  )
}

function DraftActions({ editLogo, deleteLogo }: {
  editLogo: (e?: React.MouseEvent<HTMLElement>) => void
  deleteLogo: (e?: React.MouseEvent<HTMLElement>) => void
}) {
  return (
    <>
      <IconButton key={'approve'} onClick={editLogo} icon={<EditIcon />} variant="success">
        Edit
      </IconButton>
      <ConfirmationGuard
        anchor={
          <IconButton icon={<TrashcanCrossIcon />} variant="error">
            Delete
          </IconButton>
        }
        text={'Do you want to Delete this logo?'}
        onAccept={deleteLogo}
      />
    </>
  )
}

function PendingActions({ logo, editLogo, deleteLogo, onPlaceVerdict, onRejectAndDelete }: {
  onPlaceVerdict: (logo: BaseLogo, verdict: Verdict) => void
  onRejectAndDelete?: (logo: BaseLogo, note?: string) => void
  editLogo: (e?: React.MouseEvent<HTMLElement>) => void
  deleteLogo: (e?: React.MouseEvent<HTMLElement>) => void
  logo: BaseLogo
}) {
  const { role } = useProfileContext()
  const approvalGroup = logo.approval
  // Replicated logic from ApprovalVoting component, becauase inside the component, it is too late to fallback...
  if (
    role === Role.EndUser && (!approvalGroup?.customer?.approver || approvalGroup?.customer?.status === 0) ||
    role === Role.Vendor && (!approvalGroup?.vendor?.approver || approvalGroup?.vendor?.status === 0)
  ) {
    return (
      <ApprovalVoting
        placeVerdict={(verdict) => onPlaceVerdict(logo, verdict)}
        approvalGroup={logo.approval!}
        rejectDelete={(reason) => onRejectAndDelete?.call(null, logo, reason)}
      />
    )
  }
  return <DraftActions deleteLogo={deleteLogo} editLogo={editLogo} />
}