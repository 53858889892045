import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { LineSeparator } from '../../../components/StructureElements/LineSeparator'
import { BoxFooter } from '../../../components/BoxView/BoxFooter'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { OrganizationDetailFormCreateWrapper } from '../../customer-create/wrappers/organization-details-form-create.wrapper'
import { BrandExclusionFormCreateWrapper } from '../../customer-create/wrappers/brand-exclusion-form-create.wrapper'
import LoadingElement from '../../../components/loading-element/loading-element'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { CommissionProfile } from '../../../../core/models/entities/Commission'
import { CommissionEditor, CommissionOptions } from './commission-editor.component'

export interface OrganizationUpdateForm {
  id: number
  name: string
  contactEmail: string
  email: string
  contactPerson: string
  contactPhone: string
  phone: string
  postCode: string
  address: string
  country: string
  city: string
  cvr: string
  excludedManufacturers: Manufacturer[]
  eori?: string
  shippingName?: string
  shippingAddress?: string
  shippingPostCode?: string
  shippingCity?: string
  shippingCountry?: string
  shippingContactPerson?: string
  shippingContactPhone?: string
  shippingContactEmail?: string
}

export interface CustomerUpdateForm_VM {
  manufactures: Manufacturer[]
  onCreate: () => void
  onCancel: () => void
  form: OrganizationUpdateForm
  setForm: (form: OrganizationUpdateForm) => void
  isFormValid: boolean
  isLoading: boolean
  vendorId: number
  logoOptions: LogoOptions
  updateCommission: (commission: CommissionProfile) => void
  commission: CommissionProfile
  commissionOptions: CommissionOptions
  isVendor?: boolean
}

export function CustomerUpdateForm({
  manufactures,
  onCreate,
  form,
  setForm,
  onCancel,
  isFormValid,
  isLoading,
  vendorId,
  isVendor,
  logoOptions,
  updateCommission,
  commission,
  commissionOptions
}: CustomerUpdateForm_VM) {
  return <JetSportBox>
    <BoxHeader2 className={'border-b'}>
      <BoxHeaderTypography>{isVendor ? 'Update Vendor' : 'Update Customer'}</BoxHeaderTypography>
    </BoxHeader2>
    <BoxContent className={'py-4 space-y-4'}>
      <OrganizationDetailFormCreateWrapper
        isVendor={isVendor}
        form={{ ...form }}
        onFormChange={x => setForm({ ...form, ...x })}
      />
      <LineSeparator />
      {!isVendor && <>
        <CommissionEditor
          options={commissionOptions}
          userType="customer"
          commissions={commission}
          onCommissionUpdate={updateCommission}
          logoOptions={logoOptions} />
        <LineSeparator />
        <BrandExclusionFormCreateWrapper
          form={{ ...form }}
          onFormChange={x => setForm({ ...form, ...x })}
          manufacturers={manufactures}
        />
      </>}
    </BoxContent>
    <BoxFooter className={'justify-end'}>
      <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
      <PrimaryButton onClick={onCreate} disabled={!isFormValid}>Update</PrimaryButton>
    </BoxFooter>
    {isLoading && <LoadingElement className={'rounded'} transparent />}
  </JetSportBox>
}
