import { OrganizationDetailForm, DetailsForm } from '../components/organization-details-form.component'
import { useOrganizationDetailFormCreateViewModel } from '../vmHooks/useOrganizationDetailFormCreateViewModel'

interface WrapperProps {
  form: DetailsForm,
  onFormChange: (form: DetailsForm) => void
  isVendor?: boolean
}

export function OrganizationDetailFormCreateWrapper({ isVendor, form, onFormChange }: WrapperProps) {
  const vm = useOrganizationDetailFormCreateViewModel(form, onFormChange)
  return <OrganizationDetailForm {...vm} isVendor={isVendor}/>
}

