import { InputWrapper } from '../../../../components/Input/InputWrapper'
import { InputLabel } from '../../../../components/Input/InputLabel'
import { KitMakerAutoComplete, KitMakerAutoCompleteItem } from '../../../../components/Input/KitMakerAutoComplete'
import { TransferMethod } from '../../../../../core/models/entities/LogoOptions'

interface TransferMethodEditor_VM {
  className?: string
  transferMethods: TransferMethod[]
  value?: TransferMethod
  onChange: (method: TransferMethod) => void
}

export function TransferMethodEditor({ className, transferMethods, value, onChange }: TransferMethodEditor_VM) {
  return <div className={'space-y-4 ' + className}>
    <InputWrapper>
      <InputLabel>TRANSFER METHOD</InputLabel>
      <TransferMethodAutoComplete transferMethods={transferMethods} onChange={onChange} value={value} disabled={false} />
    </InputWrapper>
  </div>
}

function TransferMethodAutoComplete({ transferMethods, disabled, value, onChange }: {
  transferMethods: TransferMethod[],
  onChange: (method: TransferMethod) => void
  disabled: boolean
  value?: TransferMethod
}) {
  const mappedList = transferMethods.map(x => ({ key: x.key, name: x.displayName }))
  return <>
    <KitMakerAutoComplete
      subject="Transfer Method"
      items={mappedList}
      disabled={disabled}
      value={value ? { key: value.key, name: value.displayName } : undefined}
      onItemChange={(item: KitMakerAutoCompleteItem) => { onChange(transferMethods.find(x => x.key === item.key)!) }}
    />
  </>
}
