import React, { useState } from 'react'
import LoadingElement from '../loading-element/loading-element'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faNoteSticky } from '@fortawesome/free-solid-svg-icons'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import { ApprovalDialog } from './useApprovalDialogByRole'

interface Props<T> {
  subject: string
  onSkip: (entity: T) => Promise<void>
  onOrder: (entity: T) => Promise<void>
  onInitApproval: (entity: T) => Promise<void>
}

export function useApprovalDialogVendor<T>({ subject, onSkip, onOrder: onSkipAndOrder, onInitApproval }: Props<T>): ApprovalDialog<T> {
  const initApproval = useDialog()
  const [loading, setLoading] = useState(false)
  const [entity, setEntity] = useState<T>()

  function handleSkip() {
    setLoading(true)
    onSkip(entity!)
      .finally(() => {
        setLoading(false)
        initApproval.closeDialog()
      })
  }

  function handleStartApproval() {
    setLoading(true)
    onInitApproval(entity!)
      .finally(() => {
        setLoading(false)
        initApproval.closeDialog()
      })
  }

  function handleSkipAndOrder() {
    setLoading(true)
    onSkipAndOrder(entity!)
      .finally(() => {
        setLoading(false)
        initApproval.closeDialog()
      })
  }

  return {
    component: <>
      <InitiateApprovalDialog2
        loading={loading}
        onSkip={handleSkip}
        onInitiateApproval={handleStartApproval}
        onSkipAndOrder={handleSkipAndOrder}
        subject={subject}
        dialogVm={initApproval}
      />
    </>,
    start: (entity) => {
      setEntity(entity)
      initApproval.openDialog()
    }
  }
}

interface InitiateApprovalDialog2_VM {
  onInitiateApproval: (e?: React.MouseEvent<HTMLElement>) => void,
  onSkipAndOrder: (e: React.MouseEvent<HTMLElement>) => void,
  onSkip: (e: React.MouseEvent<HTMLElement>) => void,
  subject: string,
  loading: boolean
  dialogVm: Dialog_VM
}

function InitiateApprovalDialog2({
  onInitiateApproval, subject, onSkipAndOrder, onSkip, loading, dialogVm
}: InitiateApprovalDialog2_VM) {

  function handleSkip(e: React.MouseEvent<HTMLElement>) {
    if (loading) {
      return
    }
    onSkip(e)
  }

  // Do not show dialogs with loading spinners. 
  // Loading spinners are currently reserved for the base view only to avoid duplicates.
  if (loading) return null

  return <DialogFrame dialogOption={dialogVm}>
    {loading && <LoadingElement transparent />}
    <div className={'flex flex-col gap-4 p-4'}>
      <p className='capitalize text-left text-base font-bold'>{subject}</p>
      <p>Click on an option to continue.</p>
      <div className={'flex justify-center space-x-4'}>
        <button className={'button-secondary w-48 uppercase align-items-center h-auto py-3 px-12 flex flex-col space-y-1'}
          onClick={onInitiateApproval} disabled={loading}>
          <FontAwesomeIcon size={'lg'} icon={faNoteSticky} />
          <span>Send Approval to customer</span></button>
        <button
          className={'button-secondary w-48 uppercase align-items-center h-auto py-3 px-12 flex flex-col space-y-1'}
          onClick={onSkipAndOrder} disabled={loading}>
          <FontAwesomeIcon size={'lg'} icon={faCartPlus} />
          <span>Add to order</span>
        </button>
      </div>
      <p className={'italic hover:underline cursor-pointer'} onClick={handleSkip}>Continue without ordering</p>
    </div>
  </DialogFrame>
}