import React, { useState } from 'react'
import { BaseLogo, getColors, isDigital } from '../../../../core/models/entities/BaseLogo'
import IconButton from '../../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DigitalColorElement } from '../../../components/Logo/DigitalColorElement'
import { VectorColorElement } from '../../../components/Logo/VectorColorElement'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { LogoSize } from '../../../components/Logo/LogoSize'
import Tooltip from '@mui/material/Tooltip'
import { LogoPrices } from '../../../components/Logo/LogoPrices'
import { formatDate } from '../../../../utils/functions/DateUtil'
import { LogoStatusElement } from '../../../components/Logo/LogoStatusElement'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { LogoStorage } from '../../../components/Logo/LogoStorage'
import { ConfirmationGuard } from '../../../components/ConfirmationGuard'
import { LogoTransferMethod } from '../../../components/Logo/LogoTransferMethod'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { getLogoState, LogoState } from '../vmHooks/useFunctionalListLogosViewModel'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { ApprovalVoting, Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import {
  AddToCartIcon,
  EditIcon,
  NoteIcon,
  TrashcanCrossIcon
} from '../../../../resources/icons/Icons'
import { Stepper } from '../../../components/Buttons/Stepper'
import CustomerTooltip from '../../../components/Organization/CustomerTooltip'

interface LogoListItemComponent {
  logo: BaseLogo
  columns: ColumnSettings
  onPlaceVerdict: (logo: BaseLogo, verdict: Verdict) => void
  onSeeRejectNote: (logo: BaseLogo) => void
  onAddToCart: (logo: BaseLogo, quantity: number) => void
  onEditLogo: (logo: BaseLogo) => void
  onDeleteLogo: (logo: BaseLogo) => void
  onInitiateApproval: (logo: BaseLogo) => void
  onRejectAndDelete: (logo: BaseLogo, note?: string) => Promise<void>
}

export function LogoRowItem({
  logo,
  columns,
  onPlaceVerdict,
  onSeeRejectNote,
  onAddToCart,
  onEditLogo,
  onDeleteLogo,
  onInitiateApproval,
  onRejectAndDelete
}: LogoListItemComponent) {
  const [quantityForOrder, setQuantityForOrder] = useState<number>(10)
  const { colors: colorOptions } = useLogoOptionsContext()
  const { role } = useProfileContext()

  function SeeRejectNote(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onSeeRejectNote(logo)
  }

  function EditLogo(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onEditLogo(logo)
  }

  function AddToCart(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onAddToCart(logo, quantityForOrder)
  }

  function Delete(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation()
    onDeleteLogo(logo)
  }

  function handleStartApproval(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onInitiateApproval(logo)
  }

  function rejectedActions() {
    return (
      <>
        <IconButton key={'see-note'} onClick={SeeRejectNote} icon={<NoteIcon />}>
          See Note
        </IconButton>
        <ConfirmationGuard
          anchor={
            <IconButton icon={<TrashcanCrossIcon />} variant="error">
              Delete
            </IconButton>
          }
          text={'Do you want to Delete this logo?'}
          onAccept={Delete}
        />
      </>
    )
  }

  function committedActions() {
    return (
      <IconButton key={'see-note'} onClick={handleStartApproval} icon={<NoteIcon />}>
        {' '}
        Start Approval
      </IconButton>
    )
  }

  function approvedActions() {
    if (role === Role.Admin) {
      return <></>
    }

    return (
      <>
        <Stepper onValueChange={setQuantityForOrder} min={0} value={quantityForOrder} />
        <IconButton
          key={'add-order'}
          disabled={quantityForOrder <= 0}
          onClick={AddToCart}
          icon={<AddToCartIcon />}
          variant="success">
          Add to Order
        </IconButton>
      </>
    )
  }

  function pendingActions() {
    return (
      <ApprovalVoting
        placeVerdict={(verdict) => onPlaceVerdict(logo, verdict)}
        approvalGroup={logo.approval!}
        rejectDelete={(reason) => onRejectAndDelete(logo, reason)}
      />
    )
  }

  function draftActions() {
    return (
      <>
        <IconButton key={'approve'} onClick={EditLogo} icon={<EditIcon />} variant="success">
          Edit
        </IconButton>
        <ConfirmationGuard
          anchor={
            <IconButton icon={<TrashcanCrossIcon />} variant="error">
              Delete
            </IconButton>
          }
          text={'Do you want to Delete this logo?'}
          onAccept={Delete}
        />
      </>
    )
  }

  function getActions(state: LogoState) {
    switch (state) {
      case LogoState.Rejected:
        return rejectedActions()
      case LogoState.Committed:
        return committedActions()
      case LogoState.Approved:
        return approvedActions()
      case LogoState.Pending:
        return pendingActions()
      case LogoState.Draft:
        return draftActions()
    }
  }

  const overallState = getLogoState(logo)
  const actions = getActions(overallState)

  const colors = getColors(logo)
  const pantoneColors = colorOptions.filter((co) => colors.find((c) => c === co.id))
  let colorsElements = isDigital(logo) ? (
    <DigitalColorElement />
  ) : (
    <div className="grid grid-rows-4 grid-flow-col">
      {pantoneColors.map((co) => (
        <VectorColorElement key={co.id} showName={true} color={co} />
      ))}
    </div>
  )

  return (
    <StrucRow className={'h-[100px] items-start py-4'}>
      <StrucCol
        size={columns['thumbnail'].weight}
        className={'items-center !justify-around space-y-2 h-full'}>
        <div className={'flex align-middle pr-1.5 max-h-[80px] w-fit mr-5'}>
          <LogoImage
            logo={logo}
            useHighRes={false}
            className={
              'bg-white rounded hover:border hover:p-2 max-h-[74px] origin-top-left hover:scale-[3] hover:ease-out transition-all duration-150 ease-out'
            }
          />
        </div>
      </StrucCol>
      <StrucCol className={'space-y-0.5'} size={columns['product.sku'].weight}>
        <Tooltip title={logo.product?.sku ?? '-'} placement={'top-start'}>
          <span className={'font-medium whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
            {logo.product?.sku ?? '-'}
          </span>
        </Tooltip>
        <LogoSize logo={logo} format="WWxYYmm" />
      </StrucCol>
      <StrucCol className={'space-y-0.5'} size={columns[nameOf<BaseLogo>((x) => x.title)].weight}>
        <Tooltip title={logo.title} placement={'top-start'}>
          <span className={'font-bold whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
            {logo.title}
          </span>
        </Tooltip>
        <LogoTransferMethod logo={logo} />
      </StrucCol>
      <StrucCol size={columns['colors'].weight}>{colorsElements}</StrucCol>
      <StrucCol size={columns[nameOf<BaseLogo>((x) => x.customer)].weight}>
        <CustomerTooltip customer={logo.customer} />
      </StrucCol>
      <StrucCol size={columns['priceSort'].weight}>
        {logo.product ? <LogoPrices logo={logo} /> : <span>-</span>}
      </StrucCol>
      <StrucCol size={columns['product.storagecount'].weight}>
        <LogoStorage logo={logo} />
      </StrucCol>
      <StrucCol size={columns[nameOf<BaseLogo>((x) => x.createdAtUtc)].weight}>
        {formatDate(logo.createdAtUtc)}
      </StrucCol>
      <StrucCol size={columns['stateFilter'].weight}>
        <LogoStatusElement logo={logo} />
      </StrucCol>
      <StrucCol
        size={columns['actions'].weight}
        className={'items-start !justify-around space-y-2 h-full'}>
        {actions}
      </StrucCol>
    </StrucRow>
  )
}
