import { JSX, useState } from 'react'
import Column, { ColumnField } from './Column'
import SortByFieldEvent from '../Models/sortByFieldEvent'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { StrucCol } from '../../StructureElements/StrucCol'
import { StrucRow } from '../../StructureElements/StrucRow'

interface ColumnHeaderProps {
  columns: ColumnField[];
  onNewSort: (sortByFiled: SortByFieldEvent) => void
  multiSelect: boolean;
  initialQuery: PageQuery;
}

function ColumnHeader({ columns, onNewSort, multiSelect, initialQuery }: ColumnHeaderProps): JSX.Element {
  const [sortByField, setSortByField] = useState<SortByFieldEvent>({
    value: initialQuery.sortBy?.field ?? '',
    sortDirection: initialQuery.sortBy?.sortDirection
  })

  return (
    <StrucRow className={`!items-center h-10 ${!multiSelect ? 'pl-6' : ''}`}>
      {columns.filter(x => !x.hide).map((c, i) =>
        <StrucCol size={c.weight} key={c.value}>
          <Column sortByField={sortByField} column={c} setSortByField={setSortByField} onNewSort={onNewSort} />
        </StrucCol>)}
    </StrucRow>
  )
}

export default ColumnHeader