import { BaseLogo, getLogoType } from '../../../../core/models/entities/BaseLogo'
import { useCartContext } from '../../../../contexts/CartContext'
import { CartItem, CartItemDesign, CartItemDesignInstance, CartItemDesignInstanceLogo, CartItemLogo, DesignSize, QuantityRow } from '../models/CartItem'
import { Design } from '../../../../core/models/entities/Design'
import { DeliveryType, Order, OrderDeliveryPreferences, OrderLine, OrderLineDesign, OrderLineLogo, Packaging } from '../../../../core/models/entities/Order'
import { useState } from 'react'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { ExtraLogo, HeatingOption } from '../../order-confirmation/dialogs/add-heating-fee-order-dialog'
import { dissmissToast, useErrorToast, useLoadingToast, withToasts } from '../../../components/Toast/Toast'
import { DesignTemplate, LogoTemplate } from '../../../../core/models/entities/DesignTemplate'
import { useNavigate } from 'react-router'
import { Id } from 'react-toastify'

export function useOrderActionHandler() {
  const { addLogoItem: addLogoToCart, addLogoItems: addLogosToCart, addDesignItem: addDesignToCart, addDesignItems: addDesignsToCart, removeItem: removeFromCart, updateItem: updateInCart, clearItems, items, designs } = useCartContext()
  const { orderRepository } = useRepositoriesContext()
  const { addItems, activeOrder, setActiveOrder } = useCartContext()

  const [isLoading, setIsLoading] = useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)
  const navigate = useNavigate()

  function updateLogoOrderLine(logo: BaseLogo, deliveryQuantities?: QuantityRow[], storageQuantities?: QuantityRow[]) {
    const exists = items.find(x => x.id === '' + logo.id) as CartItemLogo;
    const updatedOrderLine = {
      ...exists,
      deliveryQuantities: deliveryQuantities ?? exists.deliveryQuantities,
      storageQuantities: storageQuantities ?? exists.storageQuantities,
    } as CartItemLogo

    updateInCart({ ...updatedOrderLine })
  }

  function handleLogoAddOrderLine(baseLogo: BaseLogo, toDelivery: number, toStorage: number) {

    const existingLogo = items.find(x => x.id === baseLogo.id.toString()) as CartItemLogo | undefined

    const newLogo = mapBaseLogoToCartItemLogo(
      baseLogo,
      existingLogo ? existingLogo.toDelivery + toDelivery : toDelivery,
      existingLogo ? existingLogo.toStorage + toStorage : toStorage
    );

    addLogoToCart(newLogo).then().catch(() => {
      useErrorToast("Can't mix different vendor products")
    })
  }

  function handleLogoAddOrderLines(baseLogos: BaseLogo[], toDelivery: number, toStorage: number) {

    const newLogos = baseLogos.map(baseLogo => {
      const existingLogo = items.find(x => x.id === baseLogo.id.toString()) as CartItemLogo | undefined

      const newLogo = mapBaseLogoToCartItemLogo(
        baseLogo,
        existingLogo ? existingLogo.toDelivery + toDelivery : toDelivery,
        existingLogo ? existingLogo.toStorage + toStorage : toStorage
      );

      return newLogo
    })

    addLogosToCart(newLogos).then().catch(() => {
      useErrorToast("Can't mix different vendor products")
    })
  }

  function mapBaseLogoToCartItemLogo(baseLogo: BaseLogo, toDelivery: number, toStorage: number): CartItemLogo {
    const itemLogo: CartItemLogo = {
      id: baseLogo.id.toString(),
      title: baseLogo.title,
      type: "Logo",
      displayImage: baseLogo.displayImage!,
      discriminator: baseLogo.logoType,
      baseLogo: baseLogo,
      toStorage: toStorage,
      toDelivery: toDelivery,
      value: undefined,
      logoId: baseLogo.id,
      logoVariant: getLogoType(baseLogo),
      isNumeric: baseLogo.logoType === 'NumberLogo',
      deliveryQuantities: toDelivery > 0 ? [{ label: '', quantity: toDelivery }] : [],
      storageQuantities: toStorage > 0 ? [{ label: '', quantity: toStorage }] : [],
    };

    return itemLogo;
  }

  function handleDesignAddOrderLine(design: Design, quantity: number) {

    const itemQuantity = items.find(x => +x.id === design.id) as CartItemDesign | undefined
    const placeholderLogos = design.designLogos

    const instances = [
      ...itemQuantity?.instances ?? [],
      {
        logos: placeholderLogos.map(x => ({
          logoVariant: getLogoType(x.logo),
          locationIdentifier: x.viewName,
          value: "",
          logoId: x.logo.id
        }) as CartItemDesignInstanceLogo),
        label: "New Label",
        toDelivery: quantity,
        toStorage: 0,
        size: DesignSize.SizeM
      }
    ]

    const cartItemDesign = mapDesignToCartItemLogo(design, instances)

    addDesignToCart(cartItemDesign).then().catch(x => {
      useErrorToast("Can't mix different vendor products")
    })
  }

  function mapDesignToCartItemLogo(design: Design, instances: CartItemDesignInstance[] = []): CartItemDesign {
    const newDesign: CartItemDesign = {
      id: design.id.toString(),
      title: design.title,
      type: "Design",
      displayImage: design.displayImage!,
      design: design,
      instances: instances
    }
    return newDesign
  }

  function handleDesignAddOrderLines(designs: Design[], quantity: number) {

    const newDesigns = designs.map(design => {
      const itemQuantity = items.find(x => +x.id === design.id) as CartItemDesign | undefined
      const placeholderLogos = design.designLogos

      const newDesign = {
        id: design.id.toString(),
        quantity: quantity,
        title: design.title,
        type: "Design",
        displayImage: design.displayImage!,
        // discriminator: logo.logoType,
        design: design,
        logosToStorage: 0,
        logosToDelivery: quantity,
        instances: [
          ...itemQuantity?.instances ?? [],
          {
            isNumeric: false,
            logos: placeholderLogos.map(x => ({
              logoVariant: getLogoType(x.logo),
              locationIdentifier: x.viewName,
              value: "",
              logoId: x.logo.id
            }) as CartItemDesignInstanceLogo),
            label: "Temp",
            toDelivery: quantity,
            toStorage: 0,
            size: DesignSize.SizeM
          }
        ]
      } as CartItemDesign;

      return newDesign
    })

    addDesignsToCart(newDesigns).then().catch(x => {
      useErrorToast("Can't mix different vendor products")
    })
  }

  function handleRemoveOrderLine(id: string) {
    removeFromCart(id)
  }

  function handleLogoSetQuantities(baseLogo: BaseLogo, toDelivery: number, toStorage: number) {
    const exists = items.find(x => x.id === '' + baseLogo.id);
    if (!exists) {
      handleLogoAddOrderLine(baseLogo, toDelivery, toStorage)
      return;
    }

    const updatedLogo = {
      ...exists,
      toDelivery: toDelivery,
      toStorage: toStorage
    } as CartItemLogo

    updateInCart({ ...updatedLogo })
  }

  function handlePlaceVerdict(order: Order, verdict: Verdict) {
    startLoading()
    orderRepository
      .placeVerdict(order, verdict)
      .then()
      .finally(stopLoading)
  }

  function handleDesignInstanceUpdateStorage(orderlineDesign: CartItemDesign) {

    let newOrderLineDesign = { ...orderlineDesign } as CartItemDesign;

    if (newOrderLineDesign.instances === undefined) {
      newOrderLineDesign.instances = []
    }

    updateInCart({ ...newOrderLineDesign })
  }

  async function handleCreateOrder(orgId: number, items: CartItem[], heatingOption: HeatingOption, extraLogos: ExtraLogo[]): Promise<Order> {
    setIsLoading(true)
    const heatingOrderLines = applyHeatingOption(items, heatingOption)
    const extraOrderLines = applyExtraLogos(heatingOrderLines, extraLogos)
    const allLogoOrderLines = applyLogoVariantLines(extraOrderLines)
    const filteredOrderLines = allLogoOrderLines.filter((item) =>
      item.type !== 'Logo' ||
      !['name', 'number'].includes((item as CartItemLogo).logoVariant ?? 'name') ||
      !!(item as CartItemLogo).value
    )

    let toast: Id = ""
    if (activeOrder) {
      toast = useLoadingToast(`Updating order`)
    } else {
      toast = useLoadingToast(`Creating order`)
    }
    const orderOperation = activeOrder
      ? orderRepository.updateOrder(activeOrder.organization.id, activeOrder.id, filteredOrderLines)
      : orderRepository.createOrder(orgId, filteredOrderLines)

    return orderOperation
      .finally(() => {
        clearItems();
        setActiveOrder(undefined);
        setIsLoading(false)
        dissmissToast(toast)
      })
  }

  function applyHeatingOption(orderlines: CartItem[], heatingOption: HeatingOption): CartItem[] {
    const logos = orderlines.filter(x => x.type === "Logo")
    const designs = orderlines.filter(x => x.type === "Design").map(x => x as CartItemDesign).map(design => {
      return {
        ...design,
        instances:
          design.instances.map(instance => {
            return {
              ...instance,
              logos: instance.logos.map(logos => {
                return {
                  ...logos,
                  deliveryType: heatingOption === HeatingOption.all || heatingOption === HeatingOption.partly ? 'Application' : 'Delivery'
                }
              }) as CartItemDesignInstanceLogo[]
            }
          }) as CartItemDesignInstance[]
      }
    })
    return [...designs, ...logos]
  }

  function applyExtraLogos(orderlines: CartItem[], extraLogos: ExtraLogo[]) {
    const extra = extraLogos.filter(extra => extra.option !== undefined && extra.quantity > 0).map(logo => mapBaseLogoToCartItemLogo(logo.designLogo.logo, logo.option === 'toDelivery' ? logo.quantity : 0, logo.option === 'toStorage' ? logo.quantity : 0))
    return [...orderlines, ...extra]
  }

  function applyLogoVariantLines(orderlines: CartItem[]) {
    const logoOrderLines = orderlines.filter((line) => line.type === 'Logo') as CartItemLogo[]
    const logoLines: CartItemLogo[] = []
    for (const logoLine of logoOrderLines) {
      const deliveries = logoLine.deliveryQuantities ?? []
      for (const delivery of deliveries) {
        if (delivery.label) logoLines.push({
          ...logoLine,
          value: delivery.label,
          toDelivery: delivery.quantity,
          // Ensure we do not create storage lines for logos to delivery.
          toStorage: 0
        })
      }
      const storages = logoLine.storageQuantities ?? []
      for (const storage of storages) {
        if (storage.label) logoLines.push({
          ...logoLine,
          value: storage.label,
          toStorage: storage.quantity,
          // Ensure we do not create delivery lines for logos to storage.
          toDelivery: 0
        })
      }
    }

    return [...orderlines, ...logoLines]
  }

  function handleDownloadTemplate(orgId: number, designItems: CartItemDesign[]) {
    startLoading()
    orderRepository.downloadTemplate(orgId, designItems).then(blobData => {
      const url = URL.createObjectURL(blobData);
      const a = document.createElement('a');
      a.href = url;
      a.download = `designs_${designItems.map(x => x.id).join('_')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }).catch(x => console.log("Error", x)).finally(() => {
      stopLoading();
    })
  }

  function handleUploadDesignTemplate(orgId: number, file: File) {
    async function transaction() {
      return await orderRepository.uploadDesignTemplate(orgId, file)
      // const baseLogo = await logoRepository.createBaseLogo(customerId, title)
      // return await logoRepository.uploadFileToBaseLogo(file, customerId, baseLogo.id);
    }

    startLoading()
    return transaction()
      .then((res) => {
        updateDesignWithTemplate(res)
      })
      .finally(stopLoading)
  }

  function handleUploadLogoTemplate(orgId: number, file: File): Promise<LogoTemplate> {
    async function transaction() {
      return await orderRepository.uploadLogoTemplate(orgId, file)
    }

    startLoading()
    return transaction()
      .then((res) => {
        return res
      })
      .finally(stopLoading)
  }


  function updateDesignWithTemplate(template: DesignTemplate) {
    const cartItemDesign = designs.find(x => Number(x.id) === template.designs[0].designId)!
    const designLogos = cartItemDesign.design.designLogos

    const instances = template.designs[0].instances.map(instance => {
      return {
        label: instance.label,
        toDelivery: instance.quantity,
        toStorage: 0,
        size: instance.size,
        logos: instance.logos.map(logo => {
          const view = designLogos.find(x => x.logoId === logo.logoId)!.viewName
          const logoVariant = getLogoType(designLogos.find(x => x.logoId === logo.logoId)!.logo)
          const instanceLogo: CartItemDesignInstanceLogo = {
            value: logo.value,
            logoId: logo.logoId,
            deliveryType: 'Delivery',
            locationIdentifier: view,
            logoVariant: logoVariant,
          }
          return instanceLogo
        }) as CartItemDesignInstanceLogo[]
      }
    }) as CartItemDesignInstance[]

    updateInCart({ ...cartItemDesign, instances } as CartItemDesign)
  }

  async function handleCommitOrder(orgId: number, orderId: number, orderDeliveryPreferences: OrderDeliveryPreferences): Promise<Order> {
    async function transaction() {
      return await orderRepository.commitOrder(orgId, orderId, orderDeliveryPreferences)
    }

    return withToasts(
      transaction,
      'Committing order',
      'Order committed',
      'Failed to commit order',
      setIsLoading
    )
  }

  function mapOrderLineToOrderItem(orderline: OrderLine): CartItem {
    switch (orderline.product.productType) {
      case 'Logo': {
        const logo = (orderline as OrderLineLogo).baseLogo;
        const cartItem = mapBaseLogoToCartItemLogo(logo, (orderline as OrderLineLogo).toDelivery, (orderline as OrderLineLogo).toStorage)
        return cartItem
      }
      default: {
        const design = (orderline as OrderLineDesign).design;
        const instances = mapInstances(orderline as OrderLineDesign)
        const cartItem = mapDesignToCartItemLogo(design, instances)
        return cartItem
      }

    }
  }

  function mapInstances(orderline: OrderLineDesign): CartItemDesignInstance[] {
    const instances: CartItemDesignInstance[] = orderline.instances.map(x => {
      return {
        label: x.label,
        size: x.size,
        toDelivery: x.quantity,
        toStorage: 0,
        logos: x.logos.map(logo => {
          const cartItem: CartItemDesignInstanceLogo = {
            deliveryType: 'Delivery',
            locationIdentifier: "Some location",
            logoId: logo.logoId,
            logoVariant: logo.isNumeric ? "number" : "name",
            value: logo.value
          }
          return cartItem
        })
      }
    })

    return instances;
  }

  async function handleReorder(order: Order) {
    const lines: OrderLine[] = order.lines
    const cartItems = lines.map(x => mapOrderLineToOrderItem(x));
    return await addItems(cartItems).then(() => {
      navigate('/order/place')
    })
  }

  async function handleChangeOrder(order: Order) {
    const lines: OrderLine[] = order.lines
    const cartItems = lines.map(line => mapOrderLineToOrderItem(line));
    setActiveOrder(order)
    return await addItems(cartItems)
  }

  async function handleChangePackagingOrDelivery(order: Order, deliveryType: DeliveryType, packaging: Packaging) {
    startLoading()
    orderRepository
      .setPackagingAndDelivery(order, deliveryType, packaging)
      .then()
      .finally(stopLoading)
  }

  return {
    isLoading,
    handleLogoAddOrderLine,
    handleLogoAddOrderLines,
    handleDesignAddOrderLine,
    handleDesignAddOrderLines,
    handleRemoveOrderLine,
    handleLogoSetQuantities,
    updateLogoOrderLine,
    handleDesignInstanceUpdateStorage,
    handlePlaceVerdict,
    handleCreateOrder,
    handleDownloadTemplate,
    handleUploadDesignTemplate,
    handleUploadLogoTemplate,
    handleCommitOrder,
    handleChangeOrder,
    handleChangePackagingOrDelivery,
    handleReorder
  }
}
