import { StyleView } from './StyleView'
import { Manufacturer } from './Manufacturer'

export interface Style {
  status: StyleStatus
  id: number
  name: string
  model: string
  thumbnailUrl: string
  createdAtUtc?: Date
  updatedAtUtc?: Date
  styleViews: StyleView[]
  modelAndName: string
  manufacturer: Manufacturer;
  manufacturerId: number;
  EAN: string;
  originalImageUrl: string;
  thumbnailUrlLowResolution: string;
  widthInCm: number;
  heightInCm: number;
  modelAndNameWithoutHyphen: string;
}

export enum StyleStatus {
  NotAnnotated = 'notAnnotated',
  Annotated = 'annotated'
}
