import React, { forwardRef } from 'react'
import { BaseLogo } from '../../../core/models/entities/BaseLogo'

interface LogoImageProp extends React.ImgHTMLAttributes<HTMLImageElement> {
  logo: BaseLogo
  useHighRes?: boolean
  useDigit?: boolean
}

export const LogoImage = forwardRef<HTMLImageElement, LogoImageProp>((props, ref) => {
  const { logo, useHighRes, useDigit, ...rest } = props

  const { lowResUrl, highResUrl, digitUrl } = logo.displayImage

  rest.src = useDigit && digitUrl ? digitUrl :
    useHighRes && highResUrl ? highResUrl : lowResUrl

  rest.alt = logo.title
  return <img {...rest} />
})
