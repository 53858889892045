import { CartItemDesignInstance, DesignSize, CartItemDesignInstanceLogo, CartItemDesign } from '../models/CartItem'
import { useOrderActionHandler } from '../hooks/useOrderActionsHandler'
import { DesignLogo } from '../../../../core/models/entities/Design'
import { getLogoType, isPlaceholder } from '../../../../core/models/entities/BaseLogo'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { useUploadDialog } from './UploadDialog'
import { DesignInstanceVM } from './DesignInstance.VM'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { DesignInstanceComponent } from './DesignInstance.Component'

interface LogoTextEditorProps {
  orderlineDesign: CartItemDesign
}

export function LogoTextEditor({ orderlineDesign }: LogoTextEditorProps) {

  const { handleDesignInstanceUpdateStorage, handleDownloadTemplate, handleUploadDesignTemplate } = useOrderActionHandler();

  function removeRow(row: number): void {
    const newDesign: CartItemDesign = {
      ...orderlineDesign,
      instances: orderlineDesign.instances.filter((_, index) => index !== row)
    }
    handleDesignInstanceUpdateStorage(newDesign);
  }

  function mapLogoToLogoSpec(designLogo: DesignLogo): CartItemDesignInstanceLogo {
    return {
      logoVariant: getLogoType(designLogo.logo),
      locationIdentifier: designLogo.viewName,
      value: "",
      logoId: designLogo.logoId
    } as CartItemDesignInstanceLogo
  }

  function addRow(): void {
    const newDesign: CartItemDesign = {
      ...orderlineDesign,
      instances: orderlineDesign.instances?.concat({
        logos: orderlineDesign.design.designLogos.map(mapLogoToLogoSpec),
        toDelivery: 1,
        toStorage: 0,
        label: "",
        size: DesignSize.SizeM
      }) ?? []
    }

    handleDesignInstanceUpdateStorage({ ...newDesign });
  }

  function updateInstance(index: number, instance: CartItemDesignInstance): void {
    const instances = orderlineDesign.instances;
    instances[index] = instance;

    const newDesign: CartItemDesign = {
      ...orderlineDesign,
      instances: instances ?? []
    }

    handleDesignInstanceUpdateStorage({ ...newDesign });
  }

  function download() {
    handleDownloadTemplate(orderlineDesign.design.customerId, [orderlineDesign])
  }

  const rows = orderlineDesign.instances?.map((instance, instanceIndex) => {
    const vm = DesignInstanceVM({ index: instanceIndex, instance: instance, updateInstance });
    return (
      <div className={'relative grid'} key={instanceIndex}>
        <DesignInstanceComponent {...vm} />
        <div className={'absolute h-full flex items-start right-20 pl-3 mr-[-25px] pt-2.5'}>
          <div onClick={() => removeRow(instanceIndex)} className={'hide hover:text-status-failed-1'}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </div>
      </div>
    )
  })

  const { component: uploadDialog, open } = useUploadDialog({
    orgId: orderlineDesign.design.customerId,
    onContinue: async () => { },
    onCancel: () => { },
    onUpload: handleUploadDesignTemplate
  })

  const hasPlaceholders = orderlineDesign.design.designLogos?.filter(x => isPlaceholder(x.logo)).length > 0 || false

  return <>
    {uploadDialog}
    <div id={'text-numeric-edit'} className={'space-y-2 pb-2'}>
      <div id={'header'} className={'uppercase text-xs flex pr-8 pl-4 space-x-1 h-4'} style={{
        lineHeight: '18px',
        height: 18
      }}>
        <div className={'w-[270px] font-bold'}>Names</div>
        <div className={'w-[90px] font-bold'}>Sizes</div>
        <div className={'w-[90px] font-bold'}>QTY</div>
      </div>
      <div className={'overflow-y-auto overflow-x-hidden max-h-[315px]'}>
        <div className={'border rounded bg-gray-100 space-y-0.5'}>
          {rows}
        </div>
      </div>
      <div className={'flex justify-between space-x-4'}>
        <PrimaryButton className='flex-1' onClick={addRow}>Add Row</PrimaryButton>
        <SecondaryButton className='flex-1' onClick={open} disabled={false}>UPLOAD EXCEL FILE</SecondaryButton>
        <SecondaryButton className='flex-1' onClick={download} disabled={false}>DOWNLOAD TEMPLATE</SecondaryButton>
      </div>
    </div>
  </>

}