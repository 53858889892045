import React, { forwardRef } from 'react'
import { BaseLogo, LogoType } from '../../../core/models/entities/BaseLogo'

interface LogoImageProp extends React.ImgHTMLAttributes<HTMLImageElement> {
  logo: BaseLogo
  useHighRes?: boolean
}
export const LogoImage = forwardRef<HTMLImageElement, LogoImageProp>((props, ref) => {
  const { logo, useHighRes, ...rest } = props
  const highRes = logo.displayImage.highResUrl
  const lowRes = logo.displayImage.lowResUrl
  rest.src = useHighRes && highRes ? highRes : lowRes
  rest.alt = logo.title
  return <img {...rest} />
})