import { enviroment } from '../../../../enviroment'
import { useQuery } from '@tanstack/react-query'
import { DesignDto } from '../../../../data/models/responses/DesignDto'
import { LogoDto } from '../../../../data/models/responses/LogoDto'

export function usePublicApiClient() {
  const API_URL = enviroment.apiUrl


  function getDesignCache(designId: number, code: string) {
    return useQuery(['ext-design', designId, code], () => getDesign(designId, code))
  }

  async function getDesign(designId: number, code: string): Promise<DesignDto> {
    const response = await fetch(
      `${API_URL}/organizations/designs/${designId}/publickey/${code}`
    )
    return await response.json()
  }

  function getLogoCache(logoId: number, code: string) {
    return useQuery(['ext-logo', logoId, code], () => getLogo(logoId, code))
  }

  async function getLogo(logoId: number, code: string): Promise<LogoDto> {
    const response = await fetch(
      `${API_URL}/organizations/logos/${logoId}/publickey/${code}`
    )
    return await response.json()
  }

  async function placeVerdictLogo(logoId: number, approval: boolean, rejectionNote: string | undefined, code: string): Promise<LogoDto> {
    const body = {
      approve: approval,
      rejectionNode: rejectionNote,
      publicKey: code
    }

    const response = await fetch(
      `${API_URL}/organizations/logos/${logoId}/approval-external`,
      {
        method: 'POST',
        body: JSON.stringify(body)
      }
    )
    return await response.json()
  }

  async function placeVerdictDesign(designId: number, approval: boolean, rejectionNote: string | undefined, code: string): Promise<LogoDto> {
    const body = {
      approve: approval,
      rejectionNode: rejectionNote,
      publicKey: code
    }

    const response = await fetch(
      `${API_URL}/organizations/designs/${designId}/approval-external`,
      {
        method: 'POST',
        body: JSON.stringify(body)
      }
    )
    return await response.json()
  }



  return {
    getLogo: getLogoCache,
    getDesign: getDesignCache,
    placeVerdictLogo,
    placeVerdictDesign
  }
}