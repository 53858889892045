import React, { useEffect, useState } from 'react'
import { Section } from '../../../components/StructureElements/Section'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { CustomInput } from '../../../components/Input/CustomInput'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { CustomPhoneInput } from '../../../components/Input/CustomPhoneInput'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { CountryAutoComplete, CountryAutoCompleteItem } from '../../../components/Input/CountryAutoComplete'
import { ExplainTypography } from '../../../components/Typographies/ExplainTypography'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'

export interface DetailsForm {
  name: string
  contactEmail: string
  email: string
  contactPerson: string
  contactPhone: string
  phone: string
  postCode: string
  address: string
  city: string
  country: string
  cvr: string
  eori?: string,
  shippingName?: string
  shippingAddress?: string
  shippingPostCode?: string
  shippingCity?: string
  shippingCountry?: string
  shippingContactPerson?: string
  shippingContactPhone?: string
  shippingContactEmail?: string
}

export interface OrganizationDetailForm_VM {
  onDetailsChange: (form: DetailsForm) => void
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  onCountryChange: (country: CountryAutoCompleteItem) => void
  onShippingCountryChange: (country: CountryAutoCompleteItem) => void
  disabled: boolean
  form: DetailsForm
  isVendor?: boolean
}

export function OrganizationDetailForm({ disabled, isVendor, form, onFormChange, onCountryChange, onShippingCountryChange, onDetailsChange }: OrganizationDetailForm_VM) {
  const [useShippingAsInvoice, setUseShippingAsInvoice] = useState(false)
  // This flag exists to ensure no infinite rendering loops happens when the form is being updated as a result of it just updating.
  const [shouldResolveShippingAsInvoice, setShouldResolveShippingAsInvoice] = useState(false)
  const { role } = useProfileContext()

  useEffect(() => {
    if (!shouldResolveShippingAsInvoice) return

    // Get the value according to whether shipping address should be the same as invoice address. 
    const resolveShippingAsInvoice = (invoice: string, shipping?: string): string =>
      useShippingAsInvoice ? invoice : shipping ?? ""
 
    // Resolve all shipping fields based on whether they should be the same an invoice address.
    form.shippingName = resolveShippingAsInvoice(form.name, form.shippingName)
    form.shippingAddress = resolveShippingAsInvoice(form.address, form.shippingAddress)
    form.shippingPostCode = resolveShippingAsInvoice(form.postCode, form.shippingPostCode)
    form.shippingCity = resolveShippingAsInvoice(form.city, form.shippingCity)
    form.shippingCountry = resolveShippingAsInvoice(form.country, form.shippingCountry)
    form.shippingContactPerson = resolveShippingAsInvoice(form.contactPerson, form.shippingContactPerson)
    form.shippingContactPhone = resolveShippingAsInvoice(form.phone, form.shippingContactPhone)
    form.shippingContactEmail = resolveShippingAsInvoice(form.email, form.shippingContactEmail)

    onDetailsChange(form)
    setShouldResolveShippingAsInvoice(false)
  }, [useShippingAsInvoice, form])

  // Wraps `any` change handler to ensure a shipping-as-invoice resolution happens after changing.
  const onChangeProxy = (onChangeFn: Function) => (any: any) => {
    setShouldResolveShippingAsInvoice(true)
    onChangeFn(any)
  }

  // When toggling shipping-as-invoice, also make sure to perform the resoultion.
  const toggleUseShippingAsInvoice = () => {
    setShouldResolveShippingAsInvoice(true)
    setUseShippingAsInvoice(!useShippingAsInvoice)
  }

  const invoideDisabled = disabled || !!isVendor
  const shippingDisabled = role == Role.Vendor ? useShippingAsInvoice : (disabled || useShippingAsInvoice)
  
  return (
    <Section className={'space-y-4'}>
      <SectionHeaderTypography>Invoice Details</SectionHeaderTypography>
      <StrucRow>
        <StrucCol size={3.5}>
          <InputWrapper>
            <InputLabel>company</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.name)}
              value={form.name}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Company Email</InputLabel>
            <CustomInput
              type={'email'}
              name={nameOf<DetailsForm>(lf => lf.email)}
              value={form.email}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Company Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<DetailsForm>(lf => lf.phone)}
              value={form.phone}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>CVR</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.cvr)}
              value={form.cvr}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Contact Person</InputLabel>
            <CustomInput
              type={'email'}
              name={nameOf<DetailsForm>(lf => lf.contactPerson)}
              value={form.contactPerson}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Contact Email</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.contactEmail)}
              value={form.contactEmail}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Contact Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<DetailsForm>(lf => lf.contactPhone)}
              value={form.contactPhone}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>EORI</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.eori)}
              value={form.eori}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Address</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.address)}
              value={form.address}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>City</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.city)}
              value={form.city}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>PostCode</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.postCode)}
              value={form.postCode}
              onChange={onChangeProxy(onFormChange)}
              disabled={invoideDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Country</InputLabel>
            <CountryAutoComplete disabled={invoideDisabled} selectedCode={form.country} onCountryChange={onChangeProxy(onCountryChange)} />
          </InputWrapper>
        </StrucCol>
      </StrucRow>

      <SectionHeaderTypography>Shipping</SectionHeaderTypography>
      <div className={'flex items-center space-x-1.5 pl-1'}>
        <input type="checkbox" onChange={toggleUseShippingAsInvoice} checked={useShippingAsInvoice} />
        <ExplainTypography>Use same shipping details as invoice details</ExplainTypography>
      </div>
      <StrucRow>
        <StrucCol size={3.5}>
          <InputWrapper>
            <InputLabel>Shipping Name</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingName)}
              value={form.shippingName}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Address</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingAddress)}
              value={form.shippingAddress}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping PostCode</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingPostCode)}
              value={form.shippingPostCode}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Shipping City</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingCity)}
              value={form.shippingCity}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Country</InputLabel>
            <CountryAutoComplete disabled={shippingDisabled} selectedCode={form.shippingCountry} onCountryChange={onShippingCountryChange} />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Contact Person</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingContactPerson)}
              value={form.shippingContactPerson}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Shipping Contact Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<DetailsForm>(lf => lf.shippingContactPhone)}
              value={form.shippingContactPhone || ''}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Contact Email</InputLabel>
            <CustomInput
              type={'email'}
              name={nameOf<DetailsForm>(lf => lf.shippingContactEmail)}
              value={form.shippingContactEmail}
              onChange={onChangeProxy(onFormChange)}
              disabled={shippingDisabled}
            />
          </InputWrapper>
        </StrucCol>
      </StrucRow>
    </Section>)
}
