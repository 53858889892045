import { Style } from '../../../core/models/entities/Style'
import { StyleDto, StyleViewDto } from '../responses/StyleDto'
import { StyleView } from '../../../core/models/entities/StyleView'
import { mapToManufacturer } from './mapManufacturer'

export function mapToStyles(dto: StyleDto): Style {
  return {
    ...dto,
    createdAtUtc: dto.createdAtUtc ? new Date(dto.createdAtUtc) : undefined,
    updatedAtUtc: dto.updatedAtUtc ? new Date(dto.updatedAtUtc) : undefined,
    styleViews: dto.styleViews.map(mapToStyleView),
    manufacturer: mapToManufacturer(dto.manufacturer)
  }
}

export function mapToStyleView(dto: StyleViewDto): StyleView {
  return { ...dto}
}
