import { PageQuery } from '../../../../utils/models/pageQuery'
import { Organization } from '../../../../core/models/entities/Organization'
import { Order, OrderStatus } from '../../../../core/models/entities/Order'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { TableData } from '../../../components/GenericTable/FunctionalList'
import { useNavigate } from 'react-router-dom'
import { orderColumnsConfig } from '../functions/orderColumnsConfig'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { OrderRowItem } from '../components/order-list-item.component'
import { GetOverallApprovalStatus } from '../../../../core/models/entities/BaseLogo'
import { ApprovalStatus } from '../../../../core/models/entities/Approval'
import { useOrderActionHandler } from '../../order-create/hooks/useOrderActionsHandler'
import { Profile } from '../../../../core/models/entities/Profile'
import { FilterRecipe } from '../../../components/GenericTable/SubComponents/TableHeader/ListHeader'
import { Role } from '../../../../core/models/entities/Role'

export function useFunctionalListOrdersViewModel(
  profile: Profile,
  lastPage: number,
  query: PageQuery,
  customers: Organization[],
  isPageLoading: boolean,
  vendors: Organization[] | undefined,
  queryChanger: QueryChanger,
  onViewRejectNote: (order: Order) => void,
  onSelectOrder: (order: Order) => void,
  orders?: Order[]
): TableData<Order> {
  const navigate = useNavigate()

  function handleGoToDetailsOrder(order: Order) {
    navigate(`/order/confirm?orderId=${order.id}&orgId=${order.organization.id}`)
  }

  const { handlePlaceVerdict } = useOrderActionHandler()

  const filters = buildOrderFilterRecipes(profile, customers)

  return {
    columns: orderColumnsConfig(),
    tableTitle: 'Orders',
    data: orders,
    onPageSizeChange: queryChanger.handlePageSizeChange,
    onSearchQueryChange: queryChanger.handleSearchQueryChange,
    onNewPage: queryChanger.handleNewPage,
    onFilterChange: queryChanger.handleFilterChange,
    onNewSort: queryChanger.handleSortByField,
    onRowClick: onSelectOrder,
    getKey: getKey,
    isLoading: isPageLoading,
    lastPage: lastPage,
    query: query,
    renderRow: (columns: ColumnSettings, order: Order) => (
      <OrderRowItem
        columns={columns}
        order={order}
        onPlaceVerdict={handlePlaceVerdict}
        onSeeRejectNote={onViewRejectNote}
        onEditDesign={(order) => {
          handleGoToDetailsOrder(order)
        }}
      />
    ),
    filters: filters
  }
}

const getKey = (order: Order) => order.id

function buildOrderFilterRecipes(profile: Profile, customers?: Organization[]) {
  const filters: FilterRecipe<any>[] = []

  if (profile.userOrganizationInformations.role >= Role.Vendor && customers) {
    filters.push({
      options: customers,
      key: 'organization.id',
      getOptionValue: (org) => org.id,
      getOptionDisplay: (org) => org.name,
      defaultTitle: 'Customer'
    } as FilterRecipe<Organization>)
  }

  filters.push({
    options: [OrderState.InProduction, OrderState.Rejected, OrderState.Pending, OrderState.Draft],
    key: 'status',
    getOptionValue: (org) => org as string,
    getOptionDisplay: (org) => org as string,
    defaultTitle: 'Status'
  } as FilterRecipe<OrderState>)

  return filters
}

export function getOrderState(order: Order): OrderState {
  if (order.status === OrderStatus.Draft) {
    return OrderState.Draft
  }

  if (order.status === OrderStatus.Pending) {
    return OrderState.Pending
  }

  if (order.status === OrderStatus.Rejected) {
    return OrderState.Rejected
  }

  if (order.status === OrderStatus.InProduction) {
    return OrderState.InProduction
  }

  switch (GetOverallApprovalStatus(order.approval!)) {
    case ApprovalStatus.Rejected:
      return OrderState.Rejected
    case ApprovalStatus.Approved:
      return OrderState.InProduction
    case ApprovalStatus.Pending:
      return OrderState.Pending
  }
}

export enum OrderState {
  Draft = 'Draft',
  Pending = 'Pending',
  Rejected = 'Rejected',
  InProduction = 'In Production'
}
