import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { t } from 'i18next'
import { DeliveryType, Order, OrderDeliveryPreferences, OrderStatus, Packaging } from '../../../core/models/entities/Order'
import { dissmissToast, useErrorToast, useLoadingToast, useSuccessToast } from '../../components/Toast/Toast'
import { DeliveryTypeOption, OrderDeliveryForm, PackagingOption, getSubTotal } from '../order-confirmation/useOrderConfirmationEditViewModel'
import { OrderDetailsComponentViewModel } from './OrderDetailsComponent'
import { useOrderActionHandler } from '../order-create/hooks/useOrderActionsHandler'

export function useOrderDetailsViewModel(order: Order): OrderDetailsComponentViewModel {

  const { profile } = useProfileContext()
  const { handleReorder } = useOrderActionHandler()

  const [form, setForm] = useState<OrderDeliveryForm>({
    shipToName: order.orderDeliveryAddress?.name ?? "",
    shipToZip: order.orderDeliveryAddress?.zip ?? "",
    shipToContactEmail: order.orderDeliveryAddress?.email ?? "",
    shipToContactPerson: order.orderDeliveryAddress?.contact ?? "",
    shipToCity: order.orderDeliveryAddress?.city ?? "",
    shipToContactPhone: order.orderDeliveryAddress?.phone ?? "",
    shipToAddress: order.orderDeliveryAddress?.address ?? "",
    shipToCountry: order.orderDeliveryAddress?.country ?? "",
    isLoading: false,
    emailtrackAndTrace: "",
    latestDeliveryDate: order.latestDelivery,
    deliveryBefore12PMwithGLS: false,
    deliveryType: order.deliveryType as DeliveryType ?? DeliveryType.Standard,
    packaging: order.packagingType as Packaging ?? Packaging.NoPackaging,
    orderComment: order.orderComment ?? "",
    originOfDelivery: "",
    requisitionNumber: "",
  });

  const deliveryTypeGroupList: DeliveryTypeOption[] = [
    {
      key: DeliveryType.Standard,
      type: DeliveryType.Standard,
      title: "Standard",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 0,
      feeUnit: "%",
      label: "Standard"
    },
    {
      key: DeliveryType.Express24,
      type: DeliveryType.Express24,
      title: "Express 24",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 100,
      feeUnit: "%",
      label: "Exp. 24"
    },
    {
      key: DeliveryType.Express48,
      type: DeliveryType.Express48,
      title: "Express 48",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 50,
      feeUnit: "%",
      label: "Exp. 48"
    },
    {
      key: DeliveryType.Express72,
      type: DeliveryType.Express72,
      title: "Express 72",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 25,
      feeUnit: "%",
      label: "Exp. 72"
    }
  ]

  const packagingGroupList: PackagingOption[] = [
    {
      key: "NoPackaging",
      type: Packaging.NoPackaging,
      title: "No packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 0,
      feeUnit: "",
      label: "No packaging"
    },
    {
      key: "StandardBagPackaging",
      type: Packaging.StandardBagPackaging,
      title: "Standard bag packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 4.5,
      feeUnit: "per pcs",
      label: "Standard bag packaging"
    },
    {
      key: "SpecificPackaging",
      type: Packaging.SpecificPackaging,
      title: "Specific packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 300,
      feeUnit: "per hour",
      label: "Specific packaging"
    }
  ]

  const navigate = useNavigate()

  const { orderRepository } = useRepositoriesContext()

  const { commitOrder } = orderRepository

  function handleBack() {
    navigate('/order')
  }

  function handleOrderFormUpdate(form: OrderDeliveryForm): void {
    setForm({ ...form })
  }

  interface ContextError { message: String, source: String, statusCode: number }

  function reorder() {
    const orgId = profile?.userOrganizationInformations.organizationId!;
    const orderDeliveryPreferences: OrderDeliveryPreferences = {
      address: form.shipToAddress,
      city: form.shipToCity,
      company: form.shipToName,
      contactPerson: form.shipToContactPerson,
      contactPhone: form.shipToContactPhone,
      country: form.shipToCountry,
      deliveryBefore12PMwithGLS: form.deliveryBefore12PMwithGLS,
      deliveryType: form.deliveryType,
      email: form.shipToContactEmail,
      emailtrackAndTrace: form.emailtrackAndTrace,
      latestDeliveryDate: form.latestDeliveryDate,
      orderComment: form.orderComment,
      originOfDelivery: form.originOfDelivery,
      packaging: form.packaging,
      requisitionNumber: form.requisitionNumber,
      zipCode: form.shipToZip
    }

    const toast = useLoadingToast("Committing Logo")
    commitOrder(orgId, order.id, orderDeliveryPreferences).then(() => {
      navigate(`/order/${order.id}/end`)
      useSuccessToast(t("toasts.orders_committed_success"))
    }).catch((error) => {
      console.log(error)
      const contextError = error as ContextError
      const compoundErrorMessage = t("toasts.orders_committed_failed") + (contextError ? ': ' + contextError.message : '')
      navigate(`/order/${order.id}/end`)
      
      useErrorToast(compoundErrorMessage)
    }).finally(() => dissmissToast(toast))
  }
  
  const subTotal = getSubTotal(order);
  const vat = 0.25 * subTotal;
  const totalAmount = subTotal + vat;

  const readonly = order.status != OrderStatus.Draft

  function onBack() {
    navigate('/order')
  }

  const onReorder = async () => {
    await handleReorder(order)
  };

  return {
    orderNo: order.orderNumber,
    orderlines: order.lines,
    deliveryTypeGroupList,
    form,
    handleBack,
    handleConfirm: reorder,
    handleOrderFormUpdate,
    packagingGroupList,
    subTotal,
    vat,
    totalAmount,
    readonly,
    onBack,
    order,
    onReorder
  }
}
