import { Autocomplete, Box, Tooltip, createFilterOptions } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { TransferMethod } from '../../../../core/models/entities/LogoOptions'

export function PrintMethodAutoComplete({ methods, onChange, disabled, value }: {
  methods: TransferMethod[],
  onChange: (method: TransferMethod | null) => void
  disabled: boolean
  value: string | null
}) {
  const filterOptions = createFilterOptions<TransferMethod>({
    limit: 200
  })

  function printLabel(method: TransferMethod) {
    return method.displayName
  }

  const selected = methods.find(x => x.key === value) ?? null
  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    disablePortal
    options={methods}
    sx={{ width: 200 }}
    filterOptions={filterOptions}
    value={selected}
    getOptionLabel={(option) => option.displayName}
    renderOption={(props, option) => {
      // @ts-ignore
      const { key, ...otherProps } = props;
      return (
        <Box
          key={key}
          component='li'
          {...otherProps}
        >
          <div
            className={'flex text-xs items-center gap-x-2'}>
            <Tooltip title={option.description} placement={'right-start'}>
              <FontAwesomeIcon size={'lg'} icon={faQuestionCircle} />
            </Tooltip>
            <p>{printLabel(option)}</p>
          </div>
        </Box>
      )
    }}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-7 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='w-[266px] border-none text-xs font-medium outline-none'
          placeholder={'search for a method..'} />
      </div>
    )}
    onChange={(x, v, r) => onChange(v)}
  />
}

interface CustomAutoCompleteProps<T> {
  disabled?: boolean
  value?: T,
  options: T[],
  getOptionKey: (value: T) => string
  getOptionLabel?: (value: T) => string
  onChange?: (value: T | null) => void
  placeHolder?: string
}

export function CustomAutoComplete<T>({
  disabled,
  value,
  options,
  getOptionLabel,
  getOptionKey,
  onChange,
  placeHolder
}: CustomAutoCompleteProps<T>) {
  const filterOptions = createFilterOptions<T>({
    limit: 200
  })

  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    disablePortal
    options={options}
    filterOptions={filterOptions}
    value={value}
    getOptionLabel={getOptionKey}
    renderOption={(props, option, state, ownerState) => (
      <Box
        component='li'
        {...props}
      >
        <div key={getOptionKey(option)}
          className={'flex text-xs items-center'}>
          <p>{getOptionLabel ? getOptionLabel(option) : getOptionKey(option)}</p>
        </div>
      </Box>
    )}
    renderInput={(params) =>
    (
      <StrucRow
        className='border rounded-md border-gray-200 border-solid h-7 bg-white focus-within:border-gray-600 w-full justify-center'
      >
        <StrucCol size={1.5}>
          <FontAwesomeIcon className={'ml-1'} icon={faMagnifyingGlass} />
        </StrucCol>
        {/*todo: ref*/}
        <StrucCol size={10.5}>
          <input {...params.inputProps} className='border-none text-xs font-medium outline-none ml-1 '
            placeholder={placeHolder} />
        </StrucCol>
      </StrucRow>
    )}
    onChange={(x, v, r) => {
      return onChange ? onChange(v) : undefined
    }}
  />
}