import React, { createContext, useContext, useEffect, useState } from 'react'
import { Design, DesignLogo } from '../../../../core/models/entities/Design'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { NotImplemented } from '../../../shared/functions/not-implemented.function'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { Style } from '../../../../core/models/entities/Style'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import { Direction } from '../../design-editor/design-editor-tool/DesignEditorTool.vm'
import { copy } from '../../../../utils/functions/ObjectUtil'
import { ScaledViewInfo } from '../../design-editor/design-view/DesignView.vm'
import { LogoPlacement, LogoPlacementPosition } from '../../design-editor/models/Placement'
import { Marker } from '../models/marker.model'
import { getCenterOfRotatedLogo, getOriginalTopLeft, rotate } from '../../../../utils/functions/MathUtil'
import { getEmptyLogoCoverage, getLogoMaskCoverage, LogoCoverage } from '../../design-editor/design-style-editor/DesignStyleEditor.vm'
import { XnY } from '../models/x-n-y.model'
import { useStyleViewOperations } from '../hooks/useStyleViewOperations'
import { getLogoPlacementPosition } from '../functions/getLogoPlacementPosition'
import { toMarker } from '../functions/toMaker'
import { capRadianToFullRotation } from '../functions/capRadianToFullRotation'
import { useDesignActionHandler } from '../../design-list/hooks/useDesignActionHandler'
import { useDesignEditorStateContext } from './design-editor-state-provider.context'
import { EditAction } from '../../../../data/models/responses/DesignDto'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import { useApprovalDialogByRole } from '../../../components/Dialogs/useApprovalDialogByRole'
import { DesignStatus } from '../../../../core/models/entities/enums/DesignStatus'
import { useNavigate } from 'react-router'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useLogoContext } from './logo-options.context'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'

export interface DesignProviderContext {
  design: Design
  addToDesign: (logo: BaseLogo) => void,
  selectedView: StyleView
  views: StyleView[]
  selectedLogo?: DesignLogo
  logosOfDesign: DesignLogo[]
  scaledLogosOfDesign: Marker[]
  style: Style,
  lockedLogos: IdLookUps<boolean>
  lockLogo: (logo: DesignLogo, lock: boolean) => void
  changeLogoHeight: (logo: DesignLogo, value: number) => void
  changeLogoWidth: (logo: DesignLogo, value: number) => void
  rotateLogo: (logo: DesignLogo, direction: Direction) => void
  selectView: (view: StyleView) => void
  selectLogo: (view: DesignLogo['inDesignId']) => void
  imageRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  onImageRefSet: () => void
  scaledViewInfo?: ScaledViewInfo
  logoPlacement: IdLookUps<LogoPlacement>
  parentContainerXnY?: XnY,
  onLogoManipulation: (marker: Marker) => void
  removeFromDesign: (designLogo: DesignLogo) => void,
  placementErrorMessage: string
  deleteLogo: (logo: BaseLogo) => void
  editLogo: (designLogo: DesignLogo) => void
  cloneLogo: (designLogo: DesignLogo) => void
  updateDesign: () => void
  approvalDialog?: JSX.Element
  approveDesign: () => void
  readonly: boolean,
  handleGoToChangeStyle: (design: Design) => void
  refreshLogoMarkers: (logo: BaseLogo) => void
  validateAllMarkers: () => void,
  debug: boolean,
  lastCoverageChecked?: Marker
  debugPoints: DebuggablePoint[]
}

// Enable debugging to show the mask instead of the image, 
//  as well as some visual feedback on logo placement and mask areas.
const DEBUG_ENABLED = false
const DEBUG_LOGO_PLACER = process.env.NODE_ENV === 'development' && DEBUG_ENABLED

const DesignContext = createContext<DesignProviderContext | undefined>(undefined)

interface LogoProviderProps {
  children: React.ReactNode
  design: Design
}

export interface DebuggablePoint { x: number, y: number, size?: number, color?: string }

function DesignProvider({
  children,
  design
}: LogoProviderProps): React.ReactElement {

  const { goToState } = useDesignEditorStateContext()
  const { setLogoToEdit } = useLogoContext()

  const [designLogos, setDesignLogos] = useState<DesignLogo[]>(design.designLogos)
  const [activeViewMarkers, setActiveViewMarkers] = useState<Marker[]>()
  const [allMarkers, setAllMarkers] = useState<Marker[]>()
  const [selectedLogoId, setSelectedLogoId] = useState<DesignLogo['inDesignId']>()

  const [lastCoverageChecked, setLastCoverageChecked] = useState<Marker>()
  const [debugPoints, setDebugPoints] = useState<DebuggablePoint[]>([])

  const [lockedLogos, setLockedLogos] = useState<IdLookUps<boolean>>({})
  const [logoPlacement, setLogoPlacement] = useState<IdLookUps<LogoPlacement>>({})
  const {
    fullsizeViewInfoPerView,
    scaledViewInfoPerView,
    parentContainerXnY,
    imageRef,
    changeStyle,
    selectView,
    selectedView,
    style,
    views,
    containerRef,
    onImageRefSet
  } = useStyleViewOperations(design.style)

  const navigate = useNavigate()

  const { handleDeleteLogo, handleCopyLogo } = useLogoActionHandler()

  const { handleUpdateDesign: updateDesign, handleInitiateApproval, handleApprovalSkip, handleSkipAddToCart } = useDesignActionHandler()
  const { setLoading } = useDesignEditorStateContext()
  const { profile } = useProfileContext()
  const role = profile?.userOrganizationInformations.role ?? Role.Admin

  async function refreshMarkerPositionStatuses(allMarkers: Marker[], activeMarkers: Marker[]) {
    if (!selectedView) {
      return
    }

    for (const marker of allMarkers) {
      const filtered = allMarkers.filter(x => x.viewId === marker.viewId)

      const { position } = getCoverageAndPositionStatus(marker, filtered, scaledViewInfoPerView[marker.viewId])

      const logoPlacement: LogoPlacement = {
        status: position,
        viewId: marker.viewId
      }
      setLogoPlacement(x => ({
        ...x,
        [marker.id]: logoPlacement
      }))
    }
  }

  useEffect(() => {
    if (!selectedView || Object.keys(scaledViewInfoPerView).length != views.length) {
      return
    }

    const currentViewMarkers = designLogos.filter(x => x.viewId === selectedView.id).map(dl => toMarker(dl, scaledViewInfoPerView[dl.viewId]))
    const allMarkers = designLogos.map(dl => toMarker(dl, scaledViewInfoPerView[dl.viewId]))
    setActiveViewMarkers(currentViewMarkers)
    setAllMarkers(allMarkers)
    refreshMarkerPositionStatuses(allMarkers, currentViewMarkers).then()

  }, [scaledViewInfoPerView, selectedView])

  function addToDesign(logo: BaseLogo) {
    if (!selectedView) {
      return
    }

    const viewId = selectedView.id
    const designLogo: DesignLogo = {
      id: 0,
      inDesignId: crypto.randomUUID(),
      logoId: logo.id,
      logo: logo,
      viewId: viewId,
      logoX: 0,
      logoY: fullsizeViewInfoPerView[selectedView?.id].height ? (fullsizeViewInfoPerView[selectedView?.id].height / 2 - logo.heightMm / 2) : 0,
      angleRads: 0,
      widthMm: logo.widthMm,
      heightMm: logo.heightMm,
      action: EditAction.Move,
      viewName: selectedView.view,
    }
    const marker = toMarker(designLogo, scaledViewInfoPerView[selectedView.id])

    const { position } = getCoverageAndPositionStatus(marker, activeViewMarkers!, scaledViewInfoPerView[viewId])

    setDesignLogos(ls => [...updateLogosInDesign(logo, ls), designLogo])
    setActiveViewMarkers(ls => ls ? [...ls, marker] : [marker])

    const logoPlacement: LogoPlacement = {
      status: position,
      viewId: marker.viewId
    }
    setLogoPlacement(x => ({
      ...x,
      [marker.id]: logoPlacement
    }))
  }

  function removeFromDesign(designLogo: DesignLogo) {
    const removeId = designLogo.inDesignId

    setDesignLogos(ls => ls.filter(l => l.inDesignId !== removeId))
    setActiveViewMarkers(ms => {
      const result = ms ? ms.filter(m => m.id !== removeId) : []
      refreshMarkerPositionStatuses(result!, result!).then()
      return result
    })
    setLogoPlacement(x => {
      delete x[removeId]
      return x
    })
  }

  function updateLogosInDesign(logo: BaseLogo, designLogos: DesignLogo[]) {
    // Purpose of this method is to update the design logos if by change that the logo is updated by color, size, etc.
    const filteredDesignLogos = designLogos.map(x => {
      if (x.logo.id === logo.id) {
        return {
          ...x,
          logo: logo
        }
      }

      return x;
    })

    return filteredDesignLogos;
  }

  function editLogo(designLogo: DesignLogo) {
    setLogoToEdit(designLogo.logo)
    goToState(DesignEditorStates.EditLogo)
  }

  function cloneLogo(designLogo: DesignLogo) {
    setLoading(true)
    handleCopyLogo(designLogo.logo)
      .then(x => {
        setLogoToEdit(x)
        goToState(DesignEditorStates.EditLogo)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function deleteLogo(logo: BaseLogo) {
    handleDeleteLogo(logo)
  }

  function handleLockLogo(logo: DesignLogo, lock: boolean) {
    setLockedLogos(x => ({
      ...x,
      [logo.inDesignId]: lock
    }))
  }

  function changeLogoHeight(logo: DesignLogo, value: number) {
    NotImplemented('changeLogoHeight')
  }

  function changeLogoWidth(logo: DesignLogo, value: number) {
    NotImplemented('changeLogoWidth')
  }

  function rotateLogo(logo: DesignLogo, direction: Direction) {
    const rotateId = logo.inDesignId
    const markerToRotate = activeViewMarkers?.find(m => m.id === rotateId)
    if (!markerToRotate) {
      return
    }
    const mod = direction === Direction.Left ? -(Math.PI / 2) : (Math.PI / 2)

    markerToRotate.rotationRad = capRadianToFullRotation(markerToRotate.rotationRad + mod)
    onLogoManipulation(markerToRotate).then()
  }

  async function onLogoManipulation(
    marker: Marker
  ) {
    const logo = designLogos.find(dl => marker.id === dl.inDesignId)
    if (!logo) {
      return
    }

    const scaledInfo = scaledViewInfoPerView[logo.viewId]
    const { styleToViewSizeDelta: sizeDiff, ppcm } = scaledInfo
    // new logo info
    // We set rotation to 0 here, because the logo is already rotated.
    // For more details, examine the comments in the debug section of the getCoverageAndPositionStatus function.
    const { x: ox, y: oy } = getOriginalTopLeft(marker.x, marker.y, marker.widthPx, marker.heightPx, 0)
    const updatedLogo = copy(logo!)
    updatedLogo.angleRads = marker.rotationRad
    updatedLogo.logoX = ox / sizeDiff
    updatedLogo.logoY = oy / sizeDiff
    updatedLogo.heightMm = marker.heightPx / (ppcm / 10)
    updatedLogo.widthMm = marker.widthPx / (ppcm / 10)

    // coverage
    const { position, coverage } = getCoverageAndPositionStatus(marker, activeViewMarkers!, scaledInfo)

    // replaceLogo
    setDesignLogos(dls => dls?.map(dl => {
      if (dl.inDesignId === updatedLogo.inDesignId) {
        return updatedLogo
      }
      return dl
    }))

    setActiveViewMarkers(x => x?.map(y => {
      if (y.id === marker.id) {
        return marker
      }
      return y
    }))

    const logoPlacement: LogoPlacement = {
      status: position,
      viewId: marker.viewId
    }

    setLogoPlacement(x => ({
      ...x,
      [marker.id]: logoPlacement
    }))
  }

  function getCoverageAndPositionStatus(
    marker: Marker,
    overlappingMarkers: Marker[],
    scaledViewInfo: ScaledViewInfo
  ): { position: LogoPlacementPosition, coverage: LogoCoverage } {
    const { height: viewHeight, width: viewWidth, maskImage, maskCanvas } = scaledViewInfo
    const { x, y, heightPx, widthPx, rotationRad } = marker

    const ctx = maskCanvas.getContext('2d', { willReadFrequently: true })
    if (!ctx) return { position: LogoPlacementPosition.UNKNOWN, coverage: getEmptyLogoCoverage() }

    const coverage = getLogoMaskCoverage(x, y, heightPx, widthPx, rotationRad, {
      maskCanvasCtx: ctx,
      maskImage: maskImage,
      width: viewWidth,
      height: viewHeight
    })
    const position = getLogoPlacementPosition(marker, overlappingMarkers, coverage)

    // Debugging tools for the logo placer.
    // Keep in mind that all logic code in this block is replicated.
    // That is the say that it is not reflective of the logic that is actually implemented, but just attempts to mimic it.
    // Changing the implemented logic may make some or all of the code below obsolete.
    //#region Debug logo placer
    if (DEBUG_LOGO_PLACER) {
      // Include the original top left as a reference point.
      const m = { x, y } as DebuggablePoint
      const offsetRadians = 0 //rotationRad 

      // We also do not want to change the rotation when looking for the center. Color the center of the logo golden like the sun.
      const c = getCenterOfRotatedLogo(x, y, widthPx, heightPx, offsetRadians) as DebuggablePoint
      c.color = 'gold'

      // At this stage the original top left function actually re-rotates the logo, resulting in an incorrect output.
      const o = getOriginalTopLeft(x, y, widthPx, heightPx, offsetRadians) as DebuggablePoint
      o.color = 'black'

      const points: DebuggablePoint[] = [
        m,
        c,
        o,
      ]

      // Mimic the calculation loop used by getLogoMaskCoverage
      // This will plot a point for every pixel in the coverage, which is incredibly slow.
      // for (let ix = x; ix < x + widthPx; ix++) {
      //   for (let iy = y; iy < y + heightPx; iy++) {
      //     const r = rotate(c.x, c.y, ix, iy, rotationRad)
      //     points.push({ ...r, size: 1 })
      //   }
      // }

      // Because the marker is already is the correct state here, we just parameterize x and y to be able to place
      const getDebuggableMarkerFromXY = (x: number, y: number): Marker => ({ ...marker, x, y })
      const debuggableMarker = getDebuggableMarkerFromXY(o.x, o.y)
      setLastCoverageChecked(debuggableMarker)
      setDebugPoints(points)

      console.log('Logo marker:', marker)
      console.log('Mask marker:', debuggableMarker)
    }
    //#endregion

    return { coverage, position }
  }

  function buildPlacementErrorMessage(): string {
    let errorMsg = ''
    let hasInvalid = false

    for (const key in logoPlacement) {
      switch (logoPlacement[key].status) {
        case LogoPlacementPosition.INVALID:
          hasInvalid = true
          break
        case LogoPlacementPosition.OVERLAPPING:
          hasInvalid = true
          break
        case LogoPlacementPosition.VALID:
        case LogoPlacementPosition.UNKNOWN:
          break

      }
    }

    if (hasInvalid) {
      errorMsg = 'A logo in your design can’t be placed at its current placement.'
    }

    return errorMsg
  }

  function handleUpdateDesign(): void {
    async function transaction() {
      design.designLogos = designLogos;
      return await updateDesign(design)
    }

    setLoading(true)
    transaction()
      .then((updatedDesign) => {
        setDesignLogos(updatedDesign.designLogos)
      })
      .finally(() => setLoading(false))
  }

  const { component, start } = useApprovalDialogByRole<Design>({
    role,
    subject: "Design",
    onSkip: entity => handleApprovalSkip(entity).then(() => navigate("/design")),
    onOrder: (entity) => handleSkipAddToCart(entity, 10).then(() => navigate("/design")),
    onInitApproval: (entity, addExternal, email1) => handleInitiateApproval(entity, {
      vendor: true,
      customer: true,
      external: addExternal ?? false,
      externalEmail: email1
    }).then(() => {
      navigate('/design')
    })
  })

  function handleApproveDesign(): void {
    async function transaction() {
      design.designLogos = designLogos;
      return await updateDesign(design)
    }

    setLoading(true)
    transaction()
      .then((res) => {
        setDesignLogos(res.designLogos)
        start(res)
        return res
      })
      .finally(() => setLoading(false))
  }

  function handleGoToChangeStyle(design: Design) {
    navigate(`/design/${design.id}/changestyle?orgId=${design.customer.id}`)
  }

  function refreshLogoMarkers(logo: BaseLogo) {
    setActiveViewMarkers(ls => ls ? ls.map(marker => {
      if (marker.logo.id === logo.id) {
        marker.logo = logo
        marker.imageUrl = logo.displayImage.highResUrl
      }
      return marker;
    }) : [])

    setDesignLogos(dl => dl ? dl.map(designLogo => {
      if (designLogo.logoId === logo.id) {
        designLogo.logo = logo
      }
      return designLogo
    }) : [])
  }

  function validateAllMarkers() {

  }

  const readonly = design.status === DesignStatus.Approval || design.status === DesignStatus.Done

  const value: DesignProviderContext = {
    design,
    addToDesign,
    logosOfDesign: designLogos,
    selectedLogo: designLogos.find(l => l.inDesignId === selectedLogoId),
    selectedView: selectedView!,
    views: views,
    style: style,
    lockedLogos,
    lockLogo: handleLockLogo,
    changeLogoWidth: changeLogoWidth,
    changeLogoHeight: changeLogoHeight,
    rotateLogo,
    selectView: selectView,
    imageRef: imageRef,
    containerRef: containerRef,
    onImageRefSet,
    scaledViewInfo: scaledViewInfoPerView[selectedView!.id],
    selectLogo: setSelectedLogoId,
    scaledLogosOfDesign: activeViewMarkers ?? [],
    logoPlacement: logoPlacement,
    parentContainerXnY: parentContainerXnY,
    onLogoManipulation: (marker) => onLogoManipulation(marker).then(),
    removeFromDesign,
    placementErrorMessage: buildPlacementErrorMessage(),
    deleteLogo: deleteLogo,
    editLogo: editLogo,
    cloneLogo: cloneLogo,
    updateDesign: handleUpdateDesign,
    approvalDialog: component,
    approveDesign: handleApproveDesign,
    readonly,
    handleGoToChangeStyle,
    refreshLogoMarkers,
    validateAllMarkers,
    lastCoverageChecked,
    debugPoints: debugPoints,
    debug: DEBUG_LOGO_PLACER
  }

  return (
    <DesignContext.Provider
      value={value}>
      {children}
    </DesignContext.Provider>
  )
}


function useDesignContext() {
  const context = useContext(DesignContext)
  if (!context) {
    throw new Error('useDesignContext must be used within a RepositoriesProvider')
  }

  return context
}


export { DesignProvider, useDesignContext, DesignContext }

