import { IdLookUps } from '../../../../utils/models/idLookUp'

export function getUnique<T = object>(array: T[], getKey: (obj: T) => string) {
  const map: IdLookUps<T> = {}

  for (const obj of array) {
    map[getKey(obj)] = obj
  }

  const result: T[] = []

  for (const mapKey in map) {
    result.push(map[mapKey])
  }

  return result
}