import {
  DesignDto,
  DesignLogoDto,
  HeatingProductRefDto,
  MultiProductRefDto
} from '../responses/DesignDto'
import {
  Design,
  DesignLogo,
  HeatingProductRef,
  MultiProductRef
} from '../../../core/models/entities/Design'
import { mapToOrganization } from './mapOrganization'
import { mapToStyles } from './mapStyle'
import { Price } from '../../../core/models/entities/Price'
import { mapToLogo, mapToProductRef } from './mapLogo'
import { mapToApprovalGroup } from './ApprovalMapper'
import { PriceDto } from '../responses/PriceDto'

export function mapToDesign(dto: DesignDto): Design {
  
  return {
    ...dto,
    updateAtUtc: new Date(dto.updateAtUtc),
    createdAtUtc: new Date(dto.createdAtUtc),
    designLogos: dto.designLogos ? dto.designLogos.map(mapToDesignLogo) : [],
    customer: mapToOrganization(dto.customer),
    style: mapToStyles(dto.style),
    approval: dto.approval ? mapToApprovalGroup(dto.approval) : null,
    product: dto.product ? mapMultiProductRef(dto.product) : null,
    heating: dto.heating ? mapHeatingProductRef(dto.heating) : null
  }
}

export function mapMultiProductRef(dto: MultiProductRefDto): MultiProductRef {
  return {
    ...dto,
    totalPrices: dto.totalPrices.map(mapToPrice),
    products: dto.products.map(mapToProductRef)
  }
}

export function mapHeatingProductRef(dto: HeatingProductRefDto): HeatingProductRef {
  return {
    ...dto,
    prices: dto.prices?.length > 1 ? dto.prices?.map(mapToPrice) : undefined
  }
}

export function mapToPrice(dto: PriceDto): Price {
  return { ...dto, currency: dto.currency ?? 'DKK' }
}

function mapToDesignLogo(dto: DesignLogoDto): DesignLogo {
  return {
    ...dto,
    logo: mapToLogo(dto.logo),
    createdAtUtc: new Date(dto.createdAtUtc),
    updateAtUtc: new Date(dto.updateAtUtc)
  }
}
