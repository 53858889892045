import { Marker } from '../../models/marker.model'
import { LogoPlacementPosition } from '../../../design-editor/models/Placement'
import React from 'react'
import { LogoImage } from '../../../../components/Logo/LogoImage'


export interface LogoMarker_VM {
  marker: Marker
  logoPlacements: LogoPlacementPosition,
  isLocked: boolean
  isActive: boolean
  markerRef: React.RefObject<HTMLDivElement>
  onSelect: () => void
}

export function LogoMarker({ marker, logoPlacements, isLocked, isActive, markerRef, onSelect }: LogoMarker_VM) {
  function getLogoBorderColor(): string {
    switch (logoPlacements) {
      case LogoPlacementPosition.INVALID:
        return 'border-red'
      case LogoPlacementPosition.OVERLAPPING:
        return 'border-red'
      case LogoPlacementPosition.VALID:
        return 'border-green'
      case LogoPlacementPosition.UNKNOWN:
        return 'border-gray-600'
      default:
        return 'border-gray-600'
    }
  }

  function buildLogoBorder(): string {
    let borderAttributes = 'border'
    if (isLocked) {
      if (isActive) {
        borderAttributes += ' border-solid border-1 border-blue'
      } else {
        borderAttributes += ' border-solid border-1 border-transparent'
      }
    } else {
      borderAttributes += ' ' + getLogoBorderColor()

      if (isActive) {
        borderAttributes += ' border-solid border-1'
      } else {
        borderAttributes += ' border-dashed border-1'
      }
    }

    return borderAttributes
  }
 
  function buildLogoBg(): string {
    return logoPlacements === LogoPlacementPosition.VALID ? 'bg-[#7BBB5840]' : 'bg-[#C6353540]'
  }

  return (
    <div
      id={`box-${marker.id}`}
      ref={markerRef}
      className={`box box-border rounded absolute z-[2] ${buildLogoBorder()} ${buildLogoBg()} ${isActive ? isLocked ? '' : 'cursor-move' : 'cursor-pointer'}`}
      onClick={x => onSelect()}
    >
      <LogoImage className='w-full h-full' useDigit={true} logo={marker.logo} />
      {!isLocked && isActive && (
        <>
          {/*<div
            id={`item_rotater-${marker.id}`}
            className='item_rotater absolute w-[10px] h-[10px] bg-white border-solid border-2 rounded-md border-purple-500 left-full bottom-full -translate-x-1/3 translate-y-1/3'
          />*/}
          {/*
          {logoType === LogoType.NORMAL && (
            <div
              id={`item_resizer-${marker.id}`}
              className='absolute w-[10px] h-[10px] bg-white border-solid border-2 rounded-md left-full top-full -translate-x-1/3 -translate-y-1/3'
            />
          )}*/}
        </>
      )}
    </div>
  )
}


