import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { DeliveryType, Order, OrderDeliveryPreferences, OrderLine, OrderLineDesign, OrderLineLogo, OrderLineProduct, OrderStatus, Packaging } from '../../../core/models/entities/Order'
import { OrderDeliveryFormViewModel } from './OrderConfirmationComponent'
import { IdLookUps } from '../../../utils/models/idLookUp'
import { getProductPrice } from '../../../core/models/entities/BaseLogo'
import { groupBy } from '../../../utils/functions/GroupBy'
import { useOrderActionHandler } from '../order-create/hooks/useOrderActionsHandler'
import { Role } from '../../../core/models/entities/Role'

export interface OrderDeliveryForm {
  shipToName: string
  shipToZip: string
  shipToContactEmail: string
  shipToContactPerson: string
  shipToCity: string
  shipToContactPhone: string
  shipToAddress: string
  shipToCountry: string
  emailtrackAndTrace: string

  latestDeliveryDate: Date
  deliveryBefore12PMwithGLS: boolean

  deliveryType: DeliveryType
  packaging: Packaging

  orderComment: string
  originOfDelivery: string
  requisitionNumber: string
}

export interface ProductGroup {
  orderlines: OrderLine[]
}

export interface DeliveryTypeOption { key: string, type: DeliveryType, title: string, description: string, feePercentage: number, feeUnit: string, label: string }
export interface PackagingOption { key: string, type: Packaging, title: string, description: string, feeAmount: number, feeUnit: string, label: string }

export function useOrderConfirmationEditViewModel(order: Order): OrderDeliveryFormViewModel {

  const { profile, role } = useProfileContext();

  const [form, setForm] = useState<OrderDeliveryForm>({
    shipToName: profile?.shippingPreferences?.shippingName ?? "",
    shipToZip: profile?.shippingPreferences?.shippingPostCode ?? "",
    shipToContactEmail: profile?.shippingPreferences?.shippingContactEmail ?? "",
    shipToContactPerson: profile?.shippingPreferences?.shippingContactPerson ?? "",
    shipToCity: profile?.shippingPreferences?.shippingCity ?? "",
    shipToContactPhone: profile?.shippingPreferences?.shippingContactPhone ?? "",
    shipToAddress: profile?.shippingPreferences?.shippingAddress ?? "",
    shipToCountry: profile?.shippingPreferences?.shippingCountry ?? "",
    emailtrackAndTrace: "",
    latestDeliveryDate: order.latestDelivery,
    deliveryBefore12PMwithGLS: false,
    deliveryType: order.deliveryType ?? DeliveryType.Standard,
    packaging: order.packagingType ?? Packaging.NoPackaging,
    orderComment: "",
    originOfDelivery: "",
    requisitionNumber: "",
  });


  useEffect(() => {
    setForm({...form, latestDeliveryDate: order.latestDelivery})
  }, [order.deliveryType]);

  const deliveryTypeGroupList: DeliveryTypeOption[] = [
    {
      key: DeliveryType.Standard,
      type: DeliveryType.Standard,
      title: "Standard",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 0,
      feeUnit: "%",
      label: "Standard"
    },
    {
      key: DeliveryType.Express24,
      type: DeliveryType.Express24,
      title: "Express 24",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 100,
      feeUnit: "%",
      label: "Express 24"
    },
    {
      key: DeliveryType.Express48,
      type: DeliveryType.Express48,
      title: "Express 48",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 50,
      feeUnit: "%",
      label: "Express 48"
    },
    {
      key: DeliveryType.Express72,
      type: DeliveryType.Express72,
      title: "Express 72",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 25,
      feeUnit: "%",
      label: "Express 72"
    }
  ]

  const packagingGroupList: PackagingOption[] = [
    {
      key: "NoPackaging",
      type: Packaging.NoPackaging,
      title: "No packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 0,
      feeUnit: "",
      label: "No packaging"
    },
    {
      key: "StandardBagPackaging",
      type: Packaging.StandardBagPackaging,
      title: "Standard bag packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 4.5,
      feeUnit: "per pcs",
      label: "Standard bag packaging"
    },
    {
      key: "SpecificPackaging",
      type: Packaging.SpecificPackaging,
      title: "Specific packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 300,
      feeUnit: "per hour",
      label: "Specific packaging"
    }
  ]

  const orderlines = order.lines.filter((line) => !!line);

  const navigate = useNavigate()

  const { handleCommitOrder, handleChangeOrder, handleChangePackagingOrDelivery } = useOrderActionHandler()

  function handleBack() {
    navigate('/order/place')
  }

  function handlePackagingOrDeliveryChange(deliveryType: DeliveryType, packaging: Packaging) {
    handleChangePackagingOrDelivery(order, deliveryType, packaging)
  }

  function handleOrderFormUpdate(form: OrderDeliveryForm): void {
    setForm({ ...form })
  }

  function handleChange() {
    handleChangeOrder(order).then(() => navigate('/order/place'))
  }

  function handleConfirm() {
    const orgId = role === Role.Admin ? order.organization.id : profile?.userOrganizationInformations.organizationId!
    const orderDeliveryPreferences = {
      address: form.shipToAddress,
      city: form.shipToCity,
      company: form.shipToName,
      contactPerson: form.shipToContactPerson,
      contactPhone: form.shipToContactPhone,
      country: form.shipToCountry,
      deliveryBefore12PMwithGLS: form.deliveryBefore12PMwithGLS,
      deliveryType: form.deliveryType,
      email: form.shipToContactEmail,
      emailtrackAndTrace: form.emailtrackAndTrace,
      latestDeliveryDate: form.latestDeliveryDate,
      orderComment: form.orderComment,
      originOfDelivery: form.originOfDelivery,
      packaging: form.packaging,
      requisitionNumber: form.requisitionNumber,
      zipCode: form.shipToZip
    }

    handleCommitOrder(orgId, order.id, orderDeliveryPreferences).then((order) => {
      navigate(`/order/end?orderId=${order.id}&orgId=${order.organization.id}`)
    })
  }

  const pricePerProductByTotalQuantity = cachePriceBySkuQuantity(orderlines)

  function cachePriceBySkuQuantity(orderlines: OrderLine[]): IdLookUps<number> {
    let dict: IdLookUps<number> = {}

    orderlines.forEach(element => {
      switch (element.product.productType) {
        case 'Design': {
          const design = (element as OrderLineDesign);
          if (dict[design.product.productMul!] == undefined) {
            dict[design.product.productMul!] = design.quantity ?? 0
          } else {
            dict[design.product.productMul!] += design.quantity ?? 0
          }
          return;
        }
        case 'Logo': {
          const logo = (element as OrderLineLogo);
          if (dict[logo.product.productSku!] == undefined) {
            dict[logo.product.productSku!] = logo.quantity ?? 0
          } else {
            dict[logo.product.productSku!] += logo.quantity ?? 0
          }
          return;
        }
      }
    });

    return dict
  }

  function getQuantity(product: OrderLineProduct): number {

    const mul = product.productMul ?? ''
    const sku = product.productSku ?? ''

    switch (product.productType) {
      case 'Design': return pricePerProductByTotalQuantity[mul];
      case 'Logo': return pricePerProductByTotalQuantity[sku];
      default: return 0;
    }
  }

  const subTotal = getSubTotal(order);
  const vat = 0.25 * subTotal;
  const totalAmount = subTotal + vat;
  const readonly = order.status != OrderStatus.Draft
  function validateForm(form: OrderDeliveryForm) {
    return form.shipToName?.length > 0
      && form.shipToZip?.length > 0
      && form.shipToContactPerson?.length > 0
      && form.shipToCity?.length > 0
      && form.shipToAddress?.length > 0
      && form.shipToCountry?.length > 0
  }

  const isFormValid = validateForm(form)

  return {
    order,
    orderNo: order.orderNumber,
    orderlines,
    deliveryTypeGroupList,
    form,
    handleBack,
    handleChange,
    handleConfirm,
    handlePackagingOrDeliveryChange,
    handleOrderFormUpdate,
    packagingGroupList,
    subTotal,
    vat,
    totalAmount,
    readonly,
    isFormValid
  }
}

// TODO This function is deprecated in favor of backend operations.
// All references to this and other similar total/quantity calculations 
//  should be considered deprecated and not be used in the future.
export function getSubTotal(order: Order): number {
  const orderlines = order.lines.filter((line) => !!line)
  const productDict = groupBy(orderlines, i => i.product.productNo);
  const productGroups = Object.values(productDict).map(orderlines => ({ orderlines } as ProductGroup))

  const productGroupTotal = productGroups.flatMap(group => group.orderlines).map((orderline) => {
    switch (orderline.product.productType) {
      case 'Design': {
        const design = (orderline as OrderLineDesign)
        const designLogoAdjustedPrices = design.design.designLogos.map(designLogo => {
          const logoPrice = order.logoPrices.find(price => designLogo.logoId === price.logoId)
          const adjustedPrice = logoPrice?.adjustedPrice ?? 0
          return adjustedPrice
        })
        const quantity = orderline.quantity ?? 0
        const total = designLogoAdjustedPrices.reduce((sum, current) => sum + current * quantity, 0)
        return total
      }
      case 'Logo': {
        const logo = (orderline as OrderLineLogo)
        const logoPrice = order.logoPrices.find(price => logo.baseLogo.id === price.logoId)
        const adjustedPrice = logoPrice?.adjustedPrice ?? 0
        const total = adjustedPrice * orderline.quantity ?? 0
        return total
      }
      default:
        return 0
    }
  })
    .filter((value) => value !== 0)
    .reduce((sum, currentValue) => sum + currentValue, 0)

  const heatingTotal = order.heating.reduce((sum, currentValue) => {
    const qtyPrice = getProductPrice(currentValue.product.prices, currentValue.quantity)
    return sum + currentValue.quantity * (qtyPrice?.adjustedPrice ?? 0)
  }, 0)

  const additionalFees = order.lines
    .filter((line) => !!line)
    .flatMap(x => x.additionalFees)
    .map(fee => fee.unitPrice * fee.quantity)
    .reduce((sum, fee) => sum + fee, 0)

  return productGroupTotal + heatingTotal + additionalFees
}