import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

import { BaseLogo, ImageLogo, isDigital, LogoEditStatus } from '../../../../../core/models/entities/BaseLogo'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { BinaryStatusGoodBad } from './binary-status-good-bad.component'
import IconButton from '../../../../components/LogoButton/IconButton'
import { LogoDimensions } from '../../../../components/Logo/LogoDimensions'
import { getColorsElements } from '../../../order-create/subcomponents/ProductDetailsLogo'
import { LogoTransferMethod } from '../../../../components/Logo/LogoTransferMethod'
import { AddIcon, TrashcanIcon } from '../../../../../resources/icons/Icons'
import { LogoImage } from '../../../../components/Logo/LogoImage'

export function LogoCardLibrary({ logo, onAdd, onDelete }: {
  logo: BaseLogo,
  onAdd: () => void,
  onDelete: () => void
}) {
  const isRaster = isDigital(logo)
  const color = getColorsElements(logo)

  function actions(logo: ImageLogo) {
    switch (logo.status) {
      case LogoEditStatus.Rejected:
      case LogoEditStatus.Unknown: {
        break;
      }
      case LogoEditStatus.Done:
      case LogoEditStatus.Validating:
      case LogoEditStatus.Draft: {
        return <>
          <IconButton width={'full'} key={'add'} icon={<AddIcon />} textTransformation='normal-case' onClick={onAdd}>
            Add
          </IconButton>
          <IconButton width={'full'} key={'delete'} icon={<TrashcanIcon />} textTransformation='normal-case' onClick={onDelete}>
            Delete
          </IconButton>
        </>
      }
    }
  }

  const buttons = actions(logo as ImageLogo)

  return <div className={`border rounded bg-gray-100`}>
    <StrucRow className={'pl-1 pr-2 py-1 border-b'}>
      <StrucCol className={'!justify-between !flex-row items-center'}>
        <div className={'text-[#1B76DF] flex items-center space-x-2'}>
          <FontAwesomeIcon className={'text-xs px-1.5 py-1 rounded border'} icon={faFile} />
          <span className={'max-w-[200px] overflow-x-hidden overflow-ellipsis whitespace-nowrap'}>{logo.title}</span>
        </div>
        <BinaryStatusGoodBad verdict={!isRaster} goodOption={'Vector'} badOption={'Raster'} />
      </StrucCol>
    </StrucRow>
    <StrucRow>
      <StrucCol size={3.5} className={'bg-white p-3'}>
        <LogoImage logo={logo} className={'object-contain h-16'} />
      </StrucCol>
      <StrucCol size={5} className={'pl-2 pt-1 text-xs space-y-1'}>
        <div>
          <span className={'uppercase font-bold'}>Colors</span>
          <div>
            {color}
          </div>
        </div>
        <LogoDimensions logo={logo} />
        <span
          className={'font-bold'}><LogoTransferMethod logo={logo} /></span>
      </StrucCol>
      <StrucCol className={'pr-2 pt-2 space-y-2'} size={3.5}>
        {buttons}
      </StrucCol>
    </StrucRow>
  </div>

}

