import { ProductPreviewLogo } from './ProductPreviewLogo'
import { ProductDetailsLogo } from './ProductDetailsLogo'
import { CartItemLogo } from '../models/CartItem'

interface OrderLineViewLogoProps {
  orderline: CartItemLogo
}

export function OrderLineViewLogo({ orderline }: OrderLineViewLogoProps) {

  const orderlineDetails = orderline as CartItemLogo

  return (
    <div id={'main-box'} className={'flex-1 flex space-x-10 pb-8 pt-4'}>
      <div id={'left-box'} className={'flex-1 space-y-2 flex-1'}>
        <div className={'flex flex-1'} style={{ height: "100%"}}>
          <ProductPreviewLogo orderline={orderline} />
        </div>
      </div>
      <div id={'right-box'} className={'flex-1 flex flex-col'}>
        <div className={'flex items-start flex-1'}>
          <ProductDetailsLogo orderLine={orderlineDetails} />
        </div>
      </div>
    </div>
  )
}