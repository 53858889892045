import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent';
import { OrganizationDetailForm, OrganizationDetailForm_VM, DetailsForm } from '../../customer-create/components/organization-details-form.component';
import { CountryAutoCompleteItem } from '../../../components/Input/CountryAutoComplete';

interface WrapperProps {
  initialForm: DetailsForm
  onFormChange: (form: DetailsForm) => void
  disabled: boolean
}

export function OrganizationDetailsEditorWrapper({ disabled, onFormChange, initialForm: organization }: WrapperProps) {
  const vm = useViewModel(organization, onFormChange, disabled)
  return <OrganizationDetailForm {...vm} />;
}

function useViewModel(initialForm: DetailsForm,
  onFormChange: (form: DetailsForm) => void,
  disabled: boolean): OrganizationDetailForm_VM {

  const form = {
    ...initialForm
  };

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    //@ts-ignore
    form[name] = value

    onFormChange(form)
  }

  function handleCountryChange(country: CountryAutoCompleteItem) {
    onFormChange({ ...form, country: country.code })
  }

  function handleShippingCountryChange(country: CountryAutoCompleteItem) {
    onFormChange({ ...form, shippingCountry: country.code })
  }

  return {
    disabled,
    form,
    onDetailsChange: onFormChange,
    onFormChange: handleFormChange,
    onCountryChange: handleCountryChange,
    onShippingCountryChange: handleShippingCountryChange
  }
}
