import { BaseLogo, DeliveryVariant, ImageRef, LogoType, LogoVariant } from "../../../../core/models/entities/BaseLogo"
import { Design } from "../../../../core/models/entities/Design"

export interface CartItem {
    id: string
    title: string
    displayImage: ImageRef
    type: "Logo" | "Design",
}

export enum DesignSize {
    SizeS = 'S',
    SizeM = 'M',
    SizeL = 'L',
    SizeXL = 'XL'
}

export interface QuantityRow {
    label: string
    quantity: number
}

export interface CartItemQuantity {
    toStorage: number
    toDelivery: number
}

export interface CartItemLogo extends CartItem, CartItemQuantity {
    discriminator: LogoType,
    baseLogo: BaseLogo
    value: string | number | undefined
    logoVariant: LogoVariant | undefined
    logoId: number
    isNumeric: boolean
    storageQuantities: QuantityRow[]
    deliveryQuantities: QuantityRow[]
}

export interface CartItemDesign extends CartItem {
    design: Design
    instances: CartItemDesignInstance[]
}

export interface CartItemDesignInstance extends CartItemQuantity {
    label: string
    size?: DesignSize
    logos: CartItemDesignInstanceLogo[]
}

export interface CartItemDesignInstanceLogo {
    value: string | number
    logoVariant: LogoVariant
    locationIdentifier: string
    logoId: number
    deliveryType: DeliveryVariant
}

export interface CartSummaryRequest { products: CartSummartRequestProduct[] }
export interface CartSummartRequestProduct {
    productNo: string
    toStorage: number
    toDelivery: number,
}

export interface CartSummary {
    prices: CartSummaryPrice[]
    totalQuantity: number
    totalPrice: number
    totalUnitPrice: number
}

export interface CartSummaryPrice {
    productNo: string
    productName: string
    productType: string
    relatedTo: string
    toStorage: number
    toDelivery: number
    price: number
    unitPrice: number
}