import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { ColumnField } from '../../../components/GenericTable/SubComponents/Column'

// @ts-ignore
export const logoColumnsConfig: ColumnField[] = [
  {
    value: 'thumbnail',
    canSortBy: false,
    displayValue: 'Image',
    weight: 1.5
  },
  {
    value: 'product.sku',
    canSortBy: true,
    displayValue: 'NO.',
    weight: 1
  },
  {
    value: nameOf<BaseLogo>(x => x.title),
    canSortBy: true,
    displayValue: 'Title',
    weight: 1.5
  },
  {
    value: 'colors',
    canSortBy: false,
    displayValue: 'Colors',
    weight: 0
  },
  {
    value: nameOf<BaseLogo>(x => x.customer),
    canSortBy: true,
    displayValue: 'Customer',
    weight: 1
  },
  {
    value: 'priceSort',
    canSortBy: true,
    displayValue: 'Logo Price',
    weight: 1
  },
  {
    value: 'product.storagecount',
    canSortBy: true,
    displayValue: 'In Storage',
    weight: 1
  },
  {
    value: nameOf<BaseLogo>(x => x.createdAtUtc),
    canSortBy: true,
    displayValue: 'Creation Date',
    weight: 1
  },
  {
    value: 'stateFilter',
    canSortBy: true,
    displayValue: 'Status',
    weight: 1
  },
  {

    value: 'actions',
    canSortBy: false,
    displayValue: 'Action',
    weight: 1.2
  }
]