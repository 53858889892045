import { ChangeEvent, JSX, useState } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from '../../../components/Dialogs/dialog-base'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import LoadingElement from '../../../components/loading-element/loading-element'
import { Organization } from '../../../../core/models/entities/Organization'
import { useOrganizationRepository } from '../../../../data/repositories/OrganizationRepository'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { CustomInput } from '../../../components/Input/CustomInput'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { CustomAutoComplete } from '../../../components/Input/CustomAutoComplete'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { extensionsToMimeTypesString } from '../../logo-editor/componenets/ExtensionsToMimeTypesString'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useNavigate } from 'react-router'
import { Role } from '../../../../core/models/entities/Role'
import { Profile } from '../../../../core/models/entities/Profile'
import { useErrorToast } from '../../../components/Toast/Toast'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { SimpleLogoPanel } from '../../design-editor/design-editor-tool/DesignEditorTool.component'
import { useLogoRepository } from '../../../../data/repositories/LogoRepository'
import { Color, Font, LogoOptions, TransferMethod } from '../../../../core/models/entities/LogoOptions'

interface Props {
  component: JSX.Element
  open: () => void
}

export function useCreateLogoDialog({
  onContinue,
  onCancel,
  options
}: { onContinue: () => Promise<void>, onCancel?: () => void, options: LogoOptions }): Props {

  const { profile, organizationId } = useProfileContext()
  const { useAllCustomers } = useOrganizationRepository()
  const { data: customers, isError, isLoading, isSuccess } = useAllCustomers({ page: { index: 0, size: 0 } } as PageQuery)

  const navigate = useNavigate()

  const { state, dataTransition } = useComponentStateMachine()
  dataTransition(isLoading, isSuccess, isError)

  const [loading, setLoading] = useState<boolean>(false)

  const dialog = useDialog()

  const { handleCreateLogo, handleCreatePlaceholderNameLogo, handleCreatePlaceholderNumberLogo, handleCreateTextLogo } = useLogoActionHandler()

  function onSubmit() {
    setLoading(true);
    onContinue().finally(() => {
      setLoading(false)
      dialog.closeDialog()
    })
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  function handleUpload(file: File, title: string, orgId?: number) {
    const id = orgId ?? organizationId
    // We should never get to this point in the system if there isn't an available orgId.
    if (!id) return
    setLoading(true)
    handleCreateLogo(file, id, title)
      .then(newLogo => {
        onSubmit();
        navigate(`/logo/create?logo=${newLogo.id}&organization=${id}`)
        dialog.closeDialog()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleCreate(type: string, text?: string, color?: Color, font?: Font, size?: number, transferMethod?: TransferMethod) {
    const orgId = organizationId ?? 0
    const getCreateFunction = () => {
      switch (type) {
        case 'text':
          return () => handleCreateTextLogo(text!, color!.id, size!, font!.key, orgId)
        case 'number':
          return () => handleCreatePlaceholderNumberLogo(color!.id, size!, font!.key, transferMethod!.typeAndKey, orgId)
        case 'name':
          return () => handleCreatePlaceholderNameLogo(color!.id, size!, font!.key, orgId)
      }
    }

    const create = getCreateFunction()
    if (create) {
      setLoading(true)
      create()
        .then((logo) => {
          onSubmit();
          dialog.closeDialog()
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return {
    component: state === ComponentState.Loading
      ? <DialogFrame dialogOption={dialog}>
        <div className='h-80'>
          <LoadingElement transparent />
        </div>
      </DialogFrame>
      : <DialogFrame dialogOption={dialog}>
        {loading && <LoadingElement transparent />}
        <CreateLogoDialogComponent
          dialog={dialog}
          options={options}
          onCreate={handleCreate}
          onUpload={handleUpload}
          onCancel={handleCancel}
          onSubmit={onSubmit}
          customers={customers?.results!}
          profile={profile!} />
      </DialogFrame>,
    open: dialog.openDialog
  }
}

interface CreateLogoDialogComponentProps {
  dialog: Dialog_VM
  onCancel?: () => void
  onSubmit?: () => void
  onCreate?: (type: string, text?: string, color?: Color, font?: Font, size?: number, transferMethod?: TransferMethod) => void,
  onUpload: (file: File, title: string, orgId?: number) => void
  profile: Profile
  customers: Organization[]
  options: LogoOptions
}

export function CreateLogoDialogComponent(
  {
    onCreate,
    onUpload,
    customers,
    profile,
    options
  }: CreateLogoDialogComponentProps) {
  const userRole = profile?.userOrganizationInformations?.role;
  const orgId = profile?.userOrganizationInformations?.organizationId;
  const { generateNumberLogoPreview, generateTextLogoPreview } = useLogoRepository()
  const [state, setState] = useState('upload')
  const [transferMethod, setTransferMethod] = useState<TransferMethod>()
  const [customer, setCustomer] = useState<Organization | null>(userRole === Role.EndUser && customers?.length! > 0 ? customers![0] : null)
  const [title, setTitle] = useState<string | undefined>();
  const [isDragging, setIsDragging] = useState(false)

  const isValid = title !== undefined && title.length > 0 && title.length <= 30

  function handleDragCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true)
    } else if (e.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  function handleDropCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)

    if (!isValid) {
      useErrorToast("Please fill out the form first")
      return
    }

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onUpload(e.dataTransfer.files[0], title!, customer?.id)
    }
  }

  function handleSelectCatalog(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    event.preventDefault()

    if (!isValid) {
      useErrorToast("Please fill out the form first")
      return
    }

    const files = event.target.files

    if (files && files.length > 0) {
      const file = files[0]
      onUpload(file, title, customer?.id)
    }
  }

  const { extensions } = useLogoOptionsContext()

  const inputRef = React.useRef<HTMLInputElement>(null)

  function onUploadClick() {
    inputRef.current?.click()
  }

  return <div className={'flex flex-col space-y-4 p-4'}>
    <p className={'text-left text-base font-bold'}>Create New Logo</p>
    <div className={'flex flex-col justify-center space-y-4'}>
      {state === 'upload' && <InputWrapper>
        <InputLabel>Title</InputLabel>
        <CustomInput
          value={title}
          onChange={x => setTitle(x.target.value)}
          maxLength={30}
        />
      </InputWrapper>}
      <InputWrapper>
        <InputLabel>Customer</InputLabel>
        <CustomAutoComplete<Organization>
          options={customers}
          getOptionKey={(org) => org.id}
          displayOption={(org) => org.name}
          key={customer?.id}
          onChange={(org) => setCustomer(org)}
          value={customer}
          placeholder='search for customer'
          disabled={userRole === Role.EndUser}
        />
      </InputWrapper>
      {onCreate && <SimpleLogoPanel
        state={state}
        setState={setState}
        setTransferMethod={setTransferMethod}
        transferMethod={transferMethod}
        options={options}
        onSubmit={onCreate}
        getTextPreview={(value, color, height, font) => generateTextLogoPreview(value, color.id, height, font.key, orgId)}
        getNumberPreview={(color, height, font) => generateNumberLogoPreview(color.id, height, font.key, orgId)}
        renderLogoUpload={() =>
          <div>
            <InputWrapper>
              <InputLabel>Upload Logo</InputLabel>
              <PrimaryButton width={'full'} onClick={onUploadClick} disabled={!isValid}>Upload new File</PrimaryButton>
              <input ref={inputRef} className={'hidden'} type='file' id='input-file-upload'
                onChange={handleSelectCatalog}
                accept={extensionsToMimeTypesString(extensions)} />
            </InputWrapper>

            <div className='w-full flex justify-center items-center bg-gray-200'
              onDragEnter={handleDragCatalog}
              onDragLeave={handleDragCatalog}
              onDragOver={handleDragCatalog}
              onDrop={handleDropCatalog}
            >
              <div className={'space-y-3 flex flex-col h-20 mt-10'}>
                <FontAwesomeIcon icon={faFileArrowDown} size={'2x'} className={'mx-auto '} />
                <p className={'italic font-light text-xs'}>Drop image here</p>
              </div>
            </div>
          </div>}
      />}
    </div>
  </div>
}
