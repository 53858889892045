import { useParams } from 'react-router-dom'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { ErrorView } from '../../components/error-view/ErrorView'
import { useCustomerFormUpdateViewModel } from './vmHooks/useCustomerFormUpdateViewModel'
import { CustomerUpdateForm } from './component/customer-update-form.component'

export function CustomerUpdateScreen() {
  const params = useParams()
  const id = params?.customerId
  return id ? <OrganizationUpdateScreen organizationId={+id} />
    : <ErrorView />
}

export function VendorUpdateScreen() {
  const params = useParams()
  const id = params?.vendorId
  return id ? <OrganizationUpdateScreen organizationId={+id} isVendor={true} />
    : <ErrorView />
}

interface OrganizationUpdateScreenProps {
  organizationId: number
  // This flag is used to split the organization update experience in customer and vendor facing ones.
  // TODO Instead of this, it would make sense to generalize the screen to use with any organization model agnostically.
  //  Currently, the organization.isVendor fleg might be used instead.
  isVendor?: boolean
}

function OrganizationUpdateScreen({ organizationId, isVendor }: OrganizationUpdateScreenProps) {
  const { state, dataTransition } = useComponentStateMachine()
  const { manufacturerRepository, organizationRepository, logoRepository } = useRepositoriesContext()
  const { data: logoOptions, isLoading: isLogoOptionsLoading, isError: isLogoOptionsError, isSuccess: isLogoOptionsSuccess } = logoRepository.useOptions();
  const {
    data,
    isError: isManufacturersError,
    isLoading: isManufacturersLoading,
    isSuccess: isManufacturersSuccess
  } = manufacturerRepository.useManufacturers()

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isSuccess: isOrganizationSuccess,
    isError: isOrganizationError
  } = organizationRepository.useCustomer(organizationId)

  const vm = useCustomerFormUpdateViewModel(data!, organization!, logoOptions!)

  const isLoading = isManufacturersLoading || isOrganizationLoading || isLogoOptionsLoading
  const isSuccess = isOrganizationSuccess && isManufacturersSuccess && isLogoOptionsSuccess
  const isError = isOrganizationError || isManufacturersError || isLogoOptionsError

  dataTransition(isLoading, isSuccess, isError)
  return state === ComponentState.Succeed
    ? <CustomerUpdateForm {...vm} isVendor={isVendor} logoOptions={logoOptions!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}