import React, { HTMLAttributes, KeyboardEventHandler, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface Props {
  max?: number
  min?: number
  initialValue?: number
  onValueChange: (v: number) => void
  disabled?: boolean
  disableSanitize?: boolean
  className?: HTMLAttributes<string> | string
}

export function IncrementNumberInput({
  min,
  max,
  onValueChange,
  initialValue = 0,
  disableSanitize,
  disabled,
  className
}: Props) {
  const [value, setValue] = useState<number>(initialValue)
  useEffect(() => {
    onValueChange(value)
  }, [value])

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue)
    }
  }, [initialValue])

  function handleAddOne() {
    setValue((x) => sanitizeValue(x + 1, min, max))
  }

  function handleSubtractOne() {
    setValue((x) => sanitizeValue(x - 1, min, max))
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target
    const value = Number(target.value)

    setValue(sanitizeValue(value, min, max))
  }

  return (
    <div
      className={`w-32 flex border-2 rounded h-7 ${!disabled ? 'focus-within:!border-gray-600 hover:border-gray-400' : ''
        } ${className}`}>
      <button
        onClick={(e) => {
          e.stopPropagation()
          handleSubtractOne()
        }}
        className={`w-4 bg-gray-100 rounded-bl rounded-tl ${disabled ? 'text-gray-300' : ''}`}
        disabled={disabled}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className={'flex-1 contents'}>
        <input
          onKeyDown={disableSanitize ? undefined : sanitizeInput}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation()
            handleInputChange(e)
          }}
          className={`w-full bg-gray-100 text-center outline-0 ${disabled ? 'text-gray-300' : ''}`}
          value={value}
          type={'number'}
          disabled={disabled}
        />
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation()
          handleAddOne()
        }}
        className={`w-4 bg-gray-100 rounded-br rounded-tr ${disabled ? 'text-gray-300' : ''}`}
        disabled={disabled}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  )
}

// This function can be used anywhere in a onKeyDown handler to disallow typing scientific notation characters in the field.
export function sanitizeInput(e: React.KeyboardEvent<HTMLInputElement>) {
  return ['e', 'E', '+', '-', ',', '.'].includes(e.key) && e.preventDefault()
}

export function sanitizeValue(value: number, min?: number, max?: number) {
  value = Number.parseFloat(value.toFixed(2))

  if (min !== undefined) {
    if (value < min) {
      return min
    }
  }
  if (max !== undefined) {
    if (value > max) {
      return max
    }
  }

  return value
}
