import React, { createContext, useContext } from 'react'

import { OrganizationRepository } from '../data/repositories/OrganizationRepository'
import { DesignRepository } from '../data/repositories/DesignRepository'
import { LogoRepository } from '../data/repositories/LogoRepository'
import { ManufacturerRepository } from '../data/repositories/ManufacturerRepository'
import { OrderRepository } from '../data/repositories/OrderRepository'
import { StyleRepository } from '../data/repositories/StyleRepository'
import { UserRepository } from '../data/repositories/UserRepository'
import { CountryRepository } from '../data/repositories/CountryRepository'

interface Repositories {
  styleRepository: StyleRepository
  designRepository: DesignRepository
  logoRepository: LogoRepository
  organizationRepository: OrganizationRepository
  orderRepository: OrderRepository
  manufacturerRepository: ManufacturerRepository
  userRepository: UserRepository
  countryRepository: CountryRepository
}

const RepositoriesContext = createContext<Repositories | undefined>(undefined)

interface RepositoriesProviderProps {
  repositories: Repositories
  children: React.ReactNode
}

function RepositoriesProvider({
  repositories,
  children
}: RepositoriesProviderProps): React.ReactElement {
  return (
    <RepositoriesContext.Provider
      value={{
        styleRepository: repositories.styleRepository,
        designRepository: repositories.designRepository,
        logoRepository: repositories.logoRepository,
        organizationRepository: repositories.organizationRepository,
        orderRepository: repositories.orderRepository,
        manufacturerRepository: repositories.manufacturerRepository,
        userRepository: repositories.userRepository,
        countryRepository: repositories.countryRepository
      }}>
      {children}
    </RepositoriesContext.Provider>
  )
}

function useRepositoriesContext(): Repositories {
  const context = useContext(RepositoriesContext)
  if (context === undefined) {
    throw new Error('useRepositoriesContext must be used within a RepositoriesProvider')
  }
  return context
}

export { RepositoriesProvider, useRepositoriesContext }
