import { Design, DesignLogo } from '../../../../core/models/entities/Design'
import { useEffect, useState } from 'react'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { copy } from '../../../../utils/functions/ObjectUtil'
import { loadImageAsync } from '../../../../utils/functions/ImageUtil'
import {
  areTwoFiguresColliding,
  getCenterOfRotatedLogo,
  getOriginalTopLeft,
  getRotatedVertices,
  rotate
} from '../../../../utils/functions/MathUtil'
import { MaskColor } from '../../../../core/models/entities/MaskColor'
import { compareColors } from '../../../../utils/functions/ColorUtil'
import { IdLookUps } from '../../../../utils/models/idLookUp'


export interface ViewWithDimensions {
  view: StyleView
  height: number
  width: number
  maskImage: HTMLImageElement,
  maskCanvasCtx: CanvasRenderingContext2D
}

export interface LogoWithLogoPlacementStatus {
  designLogo: DesignLogo
  position: LogoPlacementPosition
  coverage: LogoCoverage
}

export enum LogoPlacementPosition {
  VALID,
  INVALID,
  OVERLAPPING,
  UNKNOWN
}

export interface LogoCoverage {
  base: number | 0
  collar: number | 0
  pocket: number | 0
  sewing: number | 0
  button: number | 0
  zipper: number | 0
  background: number | 0
  crease: number | 0
  reflection: number | 0
}
export const getEmptyLogoCoverage = (): LogoCoverage => ({
  base: 0,
  collar: 0,
  pocket: 0,
  sewing: 0,
  button: 0,
  zipper: 0,
  background: 0,
  crease: 0,
  reflection: 0
})

export interface DesignStyleEditor_VM {
  currentView?: ViewWithDimensions,
  views: ViewWithDimensions[]
  logosOfView: LogoWithLogoPlacementStatus[]
  handleSelectView: (view: ViewWithDimensions) => void
  isLoading: boolean
  handleLogoChange: (logo: LogoWithLogoPlacementStatus) => void
  currentLogo?: DesignLogo
  onSelectLogo: (logo: DesignLogo['inDesignId']) => void,
  locked: boolean
  onInvalid: (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => void,
  invalidPlacement: IdLookUps<LogoPlacementPosition>
}

export function useDesignStyleEditorViewModel(
  design: Design,
  onLogoChange: (logo: DesignLogo) => void,
  onViewSelect: (view: StyleView) => void,
  designLogos: DesignLogo[],
  onInvalid: (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => void,
  onSelectLogo: (logo: DesignLogo['inDesignId']) => void,
  locked: boolean,
  reprepareLogos: number,
  invalidPlacement: IdLookUps<LogoPlacementPosition>,
  currentLogo?: DesignLogo
): DesignStyleEditor_VM {
  const [currentView, setCurrentView] = useState<ViewWithDimensions>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [viewsWithDimensions, setViewsWithDimension] = useState<ViewWithDimensions[]>([])
  const [logoPlacementStatus, setLogoPlacementStatus] = useState<LogoWithLogoPlacementStatus[]>([])

  useEffect(() => {
    prepareViews().then(vs => {
      setViewsWithDimension(vs)
      handleSelectView(vs[0])

      const logos = prepareLogos(vs)
      setLogoPlacementStatus(logos)
    })
  }, [])

  useEffect(() => {
    if (!viewsWithDimensions) {
      return
    }

    const logos = prepareLogos(viewsWithDimensions)
    setLogoPlacementStatus(logos)
  }, [reprepareLogos])

  function handleSelectView(viewComp: ViewWithDimensions) {
    setCurrentView(viewComp)
    onViewSelect(viewComp!.view)
  }

  async function prepareViews(): Promise<ViewWithDimensions[]> {
    const result: ViewWithDimensions[] = []

    for (const view of design.style.styleViews) {
      const vImage = await loadImageAsync(view.imageUrl)
      const maskImage = await loadImageAsync(view.updatedMaskUrl ?? view.maskUrl)

      const maskCanvas = document.createElement('canvas')
      maskCanvas.width = vImage.width
      maskCanvas.height = vImage.height

      const maskCanvasCtx = maskCanvas.getContext('2d', {
        willReadFrequently: true
      })

      result.push({
        view: view,
        height: vImage.height,
        width: vImage.width,
        maskImage,
        maskCanvasCtx: maskCanvasCtx!
      })
    }
    return result
  }

  function prepareLogos(viewsWithDimensions: ViewWithDimensions[]): LogoWithLogoPlacementStatus[] {
    const preparedLogos: LogoWithLogoPlacementStatus[] = []
    for (const viewComp of viewsWithDimensions) {
      const logosOfView = designLogos.filter(x => x.viewId === viewComp.view.id)
      for (const designLogo of logosOfView) {
        const preparedLogo: LogoWithLogoPlacementStatus = {
          designLogo: designLogo,
          coverage: getLogoMaskCoverage(designLogo.logoX, designLogo.logoY, designLogo.heightMm, designLogo.widthMm, designLogo.angleRads,
            {
              maskCanvasCtx: viewComp.maskCanvasCtx,
              maskImage: viewComp.maskImage,
              width: viewComp.width,
              height: viewComp.height
            }),
          position: LogoPlacementPosition.UNKNOWN
        }
        preparedLogos.push(preparedLogo)
      }
    }
    return preparedLogos
  }


  function handleLogoChange(logoComp: LogoWithLogoPlacementStatus) {

    const logoToChange = copy(logoComp)

    setLogoPlacementStatus(x => {
      const ls = x.filter(l => l.designLogo.inDesignId !== logoToChange.designLogo.inDesignId)
      ls.push(logoToChange)
      return ls
    })
    onLogoChange(logoToChange.designLogo)
  }

  return {
    currentView,
    currentLogo,
    views: viewsWithDimensions,
    logosOfView: logoPlacementStatus.filter(l => l.designLogo.viewId === currentView?.view.id),
    handleSelectView: handleSelectView,
    isLoading,
    handleLogoChange,
    onSelectLogo,
    locked,
    onInvalid,
    invalidPlacement
  }
}

export interface PlacementCalModel {
  id: DesignLogo['inDesignId'],
  x: number,
  y: number,
  heightPx: number,
  widthPx: number,
  rotationRadian: number
  coverage: LogoCoverage
}

export function getLogoPlacementPosition(
  logo: PlacementCalModel,
  allLogos: PlacementCalModel[]
): LogoPlacementPosition {

  if (logo.heightPx == 0 || logo.widthPx == 0) {
    return LogoPlacementPosition.UNKNOWN
  }
  if (isLogoOverlappingAnotherLogo(logo, allLogos)) {
    return LogoPlacementPosition.OVERLAPPING
  }

  if (!isLogoPlacementOnMaskValid(logo.coverage)) {
    return LogoPlacementPosition.INVALID
  }

  return LogoPlacementPosition.VALID
}

function normalizeColor(rgb: number[]): MaskColor | undefined {
  const maskColor = MaskColor.parse(rgb)
  if (maskColor !== undefined) {
    return maskColor
  } else {
    for (const color of MaskColor.maskColors) {
      if (compareColors(MaskColor.toRGBColor(color), rgb)) {
        return color
      }
    }
  }
}


// Takes into account that logos can be rotated. They are therefor treated as polygons instead of rectangles
function isLogoOverlappingAnotherLogo(logo: PlacementCalModel, allLogos: PlacementCalModel[]): boolean {


  // If logo is the only logo on the view there is no need to check for overlap
  if (allLogos.length < 2) {
    return false
  }

  // Find corners/verteces of logo
  const logoVertices = getRotatedVertices(
    logo.x,
    logo.y,
    logo.widthPx,
    logo.heightPx,
    logo.rotationRadian
  )

  for (const l of allLogos) {
    // Logo will always overlap with itself, so continue loop
    if (l.id === logo.id) {
      continue
    }


    const lVertices = getRotatedVertices(l.x, l.y, l.widthPx, l.heightPx, l.rotationRadian)
    if (areTwoFiguresColliding(logoVertices, lVertices)) {
      return true
    }
  }

  return false
}

function isLogoPlacementOnMaskValid(coverage: LogoCoverage): boolean {
  const validPercentage = 0.98

  return (
    coverage.base >= validPercentage ||
    coverage.pocket >= validPercentage ||
    coverage.collar >= validPercentage
  )
}

export function getLogoMaskCoverage(
  x: number, y: number, height: number, width: number, radians: number,
  maskValues: {
    maskCanvasCtx: CanvasRenderingContext2D
    maskImage: HTMLImageElement
    width: number
    height: number
  }
): LogoCoverage {
  const ctx = maskValues.maskCanvasCtx

  ctx.drawImage(maskValues.maskImage, 0, 0, maskValues.width, maskValues.height)
  const canvasColorsMap = new Map<string, number>()

  // At this point the shape is already rotated and we do not need to consider further rotations.
  // For the same reason, we can consider the shape's center and origin to be correct regardless of rotation.
  // NOTE: Skipping this step may become an issue when rotating by incremental radians rather than by 90 degrees.
  const offsetRadians = 0 //radians // This rotation occurs after any rotation the logo may already have.
  const { x: cx, y: cy } = getCenterOfRotatedLogo(x, y, width, height, offsetRadians)
  const { x: ox, y: oy } = getOriginalTopLeft(x, y, width, height, offsetRadians)

  for (let ix = ox; ix < ox + width; ix++) {
    for (let iy = oy; iy < oy + height; iy++) {
      const r = rotate(cx, cy, ix, iy, radians)
      const rgba = ctx.getImageData(r.x, r.y, 1, 1).data
      const rgb = `${rgba[0]},${rgba[1]},${rgba[2]}`
      canvasColorsMap.set(rgb, 1 + (canvasColorsMap.get(rgb) || 0))
    }
  }

  const maskColorsMap = new Map<MaskColor, number>()
  let totalColors = 0

  canvasColorsMap.forEach((value, key) => {
    const rgb = key.split(',').map((v) => Number(v))

    const maskColor = normalizeColor(rgb)
    totalColors += value

    if (maskColor !== undefined) {
      maskColorsMap.set(maskColor, value + (maskColorsMap.get(maskColor) || 0))
    }
  })
  return {
    base: (maskColorsMap.get(MaskColor.Base) ?? 0) / totalColors,
    pocket: (maskColorsMap.get(MaskColor.Pocket) ?? 0) / totalColors,
    collar: (maskColorsMap.get(MaskColor.Collar) ?? 0) / totalColors,
    zipper: (maskColorsMap.get(MaskColor.Zipper) ?? 0) / totalColors,
    sewing: (maskColorsMap.get(MaskColor.Sewing) ?? 0) / totalColors,
    button: (maskColorsMap.get(MaskColor.Button) ?? 0) / totalColors,
    background: (maskColorsMap.get(MaskColor.Background) ?? 0) / totalColors,
    crease: (maskColorsMap.get(MaskColor.Crease) ?? 0) / totalColors,
    reflection: (maskColorsMap.get(MaskColor.Reflector) ?? 0) / totalColors
  }
}