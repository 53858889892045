import { JSX, useState } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import { PrimaryButton } from '../Buttons/PrimaryButton'
import { DesignLogo } from '../../../core/models/entities/Design'
import { getColorsElements, ProductDetailsLogo } from '../../screens/order-create/subcomponents/ProductDetailsLogo'
import { useOrderActionHandler } from '../../screens/order-create/hooks/useOrderActionsHandler'
import { useCartContext } from '../../../contexts/CartContext'
import { BaseLogo } from '../../../core/models/entities/BaseLogo'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import { CartItemLogo } from '../../screens/order-create/models/CartItem'
import { LogoQuantitySelector } from '../../screens/order-create/subcomponents/LogoQuantitySelector'

interface useAddLogoToOrderDialogProps {
  component: JSX.Element
  open: () => void
}

export function useAddLogoToOrderDialog(
  designLogo?: DesignLogo,
  baseLogo?: BaseLogo,
  onAccept?: () => void,
  onCancel?: () => void
): useAddLogoToOrderDialogProps {
  const dialog = useDialog()

  function handleAccept() {
    if (onAccept) onAccept()
    dialog.closeDialog()
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <AddLogoToDesignOrderDialog dialogVm={dialog}
      baseLogo={baseLogo ?? designLogo?.logo}
      onAccept={handleAccept}
      onCancel={handleCancel} />,
    open: dialog.openDialog
  }
}

interface AddLogoToDesignOrderDialogProps {
  dialogVm: Dialog_VM
  baseLogo?: BaseLogo
  onAccept: () => void
  onCancel?: () => void
}

export function AddLogoToDesignOrderDialog({ baseLogo, onAccept, onCancel, dialogVm }: AddLogoToDesignOrderDialogProps) {
  const { items, removeItem } = useCartContext()
  const { handleLogoSetQuantities } = useOrderActionHandler()
  const { logos } = useCartContext()
  
  if (!baseLogo) return null

  const selectedOrderLine = items.find((i) => i.id === baseLogo.id.toString())
  const logo = logos.find(x => x.id === '' + baseLogo.id)
  const isNumeric = baseLogo.logoType === 'NumberLogo'

  // Keep the old state to ensure logo quantities can be reverted to original state.
  // Never update this state. It should not be a state.
  const [logosToStorage, _setLogosToStorage] = useState<number>(logo?.toStorage ?? 0);
  const [logosToDelivery, _setLogosToDelivery] = useState<number>(logo?.toDelivery ?? 0);

  // HACK:
  // Because the LogoQuantitySelector component handles quantity state internally,
  //  we either have to let it do that or refactor the component and likely the architecture around other usages.
  // Luckily, this state is stored synchronously in localStorage,
  //  so instead of refactoring now, we can just revert the quantity state afterwards if the user cancels.
  // It would be prudent to refactor this mess instead of parsing the code and trying to update it.
  // This is the "cancel" option.
  const revert = () => {
    if (selectedOrderLine != null)
      handleLogoSetQuantities(baseLogo, logosToDelivery, logosToStorage)
    if (baseLogo != null)
      removeItem(baseLogo.id.toString())
    onCancel?.call(null)
  }

  // HACK:
  // In case the user confirms the choices, we don't need to do anything, 
  //  because the LogoQuantitySelector component has already handled the state update internally.
  // This is the "confirm" option.
  const doNotRevert = () => {
    onAccept?.call(null)
  }

  const number = baseLogo.id
  const size = baseLogo.transferMethod.size
  const storage = baseLogo.inStorage ?? 0
  const colors = getColorsElements(baseLogo);

  const alt = `Logo - ${baseLogo.title}`
  const src = baseLogo.displayImage.lowResUrl

  return (
    <DialogFrame dialogOption={dialogVm}>
      <div className={'flex flex-col space-y-4 p-4'}>
        <p className={'text-left text-base font-bold'}>Add to Order ({baseLogo.title})</p>
        <div className="flex flex-row">

          <div className={'flex flex-col mr-8'}>

            <div className="flex flex-row justify-between min-h-[100px]">
              <div className="flex-1 min-w-[150px]">
                <p className='uppercase font-bold'>No.</p>
                <p>{number}</p>
              </div>
              <div className="flex-1 min-w-[150px]">
                <p className='uppercase font-bold'>Image</p>
                <img className={'w-[70px] h-[45px] object-contain'} src={src} alt={alt} />
              </div>
            </div>

            <div className="flex flex-row justify-between min-h-[100px]">
              <div className="flex-1 min-w-[150px]">
                <p className='uppercase font-bold'>Colors</p>
                {colors}
              </div>
              <div className="flex-1 min-w-[150px]">
                <p className='uppercase font-bold'>Size</p>
                <p>{size}</p>
              </div>
            </div>

            <div className={'flex flex-row justify-between min-h-[100px]'}>
              <div className="flex-1 min-w-[150px]">
                <p className='uppercase font-bold'>In Storage</p>
                <p>{storage}</p>
              </div>
            </div>

          </div>

          <div className={`grid gap-4 ${isNumeric ? 'grid-cols-2' : ''}`}>
            <LogoQuantitySelector
              logo={baseLogo}
              orderLine={selectedOrderLine as CartItemLogo}
            />
          </div>

        </div>

        <div className={'flex flex-row justify-between '}>
          <SecondaryButton width="md" className={'bg-[#F8FAFD]'} onClick={revert}>
            <span className={'flex-1'}>Cancel</span>
          </SecondaryButton>
          <PrimaryButton width="md" className={'bg-[#F8FAFD]'} onClick={doNotRevert}>
            <span className={'flex-1'}>Confirm</span>
          </PrimaryButton>
        </div>
      </div>
    </DialogFrame>
  )
}
