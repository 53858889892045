import { useState } from 'react'

import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import ArrowSeperator from './ArrowSeperator'
import ColorSwitch from './ColorSwitch'
import ColorBlockWithToolTip from './ColorBlockWithToolTip'
import { getUnique } from '../functions/getUnique'
import ColorAutoComplete from './ColorAutoComplete'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { Color, LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { ColorChange } from '../../logo-list/hooks/useLogoActionsHandler'

function LogoColorChanger({ logo, onUpdateColor, options, disabled }: {
  onUpdateColor: (colors: ColorChange[]) => void,
  options: LogoOptions,
  logo: BaseLogo,
  disabled: boolean
}) {
  const colorList = logo.transferMethod.type == 'BRODERI' ? options.colorsEmbroidery : options.colors
  const uniqueColors = getUnique(colorList, sc => sc.id)
  const logoColorToColorDir: IdLookUps<Color> = {}

  for (const color of uniqueColors) {
    logoColorToColorDir[color.id] = color
  }

  const [currentSelectedColor, setCurrentSelectedColor] = useState<Color>()
  const [logoId, setLogoId] = useState<BaseLogo['id']>(logo.id)
  const [colorSwitchTo, setColorSwitchTo] = useState<IdLookUps<Color>>({})
  if (logoId !== logo.id) {
    setLogoId(logo.id)
    setColorSwitchTo({})
    setCurrentSelectedColor(undefined)
  }

  var colors = logo.colors
  const originalColor = colors.map(x => logoColorToColorDir[x]).filter((c) => !!c)

  function colorSwitchClickHandler(color: Color) {
    if (disabled) {
      return
    }
    setCurrentSelectedColor(color)
  }

  function changColorHandler(color: Color) {
    if (disabled || !currentSelectedColor) {
      return
    }

    setColorSwitchTo(dir => ({
      ...dir,
      [currentSelectedColor.id]: color
    }))
  }

  function updateColorHandler() {
    if (disabled) {
      return
    }
    const newColors: ColorChange[] = colors.map(x => ({ from: x, to: colorSwitchTo[x]?.id }))
    onUpdateColor(newColors)
    setColorSwitchTo({})
  }

  function validate(): boolean {
    return Object.keys(colorSwitchTo).length > 0;
  }

  const isValid: boolean = validate()

  const standardColors = uniqueColors.filter(x => x.isStandard)
  const specialColors = uniqueColors.filter(x => x.isMetallic || x.isNeon)

  const colorSwitches = originalColor.map(c => <ColorSwitch onClick={colorSwitchClickHandler}
    isSelected={currentSelectedColor?.id === c.id} key={c.id}
    baseColor={c} switchToColor={colorSwitchTo[c.id]} />)

  const standardColorBlocks = standardColors.map(sc => <ColorBlockWithToolTip onClick={changColorHandler} key={sc.id}
    color={sc} />)
  const specialColorBlocks = specialColors.map(mc => <ColorBlockWithToolTip onClick={changColorHandler} key={mc.id}
    color={mc} />)

  const autoCompleteValue = !currentSelectedColor
    ? undefined
    : colorSwitchTo[currentSelectedColor.id] ?? currentSelectedColor.id

  return <div>
    <div className={'flex'}>
      <div className={'flex-1'}>
        <p className={'text-xs text-gray-600'}>Current Colors</p>
        <div className={'flex flex-col h-[112px] w-[300px] text-gray-600 text-xs flex-wrap items-start'}>
          {colorSwitches}
        </div>
      </div>
      <ArrowSeperator />
      <div className={'flex-1 relative flex'}>
        {(disabled || !currentSelectedColor) &&
          <div className={'absolute z-10 h-full w-full bg-loadingTransparent rounded'}></div>}
        <div className={'flex-1 flex text-gray-600 text-xs p-2 relative'}>
          <div className={'flex flex-1 flex-col'}>
            <div className={'flex-1'}>
              <p className={'capitalize'}>standard colors</p>
              <div className={'w-[272px] flex flex-wrap'}>
                {standardColorBlocks}
              </div>
            </div>
            <div>
              <ColorAutoComplete value={autoCompleteValue?.id} disabled={disabled} colors={uniqueColors}
                onColorPicked={changColorHandler} />
            </div>
          </div>
          <div className={'flex flex-col'}>
            <div className={'flex flex-col flex-1'}>
              <p className={'capitalize'}>special colors</p>
              <div className={'flex flex-wrap'}>
                {specialColorBlocks}
              </div>
            </div>
            <div className={'flex'}>
              <PrimaryButton disabled={disabled || !isValid} width={'sm'} onClick={updateColorHandler}>Update
                Color
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
}


export default LogoColorChanger