import { DesignSize } from "../../../presentation/screens/order-create/models/CartItem"
import { ApprovalGroup } from "./Approval"
import { BaseLogo } from "./BaseLogo"
import { Design } from "./Design"
import { Organization } from "./Organization"
import { Price } from "./Price"

export interface Order {
  id: number
  subTotal: number
  total: number
  vat: number
  createdAtUtc: Date
  updatedAtUtc: Date
  placedAtUtc: Date
  orderNumber: string
  price: number
  status: OrderStatus
  lines: OrderLine[]
  heating: Heating[]
  logoPrices: LogoPrice[]
  additionalFees: AdditionalFee[]
  deliveryType: DeliveryType
  packagingType: Packaging
  orderComment: string
  originOfDelivery: string
  requisitionNumber: string
  organization: Organization
  approval: ApprovalGroup | null
  orderDeliveryAddress?: OrderDeliveryAddress
}

export type OrderLineDeliveryType = 'Storage' | 'Delivery' | 'Application'

export interface LogoPrice {
  adjustedPrice: number
  logoId: number
}

export interface Heating {
  product: HeatingProduct
  quantity: number
}

export interface HeatingProduct {
  additionalFee?: AdditionalFee
  isGenerated: boolean
  name: string
  prices: Price[]
  sku: string
}

export interface OrderLine {
  id: number
  displayNo: string
  type: string
  adjustedPrice: number
  adjustedUnitPrice: number
  quantity: number
  title: string
  displayImage?: string
  toStorage: number
  toDelivery: number
  product: OrderLineProduct
  deliveryType: OrderLineDeliveryType
  additionalFees: AdditionalFee[]
}

export enum OrderStatus {
  Unknown = "Unknown",
  Draft = "Draft",
  Pending = "Pending",
  Approved = "Approved",
  InProduction = "In Production",
  ProductionComplete = "Production Completed",
  OrderShipped = "Order Shipped",
  Cancelled = "Cancelled",
  Rejected = "Rejected"

  //NAV statuses
  // AwaitsRelease = "Awaits Release",
  // AcceptedAwaits = "Accepted Awaits",
  // ProductionRegistered = "Production Registered",
  // ProductionPlanned = "Production Planned",
  // ProductionFilmPrinting = "Production Film Printing",
  // ProductionFrameStarted = "Production Frame Started",
  // ProductionFrameFinished = "Production Frame Finished",
  // ProductionStarted = "Production Started",
  // ProductionProduced = "Production Produced",
  // ProductionCutted = "Production Cutted",
  // ProductionFinished = "Production Finished",
  // ProductionCancelled = "Production Cancelled",
  // Shipped = "Shipped",
}

export interface OrderLineProduct {
  productSku?: string
  productMul?: string
  productNo: string
  productType: string
  productName: string
}

export interface OrderLineDesign extends OrderLine, OrderLineQuantity {
  design: Design
  instances: OrderLineDesignInstance[]
}

export interface OrderLineLogo extends OrderLine, OrderLineQuantity {
  baseLogo: BaseLogo
}

export interface OrderLineDesignInstance {
  label: string
  quantity: number
  size?: DesignSize
  logos: OrderLineDesignInstanceLogo[]
}

export interface OrderLineDesignInstanceLogo {
  value: string | number
  isNumeric: boolean
  logoId: number
}

export interface AdditionalFee {
  itemNo: string
  name: string
  quantity: number
  price: number
  prices: Price[]
  unitPrice: number
}

export interface OrderDeliveryPreferences {
  company: string
  zipCode: string
  email: string
  contactPerson: string
  city: string
  contactPhone: string
  address: string
  country: string
  emailtrackAndTrace: string

  latestDeliveryDate: Date
  deliveryBefore12PMwithGLS: boolean

  deliveryType: DeliveryType
  packaging: Packaging

  orderComment: string
  originOfDelivery: string
  requisitionNumber: string
}

export enum DeliveryType {
  Standard = "Standard",
  Express24 = "Express24",
  Express48 = "Express48",
  Express72 = "Express72"
}

export enum Packaging {
  NoPackaging = "NoPackaging",
  StandardBagPackaging = "StandardBagPackaging",
  SpecificPackaging = "SpecificPackaging"
}

export interface OrderLineQuantity {
  toStorage: number
  toDelivery: number
}

export interface OrderDeliveryAddress {
  id: number
  orderId: number
  name: string
  address: string
  address2: string
  zip: string
  city: string
  country: string
  contact: string
  email: string
  phone: string
}