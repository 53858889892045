import { Organization } from './Organization'
import { Price } from './Price'
import { Style } from './Style'
import { DesignStatus } from './enums/DesignStatus'
import { BaseLogo, GetOverallApprovalStatus, ImageRef, ProductRef } from './BaseLogo'
import { StyleView } from './StyleView'
import { EditAction } from '../../../data/models/responses/DesignDto'
import { ApprovalGroup, ApprovalStatus } from './Approval'

export function getDesignState(design: Design): DesignState {
  if (design.status === DesignStatus.Draft) {
    return DesignState.Draft
  }

  if (design.status === DesignStatus.Approval) {
    return DesignState.Approval
  }

  if (design.status === DesignStatus.Done) {
    return DesignState.Done
  }

  if (design.status === DesignStatus.Rejected) {
    return DesignState.Rejected
  }

  switch (GetOverallApprovalStatus(design.approval!)) {
    case ApprovalStatus.Rejected:
      return DesignState.Rejected
    case ApprovalStatus.Approved:
      return DesignState.Approval
    case ApprovalStatus.Pending:
      return DesignState.Pending
  }
}

export enum DesignState {
  Draft = 'Draft',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Approval = 'Approval',
  Done = 'Done'
}

export interface Design {
  id: number
  updateAtUtc: Date
  createdAtUtc: Date
  createdBy: string
  status: DesignStatus
  designLogos: DesignLogo[]
  approval: ApprovalGroup | null
  displayImage?: ImageRef
  customerId: number
  customer: Organization
  styleId: number
  style: Style
  title: string
  product: MultiProductRef | null
  heating: HeatingProductRef | null
}

export interface MultiProductRef {
  mul: string
  products: ProductRef[]
  totalPrices: Price[]
}

export interface HeatingProductRef {
  name: string
  sku: string
  prices: Price[] | undefined
}

export function statusToString(status: DesignStatus): string {
  switch (status) {
    case DesignStatus.Unknown:
      return 'Unknown'
    case DesignStatus.Draft:
      return 'Draft'
    case DesignStatus.Rejected:
      return 'Rejected'
    case DesignStatus.Approval:
      return 'Validating'
    case DesignStatus.Done:
      return 'Done'
    default:
      console.warn('unknown status', status)
      return 'Unknown'
  }
}

export interface DesignLogo {
  id: number
  inDesignId: string
  logoX: number
  logoY: number
  viewId: StyleView['id']
  logo: BaseLogo
  logoId: number
  action?: EditAction | null
  createdAtUtc?: Date
  updateAtUtc?: Date
  createdBy?: string
  angleRads: number
  heightMm: number
  widthMm: number
  designerWidthMm: number
  viewName: string
}
