import { TextLogoCreatePanel_VM } from '../components/panel/text-logo-create-panel.component'
import { useLogoContext } from '../contexts/logo-options.context'
import { useDesignEditorStateContext } from '../contexts/design-editor-state-provider.context'
import { useEffect, useState } from 'react'
import { Color, Font } from '../../../../core/models/entities/LogoOptions'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { useDesignContext } from '../contexts/design.context'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useLogoRepository } from '../../../../data/repositories/LogoRepository'

export function useTextLogoCreatePanelViewModel(): TextLogoCreatePanel_VM {
  const { options } = useLogoContext()
  const { handleCreateTextLogo, isLoading } = useLogoActionHandler()
  const { goToState } = useDesignEditorStateContext()
  const [color, setColor] = useState<Color>()
  const [font, setFont] = useState<Font>()
  const [heightMM, setHeightMM] = useState<number>(options.fontSizes.at(0)!)
  const [textValue, setTextValue] = useState<string>('')
  const { design, addToDesign } = useDesignContext()
  const { generateTextLogoPreview } = useLogoRepository()

  function handleCancel() {
    // todo: confirmation dialog when leaving
    goToState(DesignEditorStates.Normal)
  }

  function handleAddLogo() {
    handleCreateTextLogo(textValue, color?.id!, heightMM, font?.key!, design.customerId).then(logo => {
      addToDesign(logo)
      goToState(DesignEditorStates.Normal)
    });
  }

  function isValid(): boolean {
    return !!color && !!font && !!heightMM && !!textValue && textValue.length > 0 && isLoading === false
  }

  function getPreview(value: string, color: Color, heightMM: number, font: Font): Promise<string> {
    return generateTextLogoPreview(value, color.id, heightMM, font.key, design.customerId)
  }

  return {
    font: font,
    onFontChange: setFont,
    heightMM: heightMM,
    onHeightMMChange: setHeightMM,
    color: color,
    onColorChange: setColor,
    textValue: textValue,
    onTextValueChange: setTextValue,
    options: options,
    onCancel: handleCancel,
    onAddLogo: handleAddLogo,
    canAdd: isValid(),
    getPreview: getPreview,
    isLoading: isLoading
  }
}