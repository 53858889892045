import { ChangeEvent, JSX, useState } from 'react'
import { DialogFrame, useDialog } from '../../../components/Dialogs/dialog-base'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import LoadingElement from '../../../components/loading-element/loading-element'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { extensionsToMimeTypesString } from '../../logo-editor/componenets/ExtensionsToMimeTypesString'
import { toast } from 'react-toastify'

interface DialogHandle {
  component: JSX.Element
  open: () => void
}

interface UploadDialogProps {
  orgId: number,
  typeString?: string,
  onUpload: (orgId: number, file: File) => Promise<void>
  onContinue?: () => Promise<void>
  onCancel?: () => void
}

export function useUploadDialog({ orgId, typeString = 'designs', onUpload, onContinue, onCancel }: UploadDialogProps): DialogHandle {

  const [loading, setLoading] = useState<boolean>(false)

  const dialog = useDialog()

  function onSubmit() {
    setLoading(true);
    if (onContinue)
      onContinue().finally(() => {
        setLoading(false)
        dialog.closeDialog()
      })
    else setLoading(false)
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  function handleUpload(file: File) {
    setLoading(true)
    onUpload(orgId, file)
      .then(() => {
        toast.success('Updated ' + typeString)
        onSubmit();
        dialog.closeDialog()
      })
      .catch(() => {
        toast.warn('Unable to update ' + typeString)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    component: <DialogFrame dialogOption={dialog}>
      {loading && <LoadingElement transparent />}
      <UploadDialog onUpload={handleUpload} />
    </DialogFrame>,
    open: dialog.openDialog
  }
}

interface UploadDialogComponentProps {
  extensions?: string[]
  onUpload: (file: File) => void
}

export function UploadDialog({ onUpload, extensions = ['xlsx'] }: UploadDialogComponentProps) {

  const [isDragging, setIsDragging] = useState(false)

  function handleDragCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true)
    } else if (e.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  function handleDropCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onUpload(e.dataTransfer.files[0])
    }
  }

  function handleSelectCatalog(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    event.preventDefault()

    const files = event.target.files

    if (files && files.length > 0) {
      const file = files[0]
      onUpload(file)
    }
  }

  const inputRef = React.useRef<HTMLInputElement>(null)

  function onUploadClick() {
    inputRef.current?.click()
  }

  return <div className={'flex flex-col space-y-4 p-4'}>
    <p className={'text-left text-base font-bold'}>Update designs</p>
    <div className={'flex flex-col justify-center space-y-4'}>
      <div>
        <InputWrapper>
          <InputLabel>Upload spreadsheet (*.xlsx)</InputLabel>
          <PrimaryButton width={'full'} onClick={onUploadClick}>Upload</PrimaryButton>
          <input ref={inputRef} className={'hidden'} type='file' id='input-file-upload'
            onChange={handleSelectCatalog}
            accept={extensionsToMimeTypesString(extensions)} />

        </InputWrapper>

        <div className='w-full flex justify-center items-center bg-gray-200'
          onDragEnter={handleDragCatalog}
          onDragLeave={handleDragCatalog}
          onDragOver={handleDragCatalog}
          onDrop={handleDropCatalog}
        >
          <div className={'space-y-3 flex flex-col h-20 mt-10'}>
            <FontAwesomeIcon icon={faFileArrowDown} size={'2x'} className={'mx-auto '} />
            <p className={'italic font-light text-xs'}>Drop spreadsheet here</p>
          </div>
        </div>
      </div>
    </div>
  </div>
}
