import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { UpdateStyleDto } from '../../core/models/dtos/UpdateStyleDto'
import { Style } from '../../core/models/entities/Style'
import { Resource } from '../../core/models/wrappers/Resource'
import { useApiClient } from '../clients/ApiClient'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { mapToStyles } from '../models/mappers/mapStyle'
import { PagedResponse } from '../models/responses/pagedResponse'
import { mapToUpdateStyleBody } from '../models/mapToUpdateStyleBody'
import { copy } from '../../utils/functions/ObjectUtil'

export interface StyleRepository {
  useAllStyles: (query: PageQuery) => Resource<PagedResponse<Style>>
  useStyle: (id: number) => Resource<Style>
  getStyles: (query: PageQuery) => Promise<Style[]>
  getOriginalMask: (styleId: number, styleViewId: number) => Promise<string>
  updateStyle: (dto: UpdateStyleDto) => Promise<Style>
  updateMask: (styleId: number, styleViewId: number, mask: Blob) => Promise<void>
  uploadCatalog: (manufacturerId: string, catalog: File) => Promise<void>
  getCreateMaskQueueCount: () => Promise<number>
  useAllStyleAsInfinityQuery: (query: PageQuery) => UseInfiniteQueryResult<PagedResponse<Style>>
}

export function useStyleRepository(): StyleRepository {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  async function invalidateQueries() {
    await queryClient.refetchQueries({ queryKey: ['styles'], type: 'all', refetchPage: () => true })
  }

  function updateMask(styleId: number, styleViewId: number, mask: Blob): Promise<void> {
    return apiClient.updateMask(styleId, styleViewId, mask)
  }

  function useAllStyles(query: PageQuery): Resource<PagedResponse<Style>> {
    const request = () => apiClient.getAllStyles(query).then(data => ({ ...data, results: data.results.map(mapToStyles) }));
    const config = { staleTime: 600000 }
    return useQuery(['styles', toQueryParam(query)], request, config)
  }

  function useAllStyleAsInfinityQuery(query: PageQuery): UseInfiniteQueryResult<PagedResponse<Style>> {
    return useInfiniteQuery({
      queryKey: ['styles', toQueryParam(query)],
      queryFn: ({ pageParam }) => {
        const copyQuery = copy(query);
        copyQuery.page.index = pageParam ?? query.page.index

        return apiClient.getAllStyles(copyQuery)
          .then(page => ({ ...page, results: page.results.map(mapToStyles) }))
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.pageIndex === lastPage.pageCount) {
          return undefined
        }
        return lastPage.pageIndex + 1
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.pageIndex === 1) {
          return undefined
        }

        return firstPage.pageIndex - 1
      },
    })
  }

  async function getStyles(query: PageQuery): Promise<Style[]> {
    const styles = await apiClient.getAllStyles(query)
    return styles.results.map(mapToStyles)
  }

  function useStyle(styleId: number): Resource<Style> {
    return useQuery(['styles', styleId], () => apiClient.getStyleById(styleId).then((data) => mapToStyles(data)))
  }

  async function updateStyle(dto: UpdateStyleDto): Promise<Style> {
    const result = await apiClient.updateStyle(dto.styleId, mapToUpdateStyleBody(dto));
    await invalidateQueries()
    return mapToStyles(result)
  }

  async function getOriginalMask(styleId: number, styleViewId: number): Promise<string> {
    return apiClient.getOriginalMask(styleId, styleViewId).then((data) => {
      return data.url
    })
  }

  async function uploadCatalog(manufacturerId: string, catalog: File): Promise<void> {
    const result = apiClient.uploadCatalog(manufacturerId, catalog);
    await invalidateQueries()
    return result
  }

  async function getCreateMaskQueueCount(): Promise<number> {
    return (await apiClient.getCreateMaskQueueCount()).count
  }

  return {
    useAllStyles,
    useStyle,
    getStyles,
    getOriginalMask,
    updateStyle,
    updateMask,
    uploadCatalog,
    getCreateMaskQueueCount,
    useAllStyleAsInfinityQuery
  }
}
