import React from 'react'
import { ButtonRoot } from './SecondaryButton'
import { sanitizeValue } from '../Input/IncrementNumberInput'

interface StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  min?: number
  max?: number
  value: number
  disabled?: boolean
  onValueChange: (value: number) => void
}

export const Stepper = React.forwardRef<HTMLDivElement, StepperProps>(
  ({ min = 0, max = Number.MAX_VALUE, value = 0, onValueChange, className, disabled = false }, ref) => {

    const sanitizedValue = sanitizeValue(value)

    function handleInputChange(newValue: number) {
      const paddedNewValues = sanitizeValue(newValue, min, max)
      onValueChange(paddedNewValues)
    }

    return (
      <div className={`bg-gray-100 rounded-md border flex align-middle w-32 h-7 ${className}`}>
        <ButtonRoot
          onClick={(e) => {
            e.stopPropagation()
            handleInputChange(value - 1)
          }}
          className="disabled:bg-gray-200 content-center w-[40px] text-center text-xs font-semibold h-full"
          disabled={disabled || value === min}>
          -
        </ButtonRoot>
        <div className="w-[1px] bg-gray-200" />
        <label className="flex-1 text-center content-center text-xs px-2 font-semibold min-w-8 w-8">{sanitizedValue}</label>
        <div className="w-[1px] bg-gray-200" />
        <ButtonRoot
          onClick={(e) => {
            e.stopPropagation()
            handleInputChange(value + 1)
          }}
          className="content-center w-[40px] text-center text-xs font-semibold h-full"
          disabled={disabled || value === max}>
          +
        </ButtonRoot>
      </div>
    )
  }
)
