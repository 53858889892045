import { HTMLAttributes } from 'react';
import { Stepper } from '../../../components/Buttons/Stepper';
import { CustomInput } from '../../../components/Input/CustomInput';
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import { sanitizeInput } from '../../../components/Input/IncrementNumberInput';

export interface LogoQuantitiesColumnProps extends HTMLAttributes<HTMLElement> {
    title: string
    actionLabel?: string
    onAction: () => void
    onUpload?: () => void
}

export function LogoQuantitiesColumn({ title, onAction, onUpload, actionLabel, children }: LogoQuantitiesColumnProps) {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
                <div className={'uppercase inline pr-1'}>{title}</div>
                <div className={'uppercase inline pr-1 mr-20'}>QTY</div>
            </div>
            {children}
            <SecondaryButton className="w-full" onClick={onAction}>{actionLabel || 'Tilføj'}</SecondaryButton>
            <SecondaryButton className="w-full" onClick={onUpload}>UPLOAD EXCEL FILE</SecondaryButton>
        </div>
    );
}

export interface LogoQuantityRowProps {
    label: string,
    onLabelChange: (value: string) => void
    quantity: number
    onQuantityChange: (quantity: number) => void
    isNumeric?: Boolean
}

export function LogoQuantityRow({ label, onLabelChange, quantity, onQuantityChange, isNumeric }: LogoQuantityRowProps) {
    const onNumberLabelChange = (value: string) => {
        if (!isNumeric) return onLabelChange(value)
        else {
            const number = parseInt(value)
            if (!isNaN(number))
                onLabelChange(number.toString())
        }
    }

    return (
        <div className="flex flex-row gap-2 items-center justify-between">
            <CustomInput onKeyDown={isNumeric ? sanitizeInput : undefined} type={isNumeric ? 'number' : 'text'} className="h-[32px]" value={label} onChange={(e) => onNumberLabelChange(e.target.value)} />
            <Stepper className="h-8" onValueChange={onQuantityChange} min={0} value={quantity} />
        </div>
    )
}