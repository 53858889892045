import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './AuthConfig'
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom'
import { StyleListScreen } from './presentation/screens/style-list/style-list.screen'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MaskEditorScreen } from './presentation/screens/style-mask-editor/MaskEditorScreen'
import { PrivateView, PublicView } from './App'
import { MsalProvider } from '@azure/msal-react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DesignListScreen } from './presentation/screens/design-list/design-list.screen'
import { CreateDesignScreen } from './presentation/screens/design-create/create-design.screen'
import { OrderListScreen } from './presentation/screens/order-list/order-list.screen'
import { LogoListScreen } from './presentation/screens/logo-list/logo-list.screen'
import StyleCatalogUploadScreen from './presentation/screens/style-catalog-upload/StyleCatalogUploadScreen'
//@ts-ignore
import './i18n.ts'
import AuthRoute from './routing/AuthRoute'
import { Role } from './core/models/entities/Role'
import StyleEditScreen from './presentation/screens/style-edit/StyleEditScreen'
import LogoEditorScreen from './presentation/screens/logo-editor/logo-editor.screen'
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { OrderCreateScreen } from './presentation/screens/order-create/OrderCreateScreen'
import { OrderPlaced } from './presentation/screens/order-placed/OrderPlaced'
import { CustomerListScreen } from './presentation/screens/customer-list/customer-list.screen'
import { CustomerCreateScreen } from './presentation/screens/customer-create/customer-create.screen'
import { CustomerUpdateScreen } from './presentation/screens/customer-update/customer-update.screen'
import reportWebVitals from './reportWebVitals'
import { MyProfileScreen } from './presentation/screens/my-profile/my-profile.screen'
import { DesignEditor2Screen } from './presentation/screens/design-editor2/design-editor2.screen'
import { Outlet } from 'react-router'
import {
  ExternalApprovalDesignScreen
} from './presentation/screens/public/external-approval-design/external-approval-design.screen'
import {
  ExternalApprovalLogoScreen
} from './presentation/screens/public/external-approval-logo/external-approval-logo.screen'
import { VendorListScreen } from './presentation/screens/vendor-list/vendor-list.screen'
import { VendorDetailsScreen } from './presentation/screens/vendor-details/vendor-details.screen'
import { CustomerDetailsScreen } from './presentation/screens/customer-details/customer-details.screen'
import { OrderConfirmationScreen } from './presentation/screens/order-confirmation/OrderConfirmationScreen'
import { DesignChangeStyleScreen } from './presentation/screens/design-update-style/design-change-style.screen'
import { OrderDetailsScreen } from './presentation/screens/order-details/OrderDetailsScreen'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2500
    },
  },
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <div>error</div>,
    hasErrorBoundary: true,
    children: [
      {
        path: '',
        element: <PrivateView />,
        errorElement: <div>error 2</div>,
        hasErrorBoundary: true,
        children: [
          {
            path: '',
            element: <HomeRedirector />,
          },
          {
            path: 'design',
            element: <DesignListScreen />
          },
          {
            path: 'style',
            element: <AuthRoute requiredRole={Role.Admin} element={<StyleListScreen />} />
          },
          {
            path: 'style/:styleId/edit',
            element: <AuthRoute requiredRole={Role.Admin} element={<StyleEditScreen />} />
          },
          {
            path: 'style/catalog',
            element: <AuthRoute requiredRole={Role.Admin} element={<StyleCatalogUploadScreen />} />
          },
          {
            path: 'order',
            element: <OrderListScreen />
          },
          {
            path: 'order/confirm',
            element: <OrderConfirmationScreen />
          },
          {
            path: 'order/end',
            element: <OrderPlaced />
          },
          {
            path: 'order/details',
            element: <OrderDetailsScreen />
          },
          {
            path: 'order/place',
            element: <OrderCreateScreen />
          },
          {
            path: 'design/create',
            element: <CreateDesignScreen />
          },
          {
            path: 'design/:designId/changestyle',
            element: <DesignChangeStyleScreen />
          },
          {
            path: 'design/:designId/editor',
            element: <DesignEditor2Screen />
          },
          {
            path: 'account/me',
            element: <MyProfileScreen />
          },
          {
            path: 'style/:styleId/annotate',
            element: <AuthRoute requiredRole={Role.Admin} element={<MaskEditorScreen />} />
          },
          {
            path: 'customer',
            element: <CustomerListScreen />
          },
          {
            path: 'vendor',
            element: <VendorListScreen />
          },
          {
            path: 'logo',
            element: <LogoListScreen />
          },
          {
            path: 'logo/create',
            element: <LogoEditorScreen />
          },
          {
            path: 'customer/:customerId/edit',
            element: <AuthRoute requiredRole={Role.Vendor} element={<CustomerUpdateScreen />} />
          },
          {
            path: 'customer/:customerId',
            element: <AuthRoute requiredRole={Role.Vendor} element={<CustomerDetailsScreen />} />
          },
          {
            path: 'vendor/:vendorId',
            element: <AuthRoute requiredRole={Role.Admin} element={<VendorDetailsScreen />} />
          },
          {
            path: 'customer/add',
            element: <AuthRoute requiredRole={Role.Vendor} element={<CustomerCreateScreen />} />
          }
        ]
      },
      {
        path: 'public',
        element: <PublicView />,
        children: [
          {
            path: 'design/approval/:designId',
            element: <ExternalApprovalDesignScreen />
          },
          {
            path: 'logo/approval/:logoId',
            element: <ExternalApprovalLogoScreen />
          }
        ]
      }
    ]
  }
])

function HomeRedirector() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/design');
  })
  return <></>
}

function AppWrapper(): JSX.Element {


  return (
    <RouterProvider router={router} />
  )
}



const rootElement = document.getElementById("root");

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    fontSize: 10
  },
  palette: {
    primary: {
      main: '#596675'
    }
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
  },
});

const root = ReactDOM.createRoot(rootElement as HTMLElement)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppWrapper />
            <ToastContainer />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
)

reportWebVitals()
  // .then(() => console.log('No error'))
  // .catch(() => console.log('Error'))