import { createContext, useContext, useEffect, useState } from "react";
import { CartItem, CartItemDesign, CartItemDesignInstance, CartItemLogo, CartSummary } from "../presentation/screens/order-create/models/CartItem";
import { getDesignProductPrice } from "../core/models/entities/BaseLogo";
import { NotImplemented } from "../presentation/shared/functions/not-implemented.function";
import { useRepositoriesContext } from "./RepositoriesContext";
import { useProfileContext } from "./ProfileContext";
import { parseJSONWithFallback } from "../utils/functions/JsonParse";
import { Order } from "../core/models/entities/Order";

export interface OrderBasket {
  items: CartItem[]
  logos: CartItemLogo[]
  designs: CartItemDesign[]
  cartSummary?: CartSummary,
  addLogoItem: (item: CartItemLogo) => Promise<CartItemLogo>
  addLogoItems: (items: CartItemLogo[]) => Promise<CartItemLogo[]>
  addDesignItem: (item: CartItemDesign) => Promise<CartItemDesign>
  addDesignItems: (items: CartItemDesign[]) => Promise<CartItemDesign[]>
  addItems: (items: CartItem[]) => Promise<CartItem[]>
  updateItem: (item: CartItem) => Promise<CartItem>
  removeItem: (id: string) => void
  clearItems: () => void
  getItemsQuantity: () => number
  getItemsPrice: (includeVat?: boolean) => number
  getLogoAdjustedPrice(logoId: number): number
  activeOrder?: Order
  setActiveOrder: (order?: Order) => void
}

const CartContext = createContext<OrderBasket | undefined>(undefined)

interface CartProviderProps {
  children: React.ReactNode
}

const CartProvider = ({ children }: CartProviderProps): React.ReactElement => {
  const { b2CId, organizationId } = useProfileContext()

  const productsKey = `${b2CId}_products`
  const activeOrderKey = `${b2CId}_activeOrder`

  const [items, setItems] = useState<CartItem[]>(localStorage.getItem(productsKey)
    ? parseJSONWithFallback(localStorage.getItem(productsKey) ?? JSON.stringify([]), [])
    : [])
  const [activeOrder, setActiveOrder] = useState<Order | undefined>(localStorage.getItem(activeOrderKey)
    ? parseJSONWithFallback(localStorage.getItem(activeOrderKey) ?? JSON.stringify([]), undefined)
    : undefined)

  const [cartSummary, setCartSummary] = useState<CartSummary>()
  const customerId = getCustomerOfItems();

  const { orderRepository } = useRepositoriesContext()
  const { data: orderPrices, refetch: refetchOrderPrices } = orderRepository.useOrderPrices(customerId, items ?? [])
  const adjustedLogoPrices = orderPrices ?? []

  useEffect(() => {
    refetchOrderPrices()
    if (organizationId) updateCartSummary(organizationId, items)
  }, [items])

  function getCustomerOfItems(): number | undefined {
    if (items?.length === 0) {
      return undefined
    }

    const item = items[0]
    switch (item.type) {
      case "Logo": return (item as CartItemLogo).baseLogo.customer.id
      case "Design": return (item as CartItemDesign).design.customer.id
    }
  }

  const updateCartSummary = async (orgId: number, items: CartItem[]) => {
    const summary = await orderRepository.summarizeOrder(orgId, items)
    setCartSummary(summary)
  }

  async function addLogoToCart(item: CartItemLogo): Promise<CartItemLogo> {
    return new Promise<CartItemLogo>((resolve, reject) => {

      if (!shouldAllowPluralCustomerProductsInCart(item)) {
        reject(new Error("You are not allowed to operate products of multiple customers at the same time."))
        return;
      }

      setItems(state => {
        const isItemInCart = state.find((cartItem) => cartItem.id === item.id) as CartItemLogo;
        if (isItemInCart) {
          const newState = state.map((cartItem: CartItem) =>
            cartItem.id === item.id
              ? { ...item }
              : cartItem
          )
          return newState
        } else {
          return [...state, { ...item }]
        }
      })

      resolve(item)
    })
  };

  async function addLogosToCart(items: CartItemLogo[]): Promise<CartItemLogo[]> {

    return new Promise<CartItemLogo[]>((resolve, reject) => {

      items.map(item => {
        if (!shouldAllowPluralCustomerProductsInCart(item)) {
          reject(new Error("You are not allowed to operate products of multiple customers at the same time."))
          return;
        }
      })

      setItems(state => {
        const newState = items.reduce((acc, item) => {
          const isItemInCart = acc.find((cartItem) => cartItem.id === item.id) as CartItemLogo;
          if (isItemInCart) {
            return acc.map((cartItem: CartItem) =>
              cartItem.id === item.id
                ? { ...item }
                : cartItem
            );
          } else {
            return [...acc, { ...item }];
          }
        }, state);

        return newState;
      });

      resolve(items)
    })
  };

  async function addDesignToCart(item: CartItemDesign): Promise<CartItemDesign> {

    return new Promise<CartItemDesign>((resolve, reject) => {

      if (!shouldAllowPluralCustomerProductsInCart(item)) {
        reject(new Error("You are not allowed to operate products of multiple customers at the same time."))
        return;
      }

      setItems(state => {
        const isItemInCart = state.find((cartItem) => cartItem.id === item.id) as CartItemDesign;
        if (isItemInCart) {
          const newState = state.map((cartItem: CartItem) =>
            cartItem.id === item.id
              ? { ...item }
              : cartItem
          )
          return newState
        } else {
          return [...state, { ...item }]
        }
      })

      resolve(item)
    })
  };

  async function addDesignsToCart(items: CartItemDesign[]): Promise<CartItemDesign[]> {

    return new Promise<CartItemDesign[]>((resolve, reject) => {

      items.map(item => {
        if (!shouldAllowPluralCustomerProductsInCart(item)) {
          reject(new Error("You are not allowed to operate products of multiple customers at the same time."))
          return;
        }
      })

      setItems(state => {
        const newState = items.reduce((acc, item) => {
          const isItemInCart = acc.find((cartItem) => cartItem.id === item.id) as CartItemDesign;
          if (isItemInCart) {
            return acc.map((cartItem: CartItem) =>
              cartItem.id === item.id
                ? { ...item }
                : cartItem
            );
          } else {
            return [...acc, { ...item }];
          }
        }, state);

        return newState;
      });

      resolve(items)
    })
  };

  async function addItemsToCart(items: CartItem[]): Promise<CartItem[]> {

    return new Promise<CartItem[]>((resolve, reject) => {

      items.map(item => {
        if (!shouldAllowPluralCustomerProductsInCart(item)) {
          reject(new Error("You are not allowed to operate products of multiple customers at the same time."))
          return;
        }
      })

      setItems(state => {
        const newState = items.reduce((acc, item) => {
          const isItemInCart = acc.find((cartItem) => cartItem.id === item.id) as CartItem;
          if (isItemInCart) {
            return acc.map((cartItem: CartItem) =>
              cartItem.id === item.id
                ? { ...item }
                : cartItem
            );
          } else {
            return [...acc, { ...item }];
          }
        }, state);

        return newState;
      });

      resolve(items)
    })
  };

  async function updateInCart(item: CartItem): Promise<CartItem> {

    return new Promise<CartItem>((resolve, reject) => {

      if (!shouldAllowPluralCustomerProductsInCart(item)) {
        reject(new Error("You are not allowed to operate products of multiple customers at the same time."))
        return;
      }

      const isItemInCart = items.find((cartItem) => cartItem.id === item.id);

      if (isItemInCart) {
        setItems(
          items.map((cartItem: CartItem) =>
            cartItem.id === item.id
              ? { ...item }
              : cartItem
          )
        );
        return item;
      } else {
        switch (item.type) {
          case "Logo": {
            addLogoToCart(item as CartItemLogo)
              .then(x => resolve(x))
              .catch(x => reject(x))
            break;
          }
          case "Design": {
            addDesignToCart(item as CartItemDesign)
              .then(x => resolve(x))
              .catch(x => reject(x))
            break;
          }
        }
      }

      resolve(item)
    })
  };

  const removeFromCart = (id: string): void => {
    setItems(state => {
      if (state.length === 1) {
        localStorage.removeItem(productsKey);
        return [];
      } else {
        return items.filter((cartItem) => cartItem.id !== id)
      }
    })
  };

  const clearCart = (): void => {
    setItems([]);
  };

  function getCustomerId(items: CartItem[]): number | undefined {
    const firstLogo = items.find(x => x.type === "Logo") as CartItemLogo
    const firstDesign = items.find(x => x.type === "Design") as CartItemDesign

    if (firstLogo) {
      return firstLogo.baseLogo.customer.parentId
    }

    if (firstDesign) {
      return firstDesign.design.customer.parentId
    }

    return undefined
  }

  function shouldAllowPluralCustomerProductsInCart(item: CartItem): boolean {
    // Disable plural guard for now, as it doesn't align with business logic.
    return true

    const customerId = getCustomerId(items)

    if (customerId === undefined) {
      return true
    }

    switch (item.type) {
      case "Logo":
        return (item as CartItemLogo).baseLogo.customer.parentId === customerId
      case "Design":
        return (item as CartItemDesign).design.customer.parentId === customerId
      default:
        throw NotImplemented("OrderLine type not implemented");
    }
  }

  function getLogoAdjustedPrice(logoId: number): number {
    const prices = adjustedLogoPrices?.find(x => x.logoId === logoId)
    const adjustedPricePerPiece = prices?.adjustedPrice ?? 0
    return adjustedPricePerPiece
  }

  function getOrderLineLogoPrice(orderlineLogo: CartItemLogo): number {
    const adjustedPricePerPiece = getLogoAdjustedPrice(orderlineLogo.logoId)
    const quantity = getLogoQuantity(orderlineLogo)
    const additionalFees = (orderlineLogo.baseLogo.product?.additionalFees ?? []).map(x => x.price).reduce((sum, current) => sum + current, 0)
    return quantity * adjustedPricePerPiece + additionalFees
  }

  function getDesignLogoPrices(orderlineDesign: CartItemDesign): number[] {
    const instanceQuantity = orderlineDesign.instances.reduce((sum, current) => sum + current.toDelivery + current.toStorage, 0) ?? 0
    return orderlineDesign.design.designLogos.map(designLogo => {
      const adjustedPricePerPiece = getLogoAdjustedPrice(designLogo.logoId)
      const additionalFees = (designLogo.logo.product?.additionalFees ?? []).map(x => x.price).reduce((sum, current) => sum + current, 0)
      return (instanceQuantity * adjustedPricePerPiece) + additionalFees
    });
  }

  function getDesignLogoTotalPrice(orderlineDesign: CartItemDesign) {
    let logoPrices = getDesignLogoPrices(orderlineDesign);
    const totalLogoPrice = logoPrices.reduce((sum, current) => sum + current, 0);
    return totalLogoPrice;
  }

  function getOrderLineDesignPrice(orderlineDesign: CartItemDesign): number {
    const totalDesignPrice = getDesignLogoTotalPrice(orderlineDesign)
    const application = getDesignApplicationTotalPrice(orderlineDesign)
    return totalDesignPrice + application
  }

  function getDesignApplicationTotalPrice(orderlineDesign: CartItemDesign): number {

    function isDesignLogoApplication(instance: CartItemDesignInstance): boolean {
      return instance.logos?.find(x => x.deliveryType === "Application") ? true : false
    }

    const quantity = orderlineDesign.instances.reduce((sum, current) => isDesignLogoApplication(current) ? sum + current.toDelivery + current.toStorage : sum, 0)
    const pricePerPiece = getDesignProductPrice(orderlineDesign.design.heating?.prices ?? [], quantity)
    const totalDesignPrice = quantity * (pricePerPiece?.adjustedPrice ?? 0)
    return totalDesignPrice
  }

  const getCartPrice = (includeVat: boolean = false): number => {
    const itemPrice = items.map(getOrderLinePrice)

    const subtotal = itemPrice.reduce((sum, current) => sum + current, 0)

    return includeVat ? subtotal * 1.25 : subtotal
  }

  function getOrderLinePrice(orderLine: CartItem) {
    switch (orderLine.type) {
      case "Logo":
        return getOrderLineLogoPrice(orderLine as CartItemLogo)
      case "Design":
        return getOrderLineDesignPrice(orderLine as CartItemDesign)
      default:
        throw NotImplemented("OrderLine type not implemented");
    }
  }

  const getCartQuantity = (): number => {
    const orderlineTotal = items.map(getOrderLineQuantity)
    const total = orderlineTotal.reduce((sum, current) => sum + current, 0)

    return total
  }

  function getOrderLineQuantity(orderLine: CartItem) {
    switch (orderLine.type) {
      case "Logo": {
        return getLogoQuantity(orderLine as CartItemLogo);
      }
      case "Design": {
        return getDesignQuantity(orderLine as CartItemDesign);
      }
    }
  }

  function getLogoQuantity(orderline: CartItemLogo) {
    const quantity = orderline.toDelivery + orderline.toStorage
    return quantity
  }

  function getDesignQuantity(orderlineDesign: CartItemDesign) {
    let logoPrices = orderlineDesign.design.designLogos.map(designLogo => {
      const toDeliveryQuantity = orderlineDesign.instances[designLogo.id]?.toDelivery ?? 0
      const toStorageQuantity = orderlineDesign.instances[designLogo.id]?.toStorage ?? 0
      const totalQuantity = toDeliveryQuantity + toStorageQuantity
      return totalQuantity;
    });
    let totalLogoQuantity = logoPrices.reduce((sum, current) => sum + current, 0);

    const designInstanceQuantity = orderlineDesign.instances.reduce((sum, current) => sum + current.toDelivery, 0);
    return designInstanceQuantity + totalLogoQuantity
  }

  function handleSetActiveOrder(order?: Order) {
    setActiveOrder(order)
  }

  useEffect(() => {
    localStorage.setItem(productsKey, JSON.stringify(items));
  }, [items]);

  useEffect(() => {
    localStorage.setItem(activeOrderKey, JSON.stringify(activeOrder));
  }, [activeOrder]);

  useEffect(() => {
    const cartItems = localStorage.getItem(productsKey);
    if (cartItems) {
      setItems(parseJSONWithFallback(cartItems, []));
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        items: items,
        cartSummary,
        logos: items.filter(x => x.type === "Logo") as CartItemLogo[],
        designs: items.filter(x => x.type === "Design") as CartItemDesign[],
        addLogoItem: addLogoToCart,
        addLogoItems: addLogosToCart,
        addDesignItem: addDesignToCart,
        addDesignItems: addDesignsToCart,
        addItems: addItemsToCart,
        updateItem: updateInCart,
        removeItem: removeFromCart,
        clearItems: clearCart,
        getItemsPrice: getCartPrice,
        getItemsQuantity: getCartQuantity,
        getLogoAdjustedPrice: getLogoAdjustedPrice,
        activeOrder: activeOrder,
        setActiveOrder: handleSetActiveOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCartContext(): OrderBasket {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartProvider')
  }
  return context
}

export { CartProvider, useCartContext } 