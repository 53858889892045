import { CSSProperties, JSX, useState } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from '../../../components/Dialogs/dialog-base'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'
import LoadingElement from '../../../components/loading-element/loading-element'
import { useCartContext } from '../../../../contexts/CartContext'
import { CartItemDesign } from '../../order-create/models/CartItem'
import { PartlyHeatingList } from './partly-heating-list'
import { Design, DesignLogo } from '../../../../core/models/entities/Design'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import { getProductPrice } from '../../../../core/models/entities/BaseLogo'
import { PriceLineAsString } from '../../../components/Logo/LogoPrices'

interface Props {
  component: JSX.Element
  open: () => void
}

export enum HeatingOption {
  all,
  partly,
  none
}

export function useAddHeatingFeeOrderDialog({
  onContinue,
  onCancel
}: { onContinue: (heatingOption: HeatingOption, extraLogos: ExtraLogo[]) => Promise<void>, onCancel?: () => void }): Props {

  const { designs } = useCartContext()
  const [loading, setLoading] = useState(false)
  const [heatingOption, setHeatingOption] = useState<HeatingOption>(HeatingOption.all);

  const [extraLogos, setExtraLogos] = useState<IdLookUps<ExtraLogo>>(designs.flatMap(x => {
    return {
      design: x.design,
      designLogos: x.design.designLogos
    }
  }).reduce((acc: IdLookUps<ExtraLogo>, item) => {
    item.designLogos.forEach(designLogo => {
      acc[designLogo.id] = {
        design: item.design,
        designLogo: designLogo,
        option: undefined,
        quantity: 0
      }
    });
    return acc;
  }, {}));

  function updateExtraLogo(extraLogo: ExtraLogo): void {
    setExtraLogos(state => {
      return {
        ...state,
        [extraLogo.designLogo.id]: extraLogo
      }
    })
  }

  const dialog = useDialog()

  function handleonApproveAndContinue() {
    setLoading(true);
    const logos = Object.values(extraLogos) ?? [];
    onContinue(heatingOption, logos).finally(() => {
      setLoading(false)
      dialog.closeDialog()
    })
  }

  function handleOnAll() {
    setHeatingOption(HeatingOption.all)
  }

  function handleOnPartly() {
    setHeatingOption(HeatingOption.partly)
  }

  function handleOnNone() {
    setHeatingOption(HeatingOption.none)

  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <>
      <AddHeatingFeeOrderDialog
        dialogVm={dialog}
        selectedHeatingOption={heatingOption}
        loading={loading}
        onApproveAndContinue={handleonApproveAndContinue}
        onAll={handleOnAll}
        onPartly={handleOnPartly}
        onNone={handleOnNone}
        onCancel={handleCancel}
        designs={designs}
        extraLogos={Object.values(extraLogos) ?? []}
        setExtraLogos={updateExtraLogo}
      />
    </>,
    open: dialog.openDialog
  }
}

interface AddHeatingFeeOrderDialogProps {
  dialogVm: Dialog_VM
  selectedHeatingOption: HeatingOption
  onApproveAndContinue: () => void
  onAll: () => void
  onPartly: () => void
  onNone: () => void
  onCancel?: () => void
  loading: boolean
  designs: CartItemDesign[]
  extraLogos: ExtraLogo[]
  setExtraLogos: (extraLogo: ExtraLogo) => void
}

export interface ExtraLogo {
  design: Design,
  designLogo: DesignLogo,
  quantity: number
  option: ExtraLogoOption
}

export type ExtraLogoOption = 'toDelivery' | 'toStorage' | undefined

export function AddHeatingFeeOrderDialog(
  {
    onApproveAndContinue,
    onAll,
    onPartly,
    onNone,
    onCancel,
    loading,
    dialogVm,
    selectedHeatingOption,
    extraLogos,
    setExtraLogos,
    designs
  }: AddHeatingFeeOrderDialogProps) {
  const heatingComponent = designs.map(x => {
    if (x.design.heating?.prices === undefined) {
      return <p key={x.id}>Påvarmning {x.design.heating?.name}: Ingen pris tilgængelig</p>
    }
    const price = getProductPrice(x.design.heating!.prices!, 0)!;
    const priceText = PriceLineAsString({ price, excludeQuantity: true });
    return <span key={x.id}>{x.design.heating?.name} {priceText} per pcs.</span>
  });

  const { whiteLabel } = useWhiteLabelContext()

  function getSelectedStyle(selected: HeatingOption, heatingOption: HeatingOption): CSSProperties {
    return heatingOption === selected ? {
      backgroundColor: whiteLabel.colorHex,
      color: "#F6FAF6"
    } : {}
  }

  if (loading) return null

  return <DialogFrame dialogOption={dialogVm}>
    {loading && <LoadingElement transparent />}
    <div className={'flex flex-col space-y-4 p-4'}>
      <p className={'text-left text-base font-bold'}>Heating</p>
      <p className={'text-left'}>Choose if you want all transfers applied.</p>
      <div className={'flex flex-col justify-center space-y-4'}>
        <div className={"font-bold"}>
          {heatingComponent}
        </div>
        <div className="flex flex-row justify-between space-x-10">
          <div className='flex flex-row'>
            <SecondaryButton style={getSelectedStyle(selectedHeatingOption, HeatingOption.all)} className={`rounded-r-none font-bold border-r-0`} onClick={onAll}>All</SecondaryButton>
            <SecondaryButton style={getSelectedStyle(selectedHeatingOption, HeatingOption.partly)} className={`rounded-l-none rounded-r-none font-bold border-x-0 ${getSelectedStyle(selectedHeatingOption, HeatingOption.partly)}`} onClick={onPartly} disabled={extraLogos.length === 0}>Partly</SecondaryButton>
            <SecondaryButton style={getSelectedStyle(selectedHeatingOption, HeatingOption.none)} className={`rounded-l-none font-bold border-l-0 ${getSelectedStyle(selectedHeatingOption, HeatingOption.none)}`} onClick={onNone}>None</SecondaryButton>
          </div>
          <div>
            <PrimaryButton className="font-bold" onClick={onApproveAndContinue}>Approve and Continue</PrimaryButton>
          </div>
        </div>
        {selectedHeatingOption === HeatingOption.partly && <PartlyHeatingList extraLogos={extraLogos} setExtraLogos={setExtraLogos} />}
      </div>
    </div>
  </DialogFrame>
}
