import { useDesignEditorStateContext } from '../contexts/design-editor-state-provider.context'
import { useState } from 'react'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { NumberPlaceholderLogoCreate_VM } from '../components/panel/number-placeholder-logo-create.component'
import { useDesignContext } from '../contexts/design.context'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useLogoRepository } from '../../../../data/repositories/useLogoRepository'
import { NumberPackage } from '../../../../core/models/entities/NumberPackage'

export function useNumberPlaceholderLogoCreateViewModel(numberPackages?: NumberPackage[]): NumberPlaceholderLogoCreate_VM {
  const { handleCreatePlaceholderNumberLogo, isLoading } = useLogoActionHandler()
  const { goToState } = useDesignEditorStateContext()
  const [numberPackage, setNumberPackage] = useState<NumberPackage>()
  const { design, addToDesign } = useDesignContext()
  const { generateNumberLogoPreview } = useLogoRepository()

  function handleCancel() {
    // todo: confirmation dialog when leaving
    goToState(DesignEditorStates.Normal)
  }

  function handleAddLogo() {
    handleCreatePlaceholderNumberLogo(numberPackage?.serial!, design.customerId).then(logo => {
      addToDesign(logo)
      goToState(DesignEditorStates.Normal)
    });
  }

  function isValid(): boolean {
    return !!numberPackage && !isLoading
  }

  function getPreview(numberPackage: NumberPackage): Promise<string> {
    return generateNumberLogoPreview(numberPackage.serial, design.customerId)
  }

  return {
    numberPackage: numberPackage,
    onNumberPackage: setNumberPackage,
    numberPackages: numberPackages ?? [],
    onCancel: handleCancel,
    onAddLogo: handleAddLogo,
    canAdd: isValid(),
    getPreview: getPreview,
    isLoading: isLoading
  }
}