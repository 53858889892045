import { BaseLogo, ProductPrice } from '../../../core/models/entities/BaseLogo'
import { Design } from '../../../core/models/entities/Design'
import { Organization } from '../../../core/models/entities/Organization'
import { CartItemLogo } from '../../screens/order-create/models/CartItem'

export const getLogoItemQuantity = (item: CartItemLogo) =>
  (item?.toDelivery ?? 0) + (item?.toStorage ?? 0)

export const getPriceByQuantity = (prices: ProductPrice[], quantity: number) => {
  const eligible = prices.filter((price) => price.minQuantity <= quantity) ?? []
  return eligible[eligible.length - 1] ?? 0
}

function PricesShort({ prices, skipnLines = 0, nLines = 4 }: { prices: ProductPrice[] | undefined, skipnLines?: number, nLines?: number }) {
  if (!prices || !prices.length) return <>-</>
  prices?.sort((a, b) => a.minQuantity - b.minQuantity)
  return (<div className={'flex flex-col'}>
    {prices.slice(skipnLines, nLines).map((r, i) =>
      <SimplePriceLine key={[r.minQuantity, i].toString()} price={r.adjustedPrice} quantity={r.minQuantity} currency={r.currency} />)}
  </div>)
}

export function SimplePriceLine({ price, quantity, currency }: { price: number, quantity: string | number, currency?: string }) {
  if (!price) return <></>
  currency = currency || 'DKK'

  return <p className="text-gray-400">{currency} {price.toFixed(2)} {quantity ? `(${quantity})` : ''}</p>
}

export function PriceLine({ price, excludeQuantity }: { price?: ProductPrice, excludeQuantity?: boolean }) {
  return <p>{PriceLineAsString({ price, excludeQuantity })}</p>
}

export function PriceTotalLine({ price, quantity }: { price?: ProductPrice, quantity: number }): JSX.Element {
  if (!price) {
    return <></>
  }
  return <>{`${price.currency === '' || !price.currency ? 'DKK' : price.currency} ${(price.adjustedPrice * quantity).toFixed(2)}`}</>
}

export function PriceLineAsString({ price, excludeQuantity }: { price?: ProductPrice, excludeQuantity?: boolean }) {
  if (!price) {
    return ""
  }
  const quantity = excludeQuantity ? "" : " (" + (price.minQuantity === 0 ? 1 : price.minQuantity) + ")"
  return `${price.currency === '' || !price.currency ? 'DKK' : price.currency} ${price.adjustedPrice.toFixed(2)}${quantity}`
}

export function PurePriceLine({ currency, price, minQuantity, excludeQuantity }: { currency?: string, price?: number, minQuantity?: number, excludeQuantity?: boolean }) {
  return <p>{PurePriceLineAsString({ currency, price, minQuantity, excludeQuantity })}</p>
}

export function PurePriceLineAsString({ currency, price, minQuantity, excludeQuantity }: { currency?: string, price?: number, minQuantity?: number, excludeQuantity?: boolean }): string {
  const quantity = excludeQuantity ? "" : "(" + minQuantity + ")"
  return `${currency === '' || !currency ? 'DKK' : currency} ${(price ?? 0).toFixed(2)} ${quantity}`
}

export function LogoPrices({ logo, nLines = 4 }: { logo: BaseLogo, nLines?: number }) {
  const prices = logo?.product?.prices
  return <>
    <PricesShort prices={prices} nLines={nLines} />
    <CustomerSummary customer={logo.customer} />
  </>
}

export function DesignPrices({ design, nLines = 4 }: { design: Design, nLines?: number }) {
  const prices = design.product?.totalPrices;
  return <>
    <PricesShort prices={prices} nLines={nLines} />
    <CustomerSummary customer={design.customer} />
  </>
}

const CustomerSummary = ({ customer }: { customer: Organization }) => <em>
  {customer.name} ({customer.navNumber})
</em>