import { getTypeText } from '../../../../core/models/entities/BaseLogo'
import { OrderLine, OrderLineDesign, OrderLineLogo } from '../../../../core/models/entities/Order'

const getPriceString = (price?: number, currency?: string) =>
    price ? `${currency || 'DKK'} ${price.toFixed(2)}` : ''

export interface OrderLineHideColumns {
    displayNo?: boolean
    image?: boolean
    itemNumber?: boolean
    title?: boolean
    type?: boolean
    unitPrice?: boolean
    quantity?: boolean
    price?: boolean
    subTotal?: boolean
}

export const OrderLineHeaders = ({ hide }: { hide?: OrderLineHideColumns }) => {
    return (
        <tr>
            {!hide?.displayNo && <th className='w-1/12 text-left'>No.</th>}
            {!hide?.image && <th className='w-1/12 text-left'>Image</th>}
            {!hide?.itemNumber && <th className='w-1/12 text-left'>Product ID</th>}
            {!hide?.title && <th className='w-2/12 text-left'>Title</th>}
            {!hide?.type && <th className='w-1/12 text-left'>Type</th>}
            {!hide?.unitPrice && <th className='w-1/12 text-right'>Price Per Piece</th>}
            {!hide?.quantity && <th className='w-1/12 text-right'>QTY</th>}
            {!hide?.price && <th className='w-1/12 text-right'>QTY Price</th>}
            {!hide?.subTotal && <th className='w-1/12 text-right'>Total</th>}
        </tr>
    );
}

export interface SharedOrderLineDataProps {
    displayNo?: string
    hide?: OrderLineHideColumns
    bold?: boolean
}

export interface OrderLineDataProps extends SharedOrderLineDataProps {
    title: string
    imageSrc?: string
    itemNumber?: string
    type?: string
    price?: number
    unitPrice?: number
    currency?: string
    quantity?: number
    minQuantity?: number
    subTotal?: number
}

export default function OrderLineData({ displayNo, imageSrc, itemNumber: sku, title, type, price, unitPrice, currency, quantity, minQuantity, subTotal, hide, bold }: OrderLineDataProps) {
    const
        actualPrice = price || 0,
        actualUnitPrice = unitPrice || 0,
        actualQuantity = quantity || 0,
        actualSubTotal = subTotal || actualQuantity * actualPrice


    const boldClass = bold ? ' font-bold' : ''
    const leftClass = 'text-left' + boldClass
    const rightClass = 'text-right' + boldClass

    return (
        <tr className='min-h-36 h-14'>
            {!hide?.displayNo && <td className={leftClass}>{displayNo || ''}</td>}
            {!hide?.image && <td className={leftClass}>{imageSrc && <img src={imageSrc} style={{ maxHeight: 60, maxWidth: 60 }} />}</td>}
            {!hide?.itemNumber && <td className={leftClass}>{sku || ''}</td>}
            {!hide?.title && <td className={leftClass}>{title || ''}</td>}
            {!hide?.type && <td className={leftClass}>{type || ''}</td>}
            {!hide?.unitPrice && <td className={rightClass}>{getPriceString(actualUnitPrice, currency)}</td>}
            {!hide?.quantity && <td className={rightClass}>{actualQuantity || ''}</td>}
            {!hide?.price && <td className={rightClass}>{getPriceString(actualPrice, currency)}</td>}
            {!hide?.subTotal && <td className={rightClass}>{getPriceString(actualSubTotal, currency) + (minQuantity ? ` (${minQuantity})` : '')}</td>}
        </tr>
    )
}

export interface BaseOrderLineDataProps extends SharedOrderLineDataProps { orderline: OrderLine }
export function BaseOrderLineData({ displayNo, orderline, hide }: BaseOrderLineDataProps) {
    return (
        <OrderLineData
            displayNo={displayNo ?? orderline.displayNo}
            hide={hide}
            itemNumber={orderline.product.productNo}
            title={orderline.product.productName}
            type={orderline.type}
            quantity={orderline.quantity}
            price={orderline.adjustedPrice}
            unitPrice={orderline.adjustedUnitPrice}
        />
    )
}

export interface DesignOrderLineDataProps extends BaseOrderLineDataProps { orderline: OrderLineDesign }
export function DesignOrderLineData({ displayNo, orderline, hide }: DesignOrderLineDataProps) {
    return (
        <OrderLineData
            bold={true}
            displayNo={displayNo ?? orderline.displayNo}
            hide={hide}
            imageSrc={orderline.displayImage}
            itemNumber={orderline.product.productNo}
            title={orderline.product.productName}
            type={orderline.product.productType}
            quantity={orderline.quantity}
            price={orderline.adjustedPrice}
            unitPrice={orderline.adjustedUnitPrice}
        />
    )
}

export interface LogoOrderLineDataProps extends BaseOrderLineDataProps { orderline: OrderLineLogo }
export function LogoOrderLineData({ displayNo, orderline, hide }: LogoOrderLineDataProps) {
    return (
        <OrderLineData
            displayNo={displayNo ?? orderline.displayNo}
            hide={hide}
            imageSrc={orderline.displayImage}
            itemNumber={orderline.product.productNo}
            title={orderline.title}
            type={getTypeText(orderline.baseLogo)}
            quantity={orderline.quantity}
            price={orderline.adjustedPrice}
            unitPrice={orderline.adjustedUnitPrice}
        />
    )
}

export interface GenericOrderLineDataProps extends SharedOrderLineDataProps { orderline: OrderLine }
export const GenericOrderLine = ({ hide, displayNo, orderline }: GenericOrderLineDataProps) => {
    switch (orderline.product.productType) {
        case 'Design':
            return <DesignOrderLineData displayNo={displayNo || orderline.displayNo} hide={hide} orderline={orderline as OrderLineDesign} />
        case 'Logo':
            return <LogoOrderLineData displayNo={displayNo || orderline.displayNo} hide={hide} orderline={orderline as OrderLineLogo} />
        default:
            return <BaseOrderLineData displayNo={displayNo || orderline.displayNo} hide={hide} orderline={orderline} />
    }
}