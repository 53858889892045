import { JetSportBox } from '../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../components/BoxView/BoxContent'
import { OrderDoneCelebration } from './subcomponents/OrderDoneCelebration'
import { BoxHeaderTypography } from '../../components/Typographies/BoxHeaderTypography'
import { useNavigate } from 'react-router'
import { useOrderRepository } from '../../../data/repositories/OrderRepository'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useSearchParams } from 'react-router-dom'

export function OrderPlaced() {
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId')
  const organizationId = searchParams.get('orgId')

  const navigate = useNavigate()
  function handleNavigateToOrders() {
    navigate('/order');
  }

  const { useOrder } = useOrderRepository()
  const { state, dataTransition } = useComponentStateMachine()

  const { data, isError, isLoading, isSuccess } = useOrder(+organizationId!, +orderId!);
  dataTransition(isLoading, isSuccess, isError)

  if (state === ComponentState.Failed) {
    return <ErrorView />
  } else if (state === ComponentState.Loading) {
    return <LoadingView />
  }

  return <JetSportBox>
    <BoxHeader2>
      <BoxHeaderTypography className={'uppercase'}>Order confirmed</BoxHeaderTypography>
    </BoxHeader2>
    <BoxContent className={'pt-12 pb-32 items-center'}>
      <OrderDoneCelebration orderId={data!.orderNumber} handleNavigateToOrders={handleNavigateToOrders}></OrderDoneCelebration>
    </BoxContent>
  </JetSportBox>
}

