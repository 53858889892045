import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { ErrorView } from '../../../components/error-view/ErrorView'
import LoadingElement from '../../../components/loading-element/loading-element'
import { LogoDetails } from '../components/logo-details.component'
import { useLogoActionHandler } from '../hooks/useLogoActionsHandler'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { useNavigate } from 'react-router'
import { Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { performCavemanReFetch } from '../../../../utils/functions/caveman-utils'

export function LogoDetailsWrapper({ logo, onViewRejectNote, closeDialog }: {
  logo: BaseLogo,
  onViewRejectNote: (logo: BaseLogo) => void,
  closeDialog: () => void
}) {

  const navigate = useNavigate()
  const { logoRepository } = useRepositoriesContext()

  const {
    data: logoDetails,
    isLoading: isSummaryLoading,
    isSuccess,
    isError
  } = logoRepository.useLogo(logo.customer.id, logo.id)

  const {
    handleGoToEditLogo,
    handleDeleteLogo,
    handleChangeTitle,
    handlePlaceVerdict,
    handleAddToCart,
    handleCopyThenGoToLogo,
    handleSkipApproval,
    handleSendApproval,
    isLoading: isActionRunning,
    handleRejectAndDelete
  } = useLogoActionHandler()
  const { state, dataTransition } = useComponentStateMachine()

  const isLoading = isSummaryLoading || isActionRunning
  dataTransition(isLoading, isSuccess, isError)


  function handleViewDesign(logo: BaseLogo) {
    navigate(`/design?pageSize=25&pageIndex=1&filterBy=designlogos.logoid&filterOn=${logo.id}`);
  }

  function handleTitle(title: string) {
    handleChangeTitle(logo.customer.id, logo.id, title)
  }

  function onDelete(logo: BaseLogo) {
    handleDeleteLogo(logo).then(performCavemanReFetch)
  }

  function onSendApproval(logo: BaseLogo, email: string) {
    handleSendApproval(logo, email)
  }

  function onRejectAndDelete(logo: BaseLogo, note?: string | undefined) {
    return handleRejectAndDelete(logo, note).then(performCavemanReFetch)
  }

  function onPlaceVerdict(logo: BaseLogo, verdict: Verdict)  {
    return handlePlaceVerdict(logo, verdict).then(performCavemanReFetch)
  }

  return state === ComponentState.Succeed
    ? <LogoDetails
      onPlaceVerdict={onPlaceVerdict}
      logo={logoDetails!}
      onAddToCart={handleAddToCart}
      onShowRejectionNote={onViewRejectNote}
      onDeleteLogo={onDelete}
      onTitleChange={handleTitle}
      onViewDesigns={handleViewDesign}
      onEditLogo={handleGoToEditLogo}
      onCopyLogo={handleCopyThenGoToLogo}
      onSkipApproval={handleSkipApproval}
      onSendApproval={onSendApproval}
      isLoading={isLoading}
      onRejectAndDelete={onRejectAndDelete}
    />
    : state === ComponentState.Loading
      ? <div className={'w-220 h-128 relative'}>
        <LoadingElement />
      </div>
      : <div className={'w-220 h-128 relative'}>
        <ErrorView />
      </div>
}