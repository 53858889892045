import { useState, useEffect } from 'react';
import { InputLabel } from '../../../../components/Input/InputLabel'
import LoadingElementIsolated from '../../../../components/loading-element/loading-element-isolated';
import { NumberPackage } from '../../../../../core/models/entities/NumberPackage';

interface Props {
  numberPackage?: NumberPackage,
  isValid: boolean,
  getPreview: (numberPackage: NumberPackage) => Promise<string>
}

export function NumberLogoPreview({ numberPackage, isValid, getPreview }: Props) {

  const [preview, setPreview] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isValid) {
      setPreview(undefined);
    }

    setIsLoading(isValid)
    const debounceTimeout = setTimeout(() => {
      if (isValid) {
        getPreview(numberPackage!)
          .then(result => setPreview(result))
          .catch(() => setPreview(undefined))
          .finally(() => setIsLoading(false));
      } else {
        setPreview(undefined);
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [numberPackage]);

  return <div className={'space-y-0.5'}>
    <InputLabel>PREVIEW</InputLabel>
    {
      <div className={'flex rounded border h-32 justify-center items-center'}>
        {isLoading
          ? <>
            <LoadingElementIsolated transparent className='h-32 animate-pulse' />
          </>
          : <>
            {!!preview && <img className="max-h-[100%]" src={`data:image/jpeg;base64,${preview}`} />}
          </>}
      </div>
    }
  </div>
}