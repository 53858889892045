import { JSX, MouseEvent, MouseEventHandler, useState } from 'react'
import { QueryChanger, usePageQuery } from '../../shared/hooks/usePageQuery'
import { PagedComponentState, usePagedComponentStateMachine } from '../../shared/hooks/usePageComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import FunctionalList, { TableData } from '../../components/GenericTable/FunctionalList'
import { Organization } from '../../../core/models/entities/Organization'
import { PageQuery } from '../../../utils/models/pageQuery'
import { useNavigate } from 'react-router-dom'
import { ColumnSettings } from '../../components/GenericTable/SubComponents/ListFrame'
import { StrucRow } from '../../components/StructureElements/StrucRow'
import { StrucCol } from '../../components/StructureElements/StrucCol'
import { nameOf } from '../../../utils/functions/ReflectionUtil'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { formatDate } from '../../../utils/functions/DateUtil'
import { vendorColumnsConfig } from './const/vendorColumnsConfig'
import { Tooltip } from '@mui/material'
import IconButton from '../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

export function VendorListScreen(): JSX.Element {
  const [query, queryChanger] = usePageQuery()
  const { state, dataTransition } = usePagedComponentStateMachine()
  const { styleRepository, organizationRepository } = useRepositoriesContext()
  const resStyle = styleRepository.useAllStyles(query)

  const [lastPage, setLastPage] = useState<number>(1)

  const {
    data: vendors,
    isSuccess: isManufacturersSuccess,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError
  } = organizationRepository.useAllVendors(query)

  if (vendors?.pageCount && vendors.pageCount !== lastPage) {
    setLastPage(vendors?.pageCount ?? 1)
  }

  const isLoading = resStyle.isLoading || isManufacturersLoading
  const isSuccess = isManufacturersSuccess && resStyle.isSuccess
  const isError = isManufacturersError || resStyle.isError
  dataTransition(isLoading, isSuccess, isError)


  return state === PagedComponentState.Succeed || state === PagedComponentState.PageLoading
    ? <VendorListWrapper
      organizations={vendors?.results}
      isPageLoading={state === PagedComponentState.PageLoading}
      query={query}
      queryChanger={queryChanger}
      lastPage={lastPage} />
    : state === PagedComponentState.Failed ?
      <ErrorView /> :
      <LoadingView />
}

interface WrapperProps {
  organizations?: Organization[],
  isPageLoading: boolean,
  query: PageQuery
  queryChanger: QueryChanger,
  lastPage: number
}

export function VendorListWrapper({
  organizations, isPageLoading, query, queryChanger, lastPage
}: WrapperProps) {

  const vm = useFunctionalListVendorViewModel(isPageLoading, query, queryChanger, lastPage, organizations)
  return <FunctionalList<Organization>
    {...vm}
  />
}


export function useFunctionalListVendorViewModel(isLoading: boolean, query: PageQuery,
  queryChanger: QueryChanger, lastPage: number, organizations?: Organization[]): TableData<Organization> {
  const navigate = useNavigate()

  function handleRowClick(organization: Organization) {
    navigate(`/vendor/${organization.id}`)
  }

  return {
    data: organizations,
    query: query,
    lastPage: lastPage,
    isLoading: isLoading,
    tableTitle: 'Vendors',
    onSearchQueryChange: queryChanger.handleSearchQueryChange,
    multiSelect: false,
    columns: vendorColumnsConfig,
    onNewSort: queryChanger.handleSortByField,
    onPageSizeChange: queryChanger.handlePageSizeChange,
    onNewPage: queryChanger.handleNewPage,
    renderRow: (columns, data) => <VendorListItemComponent organization={data} columns={columns} />,
    onRowClick: handleRowClick,
    getKey: d => d.id
  }
}

export interface VendorListItemProps {
  organization: Organization,
  columns: ColumnSettings;
}

export function VendorListItemComponent({ organization, columns }: VendorListItemProps) {
  const navigate = useNavigate()

  function handleEditRow(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    navigate(`/vendor/${organization.id}/edit`)
  }

  return <StrucRow className={'py-4'}>
    <StrucCol size={columns[nameOf<Organization>(org => org.name)].weight}>
      <Tooltip title={organization.name} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{organization.name}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.contactPerson)].weight}>
      <Tooltip title={organization.contactPerson} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{organization.contactPerson}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.email)].weight}>
      <Tooltip title={organization.email} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{organization.email}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.phone)].weight}>
      {// @ts-ignore
        formatPhoneNumberIntl(organization.phone)
      }
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.totalOrderValue)].weight}>
      DKK {organization.totalOrderValue ? organization.totalOrderValue.toFixed(2) : '0.00'}
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.lastOrder)].weight}>
      {organization.lastOrder ? formatDate(organization.lastOrder) : '-'}
    </StrucCol>
    <StrucCol size={columns['actions'].weight} className={'my-[-0.5rem]'}>
      <IconButton icon={<FontAwesomeIcon icon={faPenToSquare} />} onClick={handleEditRow}>
        Edit
      </IconButton>
    </StrucCol>
  </StrucRow>
}
