import { OrganizationDto } from './OrganizationDto'
import { PriceDto } from './PriceDto'
import { StyleDto } from './StyleDto'
import { ImageRefDto, LogoDto, ProductRefDto } from './LogoDto'
import { ApprovalGroupDto } from './ApprovalDto'
import { DesignStatus } from '../../../core/models/entities/enums/DesignStatus'

export interface DesignDto {
  id: number
  updateAtUtc: string
  createdAtUtc: string
  createdBy: string
  status: DesignStatus
  designLogos: DesignLogoDto[]
  approval: ApprovalGroupDto
  displayImage: ImageRefDto
  customerId: number
  customer: OrganizationDto
  style: StyleDto
  styleId: number
  title: string
  product: MultiProductRefDto
  heating: HeatingProductRefDto
}

export interface MultiProductRefDto {
  mul: string
  products: ProductRefDto[]
  totalPrices: PriceDto[]
}

export interface HeatingProductRefDto {
  name: string
  sku: string
  prices: PriceDto[]
}

export interface DesignLogoDto {
  id: number
  logoId: number
  inDesignId: string
  action: EditAction | null
  logo: LogoDto
  logoX: number
  logoY: number
  createdAtUtc: string
  updateAtUtc: string
  createdBy: string
  angleRads: number
  heightMm: number
  widthMm: number
  designerWidthMm: number
  viewId: number
  viewName: string
}

export enum EditAction {
  Move,
  Resize
}
