import { JSX, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { Style, StyleStatus } from '../../../../core/models/entities/Style'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { TableData } from '../../../components/GenericTable/FunctionalList'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { StyleListItemWrapper } from '../subcomponents/style-list-item.wrapper'
import { styleColumnConfig } from '../const/styleColumnConfig'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { FilterRecipe } from '../../../components/GenericTable/SubComponents/TableHeader/ListHeader'

export function useFunctionalListStyleViewModel(
  manufacturers: Manufacturer[],
  query: PageQuery = {} as PageQuery,
  queryChanger: QueryChanger,
  lastPage: number,
  isPageLoading: boolean,
  style?: Style[]
): TableData<Style> {
  const navigate = useNavigate()

  function handleStyle(style: Style) {}

  function handleUploadCatalog() {
    navigate('/style/catalog')
  }

  const filters: FilterRecipe<any>[] = [
    {
      options: manufacturers,
      key: 'manufacturer.id',
      getOptionValue: (org) => org.id,
      getOptionDisplay: (org) => org.name,
      defaultTitle: 'Manufacturers'
    } as FilterRecipe<Manufacturer>
  ]

  const center = <ViewPendingCount />

  const right = (
    <PrimaryButton key={'new-order'} onClick={handleUploadCatalog}>
      Upload Catalog
    </PrimaryButton>
  )

  return {
    onNewPage: queryChanger.handleNewPage,
    onNewSort: queryChanger.handleSortByField,
    onPageSizeChange: queryChanger.handlePageSizeChange,
    onSearchQueryChange: queryChanger.handleSearchQueryChange,
    onFilterChange: queryChanger.handleFilterChange,
    center: center,
    right: right,
    isLoading: isPageLoading,
    query: query,
    filters: filters,
    multiSelect: false,
    lastPage: lastPage,
    data: style,
    columns: styleColumnConfig,
    renderRow: renderRow,
    getKey: getKey,
    onRowClick: handleStyle,
    tableTitle: 'Styles',
    getRowColor: styleStatusBasedBg
  }
}

function styleStatusBasedBg(style: Style) {
  switch (style.status) {
    case StyleStatus.NotAnnotated:
      return 'bg-row-warn'
    case StyleStatus.Annotated:
      return 'bg-row-standard'
  }
}

function getKey(style: Style) {
  return style.id
}

function renderRow(columns: ColumnSettings, data: Style) {
  return <StyleListItemWrapper style={data} columns={columns} />
}

function ViewPendingCount(): JSX.Element {
  const [viewsPendingCount, setViewsPendingCount] = useState<number>(0)
  const { styleRepository } = useRepositoriesContext()

  function getViewsPendingCount() {
    styleRepository.getCreateMaskQueueCount().then((count) => {
      setViewsPendingCount(count)
    })
  }

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined

    interval = setInterval(() => {
      getViewsPendingCount()
    }, 20000)
    getViewsPendingCount()

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {viewsPendingCount !== undefined && (
        <div
          className={`w-[165px] flex justify-center items-center mr-2 rounded  border px-2 py-1 space-x-2 text-xs ${
            viewsPendingCount > 0
              ? 'bg-status-pending-2 border-status-pending-1 text-status-pending-1'
              : '!border-status-success bg-status-success-bg text-status-success '
          }`}>
          {viewsPendingCount > 0 ? (
            <>
              <span className="whitespace-nowrap">{viewsPendingCount} Images processing</span>
              <div className="text-status-pending-1">
                <CircularProgress color={'inherit'} size={15} />
              </div>
            </>
          ) : (
            <>
              <span className="whitespace-nowrap text-status-success">All Processed</span>
              <div className="text-status-success">
                <FontAwesomeIcon size={'xl'} icon={faCheck} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
