import { useState } from 'react'

import { OrganizationRepository } from '../../../data/repositories/OrganizationRepository'
import { Organization } from '../../../core/models/entities/Organization'
import { getAllQuery } from '../../screens/logo-editor/logo-editor.screen'

export function useCustomerAndVendors(organizationRepository: OrganizationRepository) {
  const [customers, setCustomers] = useState<Organization[]>()
  const [vendors, setVendors] = useState<Organization[]>()
  const [flag, setFlag] = useState<boolean>(false)

  const {
    data: customerPage,
    isError: isCustomerError,
    isSuccess: isCustomerSuccess,
    isLoading: isCustomerLoading
  } = organizationRepository.useAllCustomers(getAllQuery)

  const {
    data: vendorPage,
    isError: isVendorError,
    isSuccess: isVendorSuccess,
    isLoading: isVendorLoading
  } = organizationRepository.useAllVendors(getAllQuery)

  if (!flag && customerPage && vendorPage) {
    const customersFiltered = customerPage?.results
    setCustomers(customersFiltered)
    const vendorsFiltered = vendorPage?.results
    setVendors(vendorsFiltered)
    setFlag(true)
  }

  const isError = isVendorError || isCustomerError
  const isSuccess = isVendorSuccess && isCustomerSuccess
  const isLoading = isVendorLoading || isCustomerLoading

  return { customers, vendors, isError, isSuccess, isLoading }
}