import { createContext, useContext, useEffect, useState } from 'react'
import { Profile } from '../core/models/entities/Profile'
import { useProfileRepository } from '../data/repositories/ProfileRepository'
import { useOrganizationImpersonationContext } from './OrganizationImpersonationContext'
import { Role } from '../core/models/entities/Role'

interface ProfileProps {
  origin?: Profile
  profile?: Profile
  isImpersonatingAdmin: boolean
  role: Role | undefined
  b2CId: string | undefined
  organizationId: number | undefined
  refetch: () => Promise<Profile>
  updateProfile: (profile: Profile) => Promise<Profile>
}

const ProfileContext = createContext<ProfileProps | undefined>(undefined)

interface AuthenticationProviderProps {
  children: React.ReactNode
}

function ProfileProvider({
  children,
}: AuthenticationProviderProps): React.ReactElement {

  const { organizationId } = useOrganizationImpersonationContext()
  const { useProfile, updateProfile, updateProfileById, clearProfile } = useProfileRepository()
  const { data, refetch } = useProfile(organizationId)
  const isAdminRole = data?.userOrganizationInformations.role === Role.Admin
  const b2CId = data?.userOrganizationInformations.b2CId
  const [isAdmin, setIsAdmin] = useState<boolean>(isAdminRole)

  useEffect(() => {
    if (isAdminRole) {
      setIsAdmin(true)
    }
    else {
      setIsAdmin((v) => v ? true : v)
    }
  }, [isAdminRole])

  useEffect(() => {
    refetch()
  }, [organizationId])

  async function handleUpdateProfile(profile: Profile): Promise<Profile> {
    if (organizationId) {
      return await updateProfileById(organizationId!, profile)
    } else {
      return await updateProfile(profile)
    }
  }

  return (
    <ProfileContext.Provider value={{
      profile: data!,
      isImpersonatingAdmin: isAdmin,
      role: data?.userOrganizationInformations.role,
      organizationId: data?.userOrganizationInformations?.organizationId,
      b2CId: b2CId,
      refetch: () => refetch().then(x => x.data!),
      updateProfile: handleUpdateProfile
    }}>
      {children}
    </ProfileContext.Provider>
  )
}

function useProfileContext() {
  const context = useContext(ProfileContext)
  if (!context) {
    throw new Error('useProfileContext must be used within a ProfileProvider')
  }

  return context
}

export { ProfileProvider, useProfileContext, ProfileContext }
