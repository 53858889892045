import { Profile } from '../../../../core/models/entities/Profile'
import {
  BaseLogo,
  GetOverallApprovalStatus,
  ImageLogo,
  LogoEditStatus,
  LogoType
} from '../../../../core/models/entities/BaseLogo'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { Organization } from '../../../../core/models/entities/Organization'
import { TableData } from '../../../components/GenericTable/FunctionalList'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { logoColumnsConfig } from '../const/logoColumnsConfig'
import { LogoRowItem } from '../components/logo-list-item.component'
import { FilterRecipe } from '../../../components/GenericTable/SubComponents/TableHeader/ListHeader'
import { Role } from '../../../../core/models/entities/Role'
import { useLogoActionHandler } from '../hooks/useLogoActionsHandler'
import { organizationFilterField } from '../../../shared/const/organizationFilterField'
import {
  Color,
  Font,
  LogoOptions,
  TransferLogoType,
  TransferMethod
} from '../../../../core/models/entities/LogoOptions'
import { ApprovalStatus } from '../../../../core/models/entities/Approval'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { useMultiSelectStateMachine } from '../../../shared/hooks/useMultiSelectStateMachine'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { useState } from 'react'
import { performCavemanReFetch } from '../../../../utils/functions/caveman-utils'
import { Verdict } from '../../../components/ApprovalVoting/approval-voting'

export function useFunctionalListLogosViewModel(
  profile: Profile,
  isPageLoading: boolean,
  lastPage: number,
  options: LogoOptions,
  query: PageQuery,
  queryChanger: QueryChanger,
  onSelectLogo: (logo: BaseLogo) => void,
  onViewRejectNote: (logo: BaseLogo) => void,
  onOpenInitApproval: (logo: BaseLogo) => void,
  openCreateLogoDialog: () => void,
  customers?: Organization[],
  vendors?: Organization[],
  logos?: BaseLogo[]
): TableData<BaseLogo> {
  const {
    handleGoToEditLogo,
    handleDeleteLogo,
    handlePlaceVerdict,
    handleAddToCart,
    handleAddItemsToCart,
    handleRejectAndDelete,
    isLoading: isActionRunning
  } = useLogoActionHandler()

  // TODO This component may have several handlers that do not refresh when called, and may need a caveman treatment.

  const { role } = useProfileContext()
  const [deleted, setDeleted] = useState<number[]>([])

  const { isMultiSelect, toggleMultiSelect, selectedData, onRowChecked, onMultiSelectRow } =
    useMultiSelectStateMachine<BaseLogo>({ onSelect: onSelectLogo })

  function addToCart() {
    handleAddItemsToCart(Object.values<BaseLogo>(selectedData), 10)
  }

  function placeVerdict(logo: BaseLogo, verdict: Verdict) {
    return handlePlaceVerdict(logo, verdict).then(performCavemanReFetch)
  }

  const center = (
    <PrimaryButton key={'design-create'} width={'md'} onClick={openCreateLogoDialog}>
      Create new Logo
    </PrimaryButton>
  )

  const right = (role !== Role.Admin && (
    <PrimaryButton width={'md'} onClick={addToCart}>
      Add to Order ({Object.values(selectedData)?.length})
    </PrimaryButton>
  )) || <></>

  const filters = buildLogoFilterRecipes(profile, options.transferMethods, vendors, customers)
  const shouldUseAdditionalFilters = !!query.filterBy.find((it) => it.field === 'logoType')
  const additionalFilters = shouldUseAdditionalFilters ? buildAdditionalLogoFilterRecipes(options.fontSizes, options.colors, options.fonts) : undefined

  // Because the entire roundabout for logos doesn't start and end the same place, 
  //  we cannot simply ask for a fresh list of logos when deleting one.
  // Instead we use a hacky list of deleted logos to ensure we don't remove logos that were not deleted.
  // Next time the list is rendered, the deleted logos will not be included.
  const handleDeleteAndHide = async (logo: BaseLogo) => {
    try {
      await handleDeleteLogo(logo)
      setDeleted([...deleted, logo.id])
    } catch {
      // Do not mark logo as deleted after all.
    }
  }

  return {
    tableTitle: 'Logos',
    columns: logoColumnsConfig,
    onRowClick: onMultiSelectRow,
    data: logos?.filter((l) => !deleted.includes(l.id)),
    getKey: getKey,
    getRowColor: logoRowBgColor,
    onSearchQueryChange: queryChanger.handleSearchQueryChange,
    query: query,
    renderRow: (columns, data) => (
      <LogoRowItem
        columns={columns}
        logo={data}
        onPlaceVerdict={placeVerdict}
        onAddToCart={handleAddToCart}
        onEditLogo={handleGoToEditLogo}
        onSeeRejectNote={onViewRejectNote}
        onDeleteLogo={handleDeleteAndHide}
        onRejectAndDelete={handleRejectAndDelete}
        onInitiateApproval={onOpenInitApproval}
      />
    ),
    onPageSizeChange: queryChanger.handlePageSizeChange,
    onNewSort: queryChanger.handleSortByField,
    onNewPage: queryChanger.handleNewPage,
    onFilterChange: queryChanger.handleFilterChange,
    isLoading: isActionRunning || isPageLoading,
    filters: filters,
    additionalFilters,
    lastPage: lastPage,
    center: center,
    right: right,
    multiSelect: true,
    selectedData: selectedData,
    onRowSelected: onRowChecked
  }
}

const getKey = (logo: BaseLogo) => logo.id

function buildLogoFilterRecipes(
  profile: Profile,
  transforMethods: TransferMethod[],
  vendor?: Organization[],
  customers?: Organization[]
) {
  const filters: FilterRecipe<any>[] = []
  const logoOptions = useLogoOptionsContext()

  if (profile.userOrganizationInformations.role >= Role.Vendor && customers) {
    filters.push({
      options: customers,
      key: organizationFilterField,
      getOptionValue: (org) => org.id,
      getOptionDisplay: (org) => org.name,
      defaultTitle: 'Customer'
    } as FilterRecipe<Organization>)
  }

  filters.push({
    options: [
      LogoState.Approved,
      LogoState.Rejected,
      LogoState.Pending,
      LogoState.Committed,
      LogoState.Draft
    ],
    key: 'stateFilter',
    getOptionValue: (org) => org as string,
    getOptionDisplay: (org) => org as string,
    defaultTitle: 'Status'
  } as FilterRecipe<LogoState>)

  filters.push({
    options: logoOptions.transferLogoTypes,
    key: 'typeFilter',
    getOptionValue: (o) => o.typeKey,
    getOptionDisplay: (o) => o.nickname,
    defaultTitle: 'Type'
  } as FilterRecipe<TransferLogoType>)

  filters.push({
    options: transforMethods,
    key: 'transferMethod.key',
    getOptionValue: (org) => org.key,
    getOptionDisplay: (org) => org.method1 + (org.method2 ? `, ${org.method2}` : ''),
    defaultTitle: 'Method'
  } as FilterRecipe<TransferMethod>)

  filters.push({
    options: Object.keys(LogoType).filter((l) => l !== LogoType.ImageLogo),
    key: 'logoType',
    getOptionValue: (o) => o.toLowerCase().replace('logo', ''),
    getOptionDisplay: (o) => LogoType[o].replace('Logo', ''),
    defaultTitle: 'Logo Type'
  } as FilterRecipe<LogoType>)

  return filters
}

function buildAdditionalLogoFilterRecipes(sizes: number[], colors: Color[], fonts: Font[]) {
  const filters: FilterRecipe<any>[] = []

  filters.push({
    options: sizes,
    key: 'HeightMm',
    getOptionValue: (o) => o,
    getOptionDisplay: (o) => o.toString(),
    defaultTitle: 'Size'
  } as FilterRecipe<number>)

  filters.push({
    options: colors,
    key: 'Color.Id',
    getOptionValue: (o) => o.id,
    getOptionDisplay: (o) => o.name,
    defaultTitle: 'Color'
  } as FilterRecipe<Color>)

  filters.push({
    options: fonts,
    key: 'Font',
    getOptionValue: (o) => o.key,
    getOptionDisplay: (o) => o.name,
    defaultTitle: 'Font'
  } as FilterRecipe<Font>)

  return filters
}

function logoRowBgColor(logo: BaseLogo) {
  if (logo.logoType !== LogoType.ImageLogo) {
    return 'bg-row-standard'
  }

  const imageLogo = logo as ImageLogo

  if (imageLogo.status === LogoEditStatus.Draft) {
    return 'bg-row-draft'
  }

  if (imageLogo.status === LogoEditStatus.Done) {
    return 'bg-row-standard'
  }

  switch (GetOverallApprovalStatus(imageLogo.approval!)) {
    case ApprovalStatus.Rejected:
      return 'bg-row-warn'
    case ApprovalStatus.Approved:
      return 'bg-row-standard'
    case ApprovalStatus.Pending:
      return 'bg-row-pending'
  }
}

export function getLogoState(logo: BaseLogo): LogoState {
  const imageLogo = logo as ImageLogo

  if (imageLogo.status === LogoEditStatus.Draft) {
    return LogoState.Draft
  }

  if (imageLogo.status === LogoEditStatus.Done) {
    return LogoState.Approved
  }

  if (imageLogo.status === LogoEditStatus.Rejected) {
    return LogoState.Rejected
  }

  if (imageLogo.approval === undefined) {
    return LogoState.Undefined
  }

  switch (GetOverallApprovalStatus(imageLogo.approval!)) {
    case ApprovalStatus.Rejected:
      return LogoState.Rejected
    case ApprovalStatus.Approved:
      return LogoState.Approved
    case ApprovalStatus.Pending:
      return LogoState.Pending
  }
}

export enum LogoState {
  Draft = 'Draft',
  Committed = 'Committed',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Approved = 'Approved',
  Undefined = 'Undefined'
}
