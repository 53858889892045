import { ReactComponent as EyeSvg } from '../../../resources/icons/eye-view.svg'
import { ReactComponent as EditSvg } from '../../../resources/icons/edit.svg'
import { ReactComponent as TrashSvg } from '../../../resources/icons/trashcan-cross.svg'
import { ReactComponent as AddSvg } from '../../../resources/icons/add.svg'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import { SecondaryButton } from '../Buttons/SecondaryButton'

type Props = React.HTMLProps<HTMLButtonElement> & {
  icon: JSX.Element
  width?: 'icon' | 'xs' | 'sm' | 'md' | 'full'
  variant?: 'success' | 'error' | 'dim'
  textTransformation?: 'uppercase' | 'lowercase' | 'capitalize' | 'normal-case' | undefined
}

export default function IconButton({
  children,
  onClick,
  icon,
  className,
  width,
  disabled,
  variant = 'dim',
  textTransformation = 'uppercase'
}: Props): ReactElement {
  function getClassName() {
    switch (variant) {
      case 'success':
        return `${className} iconButtonHoverGreen`
      case 'error':
        return `${className} iconButtonHoverRed`
      case 'dim':
        return `${className} iconButtonHoverDim`
    }
  }
  return (
    <SecondaryButton
      width={width}
      disabled={disabled}
      className={`!h-7 ${textTransformation} ${getClassName()}`}
      onClick={onClick}>
      <div className="flex justify-between items-center w-full pl-2">
        <span>{icon}</span>
        <div className={'flex-1'}>{children}</div>
      </div>
    </SecondaryButton>
  )
}

interface ViewButtonProps {
  onClick: () => void
}

export function ViewButton({ onClick }: ViewButtonProps): ReactElement {
  const { t } = useTranslation()

  return (
    <IconButton
      icon={<EyeSvg />}
      onClick={onClick}
      className="hover:text-primary-100 hover:fill-primary-100">
      {t('view')}
    </IconButton>
  )
}

interface DefaultButtonProps extends Omit<Props, 'icon'> { }

export function ButtonWithIcon({
  onClick,
  className,
  variant = 'dim',
  children,
  icon,
  ...props
}: Props & { variant?: 'success' | 'error' | 'dim' | 'default' }): ReactElement {
  function getClassName() {
    switch (variant) {
      case 'success':
        return `${className} iconButtonHoverGreen`
      case 'error':
        return `${className} iconButtonHoverRed`
      case 'dim':
        return `${className} iconButtonHoverDim`
    }
  }
  return (
    <IconButton icon={icon} onClick={onClick} className={getClassName()} {...props}>
      {children}
    </IconButton>
  )
}

export function DeleteButton(props: DefaultButtonProps): ReactElement {
  const { t } = useTranslation()
  return (
    <ButtonWithIcon icon={<TrashSvg />} {...props}>
      {t('delete')}
    </ButtonWithIcon>
  )
}

interface RemoveButtonProps {
  onClick: () => void
}

export function RemoveButton({ onClick }: RemoveButtonProps): ReactElement {
  const { t } = useTranslation()
  return (
    <IconButton icon={<TrashSvg />} onClick={onClick} className="hover:text-red hover:fill-red">
      {t('remove')}
    </IconButton>
  )
}

export function AddButton(props: DefaultButtonProps): ReactElement {
  const { t } = useTranslation()
  return (
    <ButtonWithIcon icon={<AddSvg />} {...props}>
      {t('add')}
    </ButtonWithIcon>
  )
}
