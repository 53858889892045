import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { Style } from '../../../../core/models/entities/Style'

export const styleColumnConfig = [
  {
    value: nameOf<Style>(x => x.thumbnailUrl),
    canSortBy: false,
    displayValue: 'Thumbnail',
    weight: 1
  },
  {
    value: nameOf<Style>(x => x.name),
    canSortBy: true,
    displayValue: 'Model',
    weight: 2
  },
  {
    value: 'manufacturer.id',
    canSortBy: true,
    displayValue: 'Manufacturer',
    weight: 0
  },
  {
    value: nameOf<Style>(x => x.status),
    canSortBy: true,
    displayValue: 'Status',
    weight: 1
  },
  {
    value: nameOf<Style>(x => x.updatedAtUtc),
    canSortBy: true,
    displayValue: 'Last Updated',
    weight: 1
  },
  {
    value: 'actions',
    canSortBy: false,
    displayValue: 'Action',
    weight: 1.2
  }
]