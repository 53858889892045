import React, { useState } from 'react'
import { Radio } from '@mui/material'
import { StrucRow } from '../StructureElements/StrucRow'
import { StrucCol } from '../StructureElements/StrucCol'
import { InputWrapper } from '../Input/InputWrapper'
import { InputLabel } from '../Input/InputLabel'
import { CustomTextArea } from '../Input/CustomInput'
import { SecondaryButton, WarnButton } from '../Buttons/SecondaryButton'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import LoadingElement from '../loading-element/loading-element'

interface DialogCont {
  component: JSX.Element | null,
  open: () => void
}

type Option = 'delete' | 'note'

export function useRejectLogoDialog(title: string, onReject: (reason?: string) => void, onDelete: (reason?: string) => void, onClose: () => void): DialogCont {
  const [rejectionNote, setRejectionNote] = useState<string>('')
  const dialog = useDialog()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [option, setOption] = useState<Option>('note')

  function handleReject() {
    if (option === "note") {
      onReject(rejectionNote)
    } else {
      onDelete(rejectionNote)
    }
    dialog.closeDialog();
  }

  function handleCancel() {
    onClose()
    dialog.closeDialog();
  }

  function handleOption(option: Option) {
    setOption(option)
  }

  return {
    component: isLoading ? null :<RejectLogoDialog option={option} setOption={handleOption} isLoading={isLoading} dialogVm={dialog} setReason={setRejectionNote} onReject={handleReject} onClose={handleCancel} title={title} />,
    open: dialog.openDialog
  }
}

interface Props {
  option: Option
  setOption: (option: Option) => void
  setReason: (reason: string) => void
  onReject: () => void
  onClose: () => void
  title: string
  dialogVm: Dialog_VM
  isLoading: boolean
}

export function RejectLogoDialog({ option, setOption, isLoading, setReason, onReject, onClose, title, dialogVm }: Props) {
  if (isLoading) return null
  
  return <DialogFrame onClose={onClose} dialogOption={dialogVm} >
    {isLoading && <LoadingElement transparent />}
    <span>{title}</span>
    <div className={'flex flex-col ml-[-8px]'}>
      <div>
        <Radio
          checked={option === 'delete'}
          onChange={x => setOption('delete')}
          value='delete'
          name='radio-buttons'
          inputProps={{ 'aria-label': 'Delete' }}
        />
        <span>Delete this product</span>
      </div>
      <div>
        <Radio
          checked={option === 'note'}
          onChange={x => setOption('note')}
          value='note'
          name='radio-buttons'
          inputProps={{ 'aria-label': 'Note' }}
        />
        <span>Add Remark</span>
      </div>
    </div>
    <StrucRow>
      <StrucCol>
        <InputWrapper>
          <InputLabel><span>Rejection Note:</span></InputLabel>
          <CustomTextArea disabled={option !== 'note'} onChange={e => setReason(e.target.value)} />
        </InputWrapper>
      </StrucCol>
    </StrucRow>
    <div className={'flex justify-between space-x-4'}>
      <WarnButton width={'full'} onClick={onReject}>Reject</WarnButton>
      <SecondaryButton width={'full'} onClick={onClose}>Cancel</SecondaryButton>
    </div>
  </DialogFrame>
}
