import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { NavigationBarButton } from '../../../screens/header/subcomponents/NavigationBarButton'
import React from 'react'
import { PrimaryButton } from '../../Buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { useCartContext } from '../../../../contexts/CartContext'
import { CartListComponent } from '../../../screens/order-cart/CartListComponent'
import { BaseMenu } from './BaseMenu'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'

export function CartNavMenu() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { role } = useProfileContext()

  const { cartSummary, items } = useCartContext()

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    handleToCart()
    // setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  
  function handleToCart() {
    navigate('/order/place')
    // handleClose()
  }

  if (role === Role.Admin) {
    return <></>
  }

  return (
    <div>
      <NavigationBarButton className={'px-5 w-[200px]'} onClick={x => handleClick(x)}>
        <div className='flex justify-between space-x-4'>
          <CartTypography className='content-center'>items <b>{cartSummary?.totalQuantity}</b></CartTypography>
          <CartTypography className='content-center'>total <b>DKK {cartSummary?.totalPrice?.toFixed(2)}</b></CartTypography>
          <div className={'content-center relative'}>
            <div className={'h-[7px] w-[7px] rounded-full bg-success absolute top-[-2px] right-0'} />
            <FontAwesomeIcon size={'lg'} icon={faCartShopping} />
          </div>
        </div>
      </NavigationBarButton>
      
      {/* TODO Remove everything related to this overlay, provided no trouble arises from its absence...! */}

      {/* <BaseMenu
        id='basic-menu'
        anchorEl={anchorEl}
        open={false}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={'px-2 space-y-2'}>
          <div>
            <PrimaryButton onClick={handleToCart} width={'full'}>To Order</PrimaryButton>
          </div>
          <div className={'bg-success'}>
            <CartListComponent cartItems={items} />
          </div>
        </div>
      </BaseMenu> */}
    </div>
  )
}

export const CartTypography = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  (props, ref) => {
    const { className, ...rest } = props
    return <p ref={ref}
      className={`text-xxs capitalize text-left ${className ?? ''}`} {...rest}>{props.children}</p>
  }
)