import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import LoadingElement from '../../../../components/loading-element/loading-element'
import { NumberLogoPreview } from './number-logo-preview.component'
import { TransferMethodEditor } from './transfer-method-editor.component'
import { FontAndColorEditor } from './font-and-color-editor.component'
import { Color, Font, LogoOptions, TransferMethod } from '../../../../../core/models/entities/LogoOptions'
import { getConvertedLogoOptions } from '../../../design-editor/design-editor-tool/DesignEditorTool.component'

export interface NumberPlaceholderLogoCreate_VM {
  onAddLogo: () => void
  onCancel: () => void
  canAdd: boolean
  getPreview: (color: Color, heightMM: number, font: Font, transferMethod?: TransferMethod) => Promise<string>
  transferMethod?: TransferMethod
  setTransferMethod: (value: (((prevState: (TransferMethod | undefined)) => (TransferMethod | undefined)) | TransferMethod | undefined)) => void,
  isLoading: boolean
  font: Font | undefined
  color: Color | undefined
  heightMM: number
  preview?: string
  options: LogoOptions
  onColorChange: (value: (((prevState: (Color | undefined)) => (Color | undefined)) | Color | undefined)) => void
  onFontChange: (value: (((prevState: (Font | undefined)) => (Font | undefined)) | Font | undefined)) => void
  onHeightMMChange: (value: (((prevState: number) => number) | number)) => void
}

export function NumberPlaceholderLogoCreate(
  {
    transferMethod,
    setTransferMethod,
    onAddLogo,
    onCancel,
    canAdd,
    getPreview,
    isLoading,
    onHeightMMChange,
    heightMM,
    font, onFontChange, onColorChange,
    options,
    color,
  }: NumberPlaceholderLogoCreate_VM) {

  return <div className={'flex flex-col h-full space-y-4 py-4'}>
    {isLoading && <LoadingElement transparent />}
    <NumberLogoPreview
      heightMM={heightMM}
      font={font}
      color={color}
      method={transferMethod}
      isValid={canAdd}
      getPreview={getPreview}
    />
    <div className={'flex-1'}>
      <FontAndColorEditor
        color={color}
        onSelectColor={onColorChange}
        font={font}
        onSelectFont={onFontChange}
        options={getConvertedLogoOptions(options, 'number')}
        fontSize={heightMM}
        showValueField={false}
        onSelectFontSize={onHeightMMChange}
      />
      <TransferMethodEditor
        className='my-4'
        value={transferMethod}
        onChange={setTransferMethod}
        transferMethods={options.transferMethodsNumbers}
      />
    </div>
    <PrimaryButton width={'full'} onClick={onAddLogo} disabled={!canAdd}>Add Number</PrimaryButton>
    <SecondaryButton width={'full'} onClick={onCancel}>Cancel</SecondaryButton>
  </div>
}