import { useState } from 'react'

import { useCartContext } from '../../../contexts/CartContext'
import { CartItem } from './models/CartItem'
import { useOrderActionHandler } from './hooks/useOrderActionsHandler'
import { OrderCreateViewModel } from './OrderCreate.Component'
import { useNavigate } from 'react-router'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { t } from 'i18next'
import { ExtraLogo, HeatingOption } from '../order-confirmation/dialogs/add-heating-fee-order-dialog'
import { useErrorToast, useSuccessToast } from '../../components/Toast/Toast'

export function useOrderCreateViewModel(): OrderCreateViewModel {

  const navigate = useNavigate();
  const { profile } = useProfileContext();

  const [selectedOrderLineId, setSelectedOrderLineId] = useState<string | undefined>(undefined);
  const { items } = useCartContext();
  const { handleRemoveOrderLine, handleCreateOrder, isLoading } = useOrderActionHandler();

  if (selectedOrderLineId == undefined && items.length > 0) {
    setSelectedOrderLineId(items[0].id)
  }

  function removeOrderLineHandler(orderLine: CartItem) {
    handleRemoveOrderLine(orderLine.id)

    if (selectedOrderLineId == orderLine.id) {
      setSelectedOrderLineId(undefined)
    }
  }

  function selectOrderLineHandler(orderLine: CartItem) {
    setSelectedOrderLineId(orderLine.id)
  }

  async function handleContinue(heatingOption: HeatingOption, extraLogos: ExtraLogo[]): Promise<void> {
    const orgId = profile?.userOrganizationInformations.organizationId!
    return handleCreateOrder(orgId, items, heatingOption, extraLogos).then((order) => {
      navigate(`/order/confirm?orderId=${order.id}&orgId=${order.organization.id}`)
      useSuccessToast(t("toasts.orders_create_success"))
    }).catch((error) => {
      console.log(error)
      useErrorToast(t("toasts.orders_create_failed"))
    })
  }

  const isValid = items?.length > 0;

  return {
    handleRemoveOrderLine,
    orderlines: items,
    selectedOrderLineId,
    selectOrderLineHandler,
    setSelectedOrderLineId,
    removeOrderLineHandler,
    handleContinue,
    isLoading,
    isValid
  }
}