import { Profile } from '../../../../core/models/entities/Profile'
import { Organization } from '../../../../core/models/entities/Organization'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { BaseLogo, ImageLogo } from '../../../../core/models/entities/BaseLogo'
import { JSX, useEffect, useRef, useState } from 'react'
import { Role } from '../../../../core/models/entities/Role'
import Dropdown from '../../../components/dropdown/Dropdown'
import { extensionsToMimeTypes } from '../componenets/ExtensionsToMimeTypesString'
import { ColorChange, useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { ListReset } from '../componenets/LogoPicker'
import { LogoEditor_VM } from '../componenets/logo-editor.component'
import { useConfirmationDialog } from '../../../components/Dialogs/confirmation-dialog'
import { useApprovalDialogByRole } from '../../../components/Dialogs/useApprovalDialogByRole'
import { useNavigate } from 'react-router'
import { ImageLogoForm } from '../models/ImageLogoForm'

export function useLogoEditorViewModel(profile: Profile, customers: Organization[], vendors: Organization[], options: LogoOptions, selectedCustomerId: number | undefined, preselectedLogo: BaseLogo | undefined): LogoEditor_VM {
  const [selectedCustomer, setSelectedCustomer] = useState<Organization | undefined>(customers.find(x => x.id === selectedCustomerId))
  const [selectedLogo, setSelectedLogo] = useState<BaseLogo | undefined>(preselectedLogo)
  const [uploadImage, setUploadImage] = useState<File | null>(null)
  const [tempColorImage, setTempColorImage] = useState<string | null>(null)
  const [colorChangeCode, setColorChangeCode] = useState<string | null>(null)
  const [isFullLoading, setIsFullLoading] = useState<boolean>(false)
  const { isLoading, handleInitiateApproval, handleUpdateLogo, handleUpdateLogoColor, handleResizeLogo, handleCreateLogo, handleSkipApproval, handleSkipAddToCart } = useLogoActionHandler()

  const copy = Object.assign({}, selectedLogo as ImageLogo)
  const [logoForm, setLogoForm] = useState<ImageLogoForm>(toLogoForm(copy))

  const navigate = useNavigate()
  const { component, start } = useApprovalDialogByRole<BaseLogo>({
    role: profile.userOrganizationInformations.role,
    subject: "logo",
    onSkip: entity => handleSkipApproval(entity).then(() => navigate("/logo")),
    onOrder: (entity) => handleSkipAddToCart(entity, 10).then(() => navigate('/logo')),
    onInitApproval: (entity, addExternal, email1) => handleInitiateApproval(entity, {
      skip: false,
      vendor: true,
      customer: true,
      external: addExternal ?? false,
      externalEmail: email1
    }).then(() => {
      navigate('/logo')
    })
  })

  const stopOverlayLoading = () => setIsFullLoading(false)
  const startOverlayLoading = () => setIsFullLoading(true)

  const filters: JSX.Element[] = []

  function getCustomerWithID(customerId: Organization['id']) {
    return customers.filter(org => !org.isVendor && customerId === org.id)[0]
  }

  useEffect(() => {
    if (profile.userOrganizationInformations.role === Role.Admin && !selectedCustomer) {
      if (selectedCustomerId) {
        const customer = getCustomerWithID(selectedCustomerId)
        if (customer) {
          setSelectedCustomer(customer)
        }
      } else {
        const customer = customers.filter(org => !org.isVendor)[0]
        if (customer) {
          setSelectedCustomer(customer)
        }
      }
    }

    if (profile.userOrganizationInformations.role === Role.Vendor && !selectedCustomer) {
      if (selectedCustomerId) {
        const customer = customers.filter(org => !org.isVendor && org.id === selectedCustomerId)[0]
        setSelectedCustomer(customer)
      } else {
        const customer = customers.filter(org => !org.isVendor)[0]
        setSelectedCustomer(customer)
      }
    }

    if (profile.userOrganizationInformations.role === Role.EndUser && !selectedCustomer) {
      const customer = customers.filter(org => !org.isVendor && org.id === profile.userOrganizationInformations.organizationId)[0]
      setSelectedCustomer(customer)
    }
  }, [profile, customers, selectedCustomerId])

  if (profile.userOrganizationInformations.role >= Role.Vendor) {
    const customerFilter = <Dropdown<Organization>
      key={'customer-filter'}
      selectedOption={selectedCustomer}
      options={customers}
      onOptionSelect={setSelectedCustomer}
      defaultValue
      defaultTitle={'Group Customer'}
      width='max'
      getOptionDisplay={(option) => option.name}
    />

    filters.push(customerFilter)
  }

  function fileUploadHandler(image: File) {
    if (!selectedCustomer) {
      console.warn('no customer was selected')
      return
    }

    if (!extensionsToMimeTypes(options.extensions).find(x => x === image.type)) {
      alert(image.type + ' not valid file format')
      return
    }
    setUploadImage(image)
    handleOpen()
  }

  const confirmationDialog = useConfirmationDialog(
    'Are you sure you want to upload this file?',
    dialogAcceptHandler,
    dialogCancelHandler)
  function handleOpen() {
    confirmationDialog.open()
  }

  function dialogCancelHandler() {
    setUploadImage(null)
  }

  function dialogAcceptHandler() {
    createLogo()
  }

  function createLogo() {
    if (!selectedCustomer) {
      console.warn('no customer was selected')
      return
    }
    if (!uploadImage) {
      return
    }
    startOverlayLoading()
    handleCreateLogo(uploadImage, selectedCustomer.id, "New Logo")
      .then(x => {
        setSelectedLogo(x)
        setUploadImage(null)
        resetList()
      })
      .finally(stopOverlayLoading)
  }

  function handleSelectLogo(logo: BaseLogo) {
    if (isLoading) {
      return
    }
    setSelectedLogo(logo)
    setTempColorImage(null)
  }

  function handleSaveDraftChanges() {
    const updatedLogo = {
      ...selectedLogo,
      ...logoForm,
      customerId: logoForm.customer.id
    } as ImageLogo

    handleUpdateLogo(selectedCustomerId!, updatedLogo)
      .then((res) => {
        setSelectedLogo(res)
        setLogoForm(toLogoForm(res as ImageLogo))
        navigate(`/logo/create?logo=${res.id}&organization=${res.customer.id}`)
      })
  }

  function handleSaveApproval() {
    const updatedLogo = {
      ...selectedLogo,
      ...logoForm
    } as ImageLogo

    handleUpdateLogo(selectedCustomerId!, updatedLogo)
      .then((res) => {
        setSelectedLogo(res)
        setLogoForm(toLogoForm(res as ImageLogo))
        return res;
      })
      .then(start)
  }

  function updateColorHandler(colors: ColorChange[]) {
    if (!selectedLogo) {
      console.warn('No Logo was selected')
      return
    }

    handleUpdateLogoColor(selectedCustomerId!, selectedLogo.id, { colorChanges: colors }).then(x => {
      const imageLogo = x as ImageLogo
      setTempColorImage(imageLogo.displayImage.highResUrl ?? null)
      setColorChangeCode(imageLogo.colorChange?.colorChangeCode ?? null)
      setSelectedLogo({
        ...imageLogo,
        colorChange: imageLogo.colorChange
      } as ImageLogo)
    })
  }

  function cancelHandler() {
    setSelectedLogo(undefined)
    navigate("/logo")
  }

  const logoListRef = useRef<ListReset>(null)
  const resetList = () => {
    if (logoListRef.current) logoListRef.current.reset()
  }

  function toLogoForm(logo: ImageLogo): ImageLogoForm {
    return {
      id: logo.id,
      title: logo.title ?? '',
      transferMethod: logo.transferMethod,
      heightMm: logo.heightMm ?? 0,
      widthMm: logo.widthMm ?? 0,
      customer: logo.customer,
      colors: logo.colors
    }
  }

  function updateForm(formLogo: ImageLogoForm) {
    setLogoForm({ ...formLogo })
  }

  function handleResize() {
    const updatedLogo = {
      ...selectedLogo,
      ...logoForm
    } as ImageLogo
    
    handleResizeLogo(selectedCustomerId!, updatedLogo).then(resizedLogo => {
      const resizedImageLogo = resizedLogo as ImageLogo
      setSelectedLogo({ ...resizedImageLogo })
      setLogoForm({
        ...logoForm,
        heightMm: resizedImageLogo.heightMm,
        widthMm: resizedImageLogo.widthMm
      })
    })
  }

  return {
    logoListRef,
    isLoading: isFullLoading,
    isToolLoading: isFullLoading ? false : isLoading,
    onToolCancel: cancelHandler,
    onFileUploadUpload: fileUploadHandler,
    filters: filters,
    onToolSaveDraftChanges: handleSaveDraftChanges,
    onToolSaveApprovalChanges: handleSaveApproval,
    options: options,
    onSelectLogo: handleSelectLogo,
    profile: profile,
    selectedCustomer: selectedCustomer,
    selectedLogo: selectedLogo,
    tempColorImage: tempColorImage,
    onToolUpdateColor: updateColorHandler,
    approvalDialog: component,
    confirmationDialog: confirmationDialog.component,
    customers,
    logoForm: logoForm,
    updateForm: updateForm,
    onResize: handleResize
  }
}




