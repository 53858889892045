import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { BaseLogo } from '../../core/models/entities/BaseLogo'
import { Resource } from '../../core/models/wrappers/Resource'
import { useApiClient } from '../clients/ApiClient'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { Organization } from '../../core/models/entities/Organization'
import { PagedResponse } from '../models/responses/pagedResponse'
import { mapToLogo } from '../models/mappers/mapLogo'
import { LogoOptions } from '../../core/models/entities/LogoOptions'
import { mapLogoOptions } from '../models/mappers/mapLogoOptions'
import { TransferOptions } from '../../core/models/entities/TransferOptions'
import { mapTransferOptions } from '../models/mappers/mapTransferOptions'
import { ChangeLogoColors, InitiateApproval } from '../../presentation/screens/logo-list/hooks/useLogoActionsHandler'
import { LogoCommit } from '../models/bodies/LogoCommit'
import { Verdict } from '../../presentation/components/ApprovalVoting/approval-voting'

export interface LogoRepository {
  changeColors(orgId: Organization['id'], logoId: BaseLogo['id'], colors: ChangeLogoColors): Promise<BaseLogo>
  changeTitle(orgId: Organization['id'], logoId: BaseLogo['id'], title: string): Promise<BaseLogo>
  useLogo(orgId: number, logoId: number): Resource<BaseLogo>
  useOptions(): Resource<LogoOptions>
  useTransferOptions(vendorId?: number): Resource<TransferOptions>
  useAllLogos(currentPageQuery?: PageQuery): Resource<PagedResponse<BaseLogo>>
  useAllLogosForDesignInfinity(customerId: number, currentPageQuery?: PageQuery): UseInfiniteQueryResult<PagedResponse<BaseLogo>>
  useAllLogosInfinity(query: PageQuery): UseInfiniteQueryResult<PagedResponse<BaseLogo>>
  createLogo(logo: File, orgId: number): Promise<BaseLogo>
  createTextLogo(value: string, color: string, heightMm: number, font: string, orgId: number): Promise<BaseLogo>
  createNameLogo(color: string, heightMm: number, font: string, orgId: number): Promise<BaseLogo>
  createNumberLogo(color: string, heightMm: number, font: string, method: string, orgId: number): Promise<BaseLogo>
  createBaseLogo(customerId: number, title: string): Promise<BaseLogo>
  uploadFileToBaseLogo(logo: File, customerId: number, logoId: number): Promise<BaseLogo>
  update(orgId: number, logo: BaseLogo): Promise<BaseLogo>
  resize(orgId: number, logo: BaseLogo): Promise<BaseLogo>
  copy(orgId: number, logoId: number): Promise<BaseLogo>
  commit(logo: BaseLogo, commit: LogoCommit): Promise<BaseLogo>
  initiateApproval(logo: BaseLogo, approval: InitiateApproval): Promise<BaseLogo>
  sendApproval(logo: BaseLogo, email: string): Promise<BaseLogo>
  placeVerdict(logo: BaseLogo, verdict: Verdict): Promise<BaseLogo>
  delete(orgId: number, logoId: number): Promise<void>
  generateTextLogoPreview: (value: string, color: string, heightMM: number, font: string, orgId: number) => Promise<string>
  generateNumberLogoPreview: (color: string, heightMm: number, font: string, orgId: number) => Promise<string>
}

export function useLogoRepository(): LogoRepository {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  async function invalidateQueries() {
    await queryClient.refetchQueries({ queryKey: ['logos', ''], type: 'all', refetchPage: () => true })
  }

  function useOptions(): Resource<LogoOptions> {
    return useQuery(['logo-options'], () => apiClient.getLogoOptions().then(mapLogoOptions), {
      staleTime: 600000
    })
  }

  function useTransferOptions(vendorId?: number): Resource<TransferOptions> {
    return useQuery(['transfer-options'], () => apiClient.getTransferOptions(vendorId).then(mapTransferOptions))
  }

  function useLogo(orgId: number, logoId: number): Resource<BaseLogo> {
    return useQuery(['logos', logoId], () => apiClient.getLogo(orgId, logoId).then(mapToLogo))
  }

  function useAllLogos(query: PageQuery): Resource<PagedResponse<BaseLogo>> {
    // If a logo type was filtered, extend the route by a type parameter.
    const typeFilter = query.filterBy.find((it) => it.field === 'logoType')
    const queryCopy = {...query}
    queryCopy.filterBy = queryCopy.filterBy.filter((it) => it.field !== 'logoType')
    return useQuery(['logos', typeFilter?.value, toQueryParam(query)], () =>
      apiClient.getAllLogos(queryCopy, typeFilter?.value)
        .then((data) => ({ ...data, results: data.results.map(mapToLogo) })), {staleTime: 1})
  }

  function useAllLogosForDesignInfinity(customerId: number, query: PageQuery): UseInfiniteQueryResult<PagedResponse<BaseLogo>> {
    return useInfiniteQuery({
      queryKey: ['logos', customerId, 'inf', toQueryParam(query)],
      queryFn: ({ pageParam }) => {
        const copy: PageQuery = {
          page: {
            index: pageParam ?? query.page.index,
            size: query.page.size
          },
          filterBy: query.filterBy,
          sortBy: {
            field: query.sortBy.field,
            sortDirection: query.sortBy.sortDirection
          },
          searchTerm: query.searchTerm,
        }

        return apiClient.getAllLogosForDesign(customerId, copy)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.pageIndex === lastPage.pageCount) {
          return undefined
        }
        return lastPage.pageIndex + 1
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.pageIndex === 1) {
          return undefined
        }

        return firstPage.pageIndex - 1
      },
    })
  }

  function useAllLogosInfinity(query: PageQuery): UseInfiniteQueryResult<PagedResponse<BaseLogo>> {
    return useInfiniteQuery({
      queryKey: ['logos', 'inf', toQueryParam(query)],
      queryFn: ({ pageParam }) => {
        const copy: PageQuery = {
          page: {
            index: pageParam ?? query.page.index,
            size: query.page.size
          },
          filterBy: query.filterBy,
          sortBy: {
            field: query.sortBy.field,
            sortDirection: query.sortBy.sortDirection
          },
          searchTerm: query.searchTerm
        }

        return apiClient.getAllLogos(copy)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.pageIndex === lastPage.pageCount) {
          return undefined
        }
        return lastPage.pageIndex + 1
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.pageIndex === 1) {
          return undefined
        }

        return firstPage.pageIndex - 1
      },
    })
  }

  function deleteLogo(orgId: number, logoId: number): Promise<void> {
    return apiClient.deleteLogoById(orgId, logoId).then(async (res) => {
      await invalidateQueries()
      return res
    })
  }

  async function createImageLogo(logoFile: File, orgId: number): Promise<BaseLogo> {
    let lr = await apiClient.createAndUploadImageLogo(logoFile, orgId)
    await invalidateQueries()
    return mapToLogo(lr)
  }

  async function createTextLogo(value: string, color: string, heightMM: number, font: string, orgId: number): Promise<BaseLogo> {
    let lr = await apiClient.createTextLogo(orgId, {
      color: color,
      heightMm: heightMM,
      value: value,
      font: font
    })
    await invalidateQueries()
    return mapToLogo(lr)
  }

  async function createNameLogo(color: string, heightMM: number, font: string, orgId: number): Promise<BaseLogo> {
    let lr = await apiClient.createPlaceholderNameLogo(orgId, {
      color: color,
      heightMm: heightMM,
      font: font,
    })
    await invalidateQueries()
    return mapToLogo(lr)
  }

  async function createNumberLogo(color: string, heightMM: number, font: string, method: string, orgId: number): Promise<BaseLogo> {
    let lr = await apiClient.createPlaceholderNumberLogo(orgId, {
      color: color,
      heightMm: heightMM,
      font: font,
      method: method
    })
    await invalidateQueries()
    return mapToLogo(lr)
  }

  async function createBaseLogo(organizationId: number, title: string): Promise<BaseLogo> {
    // TODO Fix own logged in user's id.
    let lr = await apiClient.createImageLogo({ title, customerId: organizationId })
    await invalidateQueries()
    return mapToLogo(lr)
  }

  async function uploadFileToBaseLogo(logo: File, customerId: number, logoId: number): Promise<BaseLogo> {
    let lr = await apiClient.uploadFileImageLogo(logo, customerId, logoId)
    await invalidateQueries()
    return mapToLogo(lr)
  }

  async function changeColors(orgId: Organization['id'], logoId: BaseLogo['id'], colors: ChangeLogoColors): Promise<BaseLogo> {
    const lr = await apiClient.changeColors(orgId, logoId, colors)
    await invalidateQueries()
    return lr
  }

  async function changeTitle(orgId: Organization['id'], logoId: number, title: string): Promise<BaseLogo> {
    const lr = await apiClient.changeLogoTitle(orgId, logoId, title)
    await invalidateQueries()
    return lr
  }

  async function update(orgId: number, logo: BaseLogo): Promise<BaseLogo> {
    const lr = await apiClient.updateLogo(orgId, logo)
    invalidateQueries()
    return lr
  }

  async function resize(orgId: number, logo: BaseLogo): Promise<BaseLogo> {
    const lr = await apiClient.resizeLogo(orgId, logo)
    invalidateQueries()
    return lr
  }

  async function copy(orgId: number, logoId: number): Promise<BaseLogo> {
    const lr = await apiClient.copyLogo(orgId, logoId)
    await invalidateQueries()
    return lr
  }

  async function commit(logo: BaseLogo, logoCommit: LogoCommit): Promise<BaseLogo> {
    const lr = await apiClient.commitLogo(logo, logoCommit)
    await invalidateQueries()
    return lr
  }

  async function placeVerdict(logo: BaseLogo, verdict: Verdict): Promise<BaseLogo> {
    const lr = await apiClient.placeVerdictLogo(logo, verdict)
    await invalidateQueries()
    return lr
  }

  async function initiateApproval(logo: BaseLogo, approval: InitiateApproval): Promise<BaseLogo> {
    const lr = await apiClient.initiateApprovalLogo(logo, approval)
    await invalidateQueries()
    return lr
  }

  async function sendApproval(logo: BaseLogo, email: string): Promise<BaseLogo> {
    const lr = await apiClient.sendApprovalLogo(logo, email)
    await invalidateQueries()
    return lr
  }

  async function generateTextLogoPreview(value: string, color: string, heightMM: number, font: string, orgId: number): Promise<string> {
    return await apiClient.generateTextLogoPreview(orgId, {
      color: color,
      heightMm: heightMM,
      value: value,
      font: font
    })
  }

  async function generateNumberLogoPreview(color: string, heightMm: number, font: string, orgId: number): Promise<string> {
    return await apiClient.generateNumberLogoPreview(orgId, {
      color: color,
      heightMm: heightMm,
      font: font
    })
  }

  return {
    useOptions,
    useTransferOptions,
    useLogo,
    useAllLogos,
    useAllLogosForDesignInfinity,
    delete: deleteLogo,
    createLogo: createImageLogo,
    createTextLogo,
    createNameLogo,
    createNumberLogo,
    createBaseLogo,
    uploadFileToBaseLogo,
    changeColors,
    changeTitle,
    update,
    resize,
    copy,
    commit,
    useAllLogosInfinity,
    initiateApproval,
    sendApproval,
    placeVerdict,
    generateTextLogoPreview,
    generateNumberLogoPreview
  }
}