import { useQuery } from '@tanstack/react-query'
import { useApiClient } from '../clients/ApiClient'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { PagedResponse } from '../models/responses/pagedResponse'
import { Country } from '../../core/models/entities/Country'
import { Resource } from '../../core/models/wrappers/Resource'
import { mapToCountry } from '../models/mappers/mapCountry'

export interface CountryRepository {
  useAllCountries(query: PageQuery): Resource<PagedResponse<Country>>
}

export function useCountryRepository(): CountryRepository {
  const apiClient = useApiClient()

  function useAllCountries(query: PageQuery): Resource<PagedResponse<Country>> {
    return useQuery(['countries', toQueryParam(query)], () => apiClient.getAllCountries(query).then((data) => ({ ...data, results: data.results.map(mapToCountry) })), { staleTime: 600000 })
  }

  return {
    useAllCountries,
  }
}

