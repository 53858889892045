import { useEffect, useState } from 'react';
import { BaseLogo } from '../../../../core/models/entities/BaseLogo';
import { useOrderActionHandler } from '../hooks/useOrderActionsHandler';
import { CartItemLogo, QuantityRow } from '../models/CartItem';
import { LogoTemplate } from '../../../../core/models/entities/DesignTemplate';
import { useUploadDialog } from './UploadDialog';
import { LogoQuantitiesColumn, LogoQuantityRow } from './LogoQuantitiesColumn';
import { Stepper } from '../../../components/Buttons/Stepper';

interface LogoQuantitySelectorProps {
    logo: BaseLogo
    orderLine?: CartItemLogo
}

const getEmptyQuantityRow = (label: string = '', quantity: number = 0): QuantityRow => ({ label, quantity })
const getEmptyNumericQuantityColumn = (existing: QuantityRow[]) =>
    new Array(10).fill(0).map((_, i) => getEmptyQuantityRow(i.toString(), existing.find((e) => e.label === i.toString())?.quantity ?? 0))

export function LogoQuantitySelector({ logo, orderLine }: LogoQuantitySelectorProps) {
    const { handleLogoSetQuantities } = useOrderActionHandler()

    const isNumeric = logo.logoType === 'NumberLogo'
    const numberOrName = isNumeric ? 'Number' : 'Name'
    const hasCustomizableQuantity = ['NumberLogo', 'NameLogo'].includes(logo.logoType)

    const toDelivery = orderLine?.toDelivery ?? 0
    const toStorage = orderLine?.toStorage ?? 0
    const [logosToStorage, setLogosToStorage] = useState<number>(toStorage)
    const [logosToDelivery, setLogosToDelivery] = useState<number>(toDelivery)

    const initialDeliveryRow: QuantityRow = {
        label: orderLine?.value?.toString() ?? '',
        quantity: orderLine?.toDelivery ?? 0
    }
    const [deliveryRows, setDeliveryRows] = useState<QuantityRow[]>(orderLine?.deliveryQuantities ?? [initialDeliveryRow])
    const [storageRows, setStorageRows] = useState<QuantityRow[]>(orderLine?.storageQuantities ?? [])

    useEffect(() => {
        if (!orderLine) return
        setLogosToDelivery(orderLine?.toDelivery ?? 0)
        setLogosToStorage(orderLine?.toStorage ?? 0)
        if (hasCustomizableQuantity) {
            if (logo.logoType == 'NameLogo') {
                setDeliveryRows(orderLine?.deliveryQuantities ?? [initialDeliveryRow])
                setStorageRows(orderLine?.storageQuantities ?? [])
            }
            else if (logo.logoType == 'NumberLogo') {
                setDeliveryRows(getEmptyNumericQuantityColumn(orderLine?.deliveryQuantities))
                setStorageRows(getEmptyNumericQuantityColumn(orderLine?.storageQuantities))
            }
        }
    }, [orderLine?.id])

    useEffect(() => {
        // if (hasCustomizableQuantity) return
        // Two different approaches to handling quantities on different levels dictate 
        //  that we enable the appropriate quantity feature depending on the type of logo.
        handleLogoSetQuantities(logo, logosToDelivery, logosToStorage)
        if (orderLine == null) return
        orderLine.deliveryQuantities = deliveryRows
        orderLine.storageQuantities = storageRows
    }, [logosToDelivery, logosToStorage])

    const { handleUploadLogoTemplate, updateLogoOrderLine } = useOrderActionHandler()

    const getUpdateQuantityState = (setQuantityState: (quantity: number) => void) => (value: number) => setQuantityState(value)
    const updateRowGeneric = (
        index: number,
        rowState: QuantityRow[],
        setRowState: (rows: QuantityRow[]) => void,
        update: (row: QuantityRow) => void,
    ) => {
        const row = rowState[index]
        if (!row) return
        update(row)
        const updatedRows = [...rowState]
        updatedRows[index] = row
        // // Remove all rows with 0 or less quantity.
        // const filteredRows = updatedRows.filter((row) => row.quantity > 0)
        setRowState(updatedRows)
        return updatedRows
    }

    const updateDeliveryRow = (index: number, update: (row: QuantityRow) => void) => {
        const rows = updateRowGeneric(index, deliveryRows, setDeliveryRows, update)
        updateLogoOrderLine(logo, rows, undefined)
    }

    const updateStorageRow = (index: number, update: (row: QuantityRow) => void) => {
        const rows = updateRowGeneric(index, storageRows, setStorageRows, update)
        updateLogoOrderLine(logo, undefined, rows)
    }

    const getAddRow = (
        rowState: QuantityRow[],
        setRowState: (rows: QuantityRow[]) => void,
    ) => () => {
        setRowState([
            ...rowState,
            getEmptyQuantityRow(),
        ])
    }

    const addStorageRow = getAddRow(storageRows, setStorageRows)
    const addDeliveryRow = getAddRow(deliveryRows, setDeliveryRows)

    const mapLogoTemplate = (template: LogoTemplate, setRowState: (rows: QuantityRow[]) => void) => {
        if (!Array.isArray(template.logos)) return
        else {
            const logos = template.logos.map((item) => ({
                label: item.value,
                quantity: item.quantity
            }))
            setRowState(logos)
            return logos
        }
    }

    const orgId = orderLine?.baseLogo.customer.id ?? logo?.customer?.id
    const { component: deliveryDialog, open: openDeliveryDialog } = useUploadDialog({
        orgId,
        typeString: 'logos',
        onUpload: async (orgId, file) => {
            const output: LogoTemplate = await handleUploadLogoTemplate(orgId, file)
            const logos = mapLogoTemplate(output, setDeliveryRows)
            const baseLogo = orderLine?.baseLogo
            if (!baseLogo) return
            updateLogoOrderLine(baseLogo, logos)
        },
    })

    const { component: storageDialog, open: openStorageDialog } = useUploadDialog({
        orgId,
        typeString: 'logos',
        onUpload: async (orgId, file) => {
            const output = await handleUploadLogoTemplate(orgId, file)
            const logos = mapLogoTemplate(output, setStorageRows)
            const baseLogo = orderLine?.baseLogo
            if (!baseLogo) return
            updateLogoOrderLine(baseLogo, undefined, logos)
        },
    })

    return <>
        {hasCustomizableQuantity && <div>
            <span className="block pt-1 text-xs mb-2">
                <p className="block text-base font-bold uppercase">To Delivery</p>
            </span>
            <LogoQuantitiesColumn
                title={numberOrName}
                actionLabel={'Add ' + numberOrName}
                onAction={addDeliveryRow}
                onUpload={openDeliveryDialog}
            >
                {deliveryRows.map((item, index) =>
                    <LogoQuantityRow
                        key={index}
                        isNumeric={isNumeric}
                        label={item.label}
                        onLabelChange={(label) => updateDeliveryRow(index, (row) => {
                            row.label = label
                        })}
                        quantity={item.quantity}
                        onQuantityChange={(q) => updateDeliveryRow(index, (row) => {
                            row.quantity = q
                        })}
                    />
                )}
            </LogoQuantitiesColumn>
        </div>}

        {hasCustomizableQuantity && isNumeric && <div>
            <span className="block pt-1 text-xs mb-2">
                <p className="block text-base font-bold uppercase">To Storage</p>
            </span>
            <LogoQuantitiesColumn
                title={numberOrName}
                actionLabel={'Add ' + numberOrName}
                onAction={addStorageRow}
                onUpload={openStorageDialog}
            >
                {storageRows.map((item, index) =>
                    <LogoQuantityRow
                        key={index}
                        isNumeric={isNumeric}
                        label={item.label}
                        onLabelChange={(label) => updateStorageRow(index, (row) => {
                            row.label = label
                        })}
                        quantity={item.quantity}
                        onQuantityChange={(q) => updateStorageRow(index, (row) => {
                            row.quantity = q
                        })}
                    />
                )}
            </LogoQuantitiesColumn>
        </div>}

        {!hasCustomizableQuantity && <div>
            <div className={'block text-base font-bold uppercase'}>QTY</div>
            <span className='block pt-1 text-xs'>
                <p className={'uppercase inline pr-1'}>Logos to storage</p>
            </span>
            <Stepper onValueChange={getUpdateQuantityState(setLogosToStorage)} min={0} value={logosToStorage} />
            <span className='block pt-1 text-xs'>
                <p className={'uppercase inline pr-1'}>Logos to delivery</p>
            </span>
            <Stepper onValueChange={getUpdateQuantityState(setLogosToDelivery)} min={0} value={logosToDelivery} />
        </div>}
        {deliveryDialog}
        {storageDialog}
    </>
}