import { Fragment, useState } from 'react'

import { BoxContent } from '../../../../components/BoxView/BoxContent'
import { InvertedPanel } from '../../../../components/BoxView/InvertedPanel'
import { BoxHeader2 } from '../../../../components/BoxView/BoxHeader2'
import { DebuggablePoint, useDesignContext } from '../../contexts/design.context'
import { ImageContainer } from '../../../../components/image/ImageContainer'
import { LogoPlacerHeaderBarWrapper } from '../../wrappers/logo-placer-header-bar.wrapper'
import { ViewSelectorWrapper } from '../../wrappers/view-selector.wrapper'
import { Marker2Wrapper } from '../../wrappers/marker.wrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Organization } from '../../../../../core/models/entities/Organization'
import { InputLabel } from '@mui/material'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { CustomInput } from '../../../../components/Input/CustomInput'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'
import { Role } from '../../../../../core/models/entities/Role'
import { useOrganizationRepository } from '../../../../../data/repositories/useOrganizationRepository'
import { getAllQuery } from '../../../logo-editor/logo-editor.screen'
import { useProfileContext } from '../../../../../contexts/ProfileContext'
import { InputWrapper } from '../../../../components/Input/InputWrapper'
import { CustomAutoComplete } from '../../../../components/Input/CustomAutoComplete'
import { Marker } from '../../models/marker.model'

export function LogoPlacer() {
  const {
    design,
    selectedView,
    scaledViewInfo,
    containerRef,
    imageRef,
    onImageRefSet,
    scaledLogosOfDesign: scaledLogosOfDesign,
    placementErrorMessage,
    // Debugging values
    debug,
    lastCoverageChecked,
    debugPoints
  } = useDesignContext()

  const {
    // isLoading: customerDataLoading,
    // isSuccess: customerDataSuccess,
    // isError: customerDataError,
    data: customerData
  } = useOrganizationRepository().useAllCustomers(getAllQuery)
  const customers = customerData?.results ?? []

  const { role, profile } = useProfileContext()
  const { selectedLogo } = useDesignContext()
  const logoHeaderBar = selectedLogo && <LogoPlacerHeaderBarWrapper />

  // Simple functions to update the immediate object: setTitle, updateCustomer.
  // This saves having to thread all data updates through the repository states and hooks.
  // The trade off for this quick implementation is the absence of further architectural integrity.
  const [title, setTitle] = useState(design.title);
  const updateTitle = (title: string) => {
    setTitle(title)
    design.title = title
  }
  const [selectedCustomer, setSelectedCustomer] = useState<Organization | null>(design.customer)
  const updateCustomer = (organization?: Organization | null) => {
    if (!organization) return
    setSelectedCustomer(organization)
    design.customerId = organization.id ?? design.customerId ?? profile?.userOrganizationInformations.organizationId ?? 0
  }
  //

  return <div>
    <InvertedPanel className={'!mx-0 !py-0 !px-0 flex justify-center rounded-tr'}>
      <BoxHeader2 className={'!mx-0 !py-3 justify-between items-center'}>
        <div className={'flex h-7 space-x-2'}>
          {logoHeaderBar}
        </div>
      </BoxHeader2>
    </InvertedPanel>
    <InvertedPanel className={'!mx-0 !py-0 !px-0 flex justify-center rounded-tr'}>
      <div className={'bg-gray-100 border-t border-b py-2 px-2 w-full'}>
        <StrucRow>
          <StrucCol size={6} className={'px-2'}>
            <InputWrapper label="Title">
              <CustomInput className={'!bg-white h-100'} value={title} maxLength={30}
                onChange={e => updateTitle(e.target.value)} placeholder='enter design title' />
            </InputWrapper>
          </StrucCol>
          <StrucCol size={6} className={'px-2'}>
            <InputLabel>Customer</InputLabel>
            <CustomAutoComplete<Organization>
              options={customers}
              getOptionKey={(org) => '' + org.id}
              displayOption={(org: Organization) => org.name}
              placeholder='search for customer'
              key={selectedCustomer?.id}
              onChange={updateCustomer}
              value={selectedCustomer ?? null}
              disabled={role === Role.EndUser}
            />
          </StrucCol>
        </StrucRow>
      </div>

    </InvertedPanel>
    <BoxContent className={'relative py-4 justify-center items-center !min-h-[60vh]'}>
      {placementErrorMessage && <div className={'absolute z-10 top-0 left-0 flex p-1.5 space-x-1'}>
        <FontAwesomeIcon className={'text-[20px] text-status-failed-1 p-1.5'} icon={faCircleExclamation} />
        <div className={'bg-[#596675] rounded text-xs text-gray-100 px-2 py-1 max-w-[180px] text-center'}>
          {placementErrorMessage}
        </div>
      </div>}
      <div className={'relative flex-1'} ref={containerRef}>
        {scaledViewInfo && <>
          <ImageContainer
            ref={imageRef}
            onRefSet={onImageRefSet}
            url={debug ? selectedView.maskUrl : selectedView.imageUrl}
            style={{ width: scaledViewInfo.width, height: scaledViewInfo.height }}
            className='relative bg-cover shrink-0'
          />
          <div
            className={'top-0 absolute h-full w-full'}
            style={{ width: scaledViewInfo.width, height: scaledViewInfo.height }}
          >{scaledLogosOfDesign.filter(x => x.viewId === selectedView.id).map((m) =>
            <Fragment key={m.id}>
              <Marker2Wrapper key={m.id} marker={m} />
              {debug && <MarkerDebugging marker={m} coverage={lastCoverageChecked} points={debugPoints} />}
            </Fragment>)}
          </div>
        </>}
      </div>
    </BoxContent>
    <ViewSelectorWrapper />
  </div>
}


/**
 * Code below is used to facilitate visual debugging of the logo placer. 
 */

//#region

interface MarkerDebuggingProps { marker: Marker, coverage?: Marker, points: DebuggablePoint[] }
const MarkerDebugging = ({ marker, coverage, points }: MarkerDebuggingProps) => (<>
  <DebugMarker key={'DebugWatermark' + marker.id} marker={marker} color="#000000" />
  {coverage?.id === marker.id &&
    <DebugMarker key={'DebugCoverage' + marker.id} marker={coverage} color="#FFFFFF" />}
  {points.map((point, i) =>
    <DebugPoint key={i} point={point} />)}
</>)

const DEFAULT_COLOR = '#FFFFFF'
const DEFAULT_OPACITY = 0.5
interface DebugMarkerProps { marker: Marker, color?: string, opacity?: number }
const DebugMarker = ({ marker, color, opacity }: DebugMarkerProps) => (
  <div
    style={{
      position: 'absolute',
      top: marker.y,
      left: marker.x,
      width: marker.widthPx,
      height: marker.heightPx,
      backgroundColor: color ?? DEFAULT_COLOR,
      transform: `rotate(${marker.rotationRad}rad)`,
      opacity: opacity ?? DEFAULT_OPACITY,
      borderRadius: 5
    }} />
)

const DEFAULT_POINT_SIZE = 10
interface DebugPointProps { point: DebuggablePoint }
const DebugPoint = ({ point: { x, y, color, size } }: DebugPointProps) => (
  <div
    data-x={x}
    data-y={y}
    style={{
      position: 'absolute',
      zIndex: 10,
      userSelect: 'none',
      top: y,
      left: x,
      width: size ?? DEFAULT_POINT_SIZE,
      height: size ?? DEFAULT_POINT_SIZE,
      backgroundColor: color ?? DEFAULT_COLOR,
      transform: `translate(-50%, -50%)`,
      borderRadius: '100%'
    }}
  />
)

//#endregion