import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext';
import { BaseLogo, GetProductMinQuantity, getLogoColors, isDigital } from '../../../../core/models/entities/BaseLogo';
import { DigitalColorElement } from '../../../components/Logo/DigitalColorElement';
import { PriceLine } from '../../../components/Logo/LogoPrices';
import { VectorColorElement } from '../../../components/Logo/VectorColorElement';
import { CartItemLogo } from '../models/CartItem'
import { useCartContext } from '../../../../contexts/CartContext';
import { LogoQuantitySelector } from './LogoQuantitySelector';

export function ProductDetailsLogo({ orderLine, baseLogo }: { orderLine?: CartItemLogo, baseLogo?: BaseLogo }) {
  const { cartSummary } = useCartContext()
  const logo = orderLine?.baseLogo ?? baseLogo
  if (!logo) return null

  const size = `${logo.widthMm}x${logo.heightMm}`;
  const type = logo.type;
  const method = logo.transferMethod.key;
  const storage = logo.inStorage
  const prices = logo.product?.prices.sort((a, b) => a.minQuantity - b.minQuantity) ?? []

  const isNumeric = logo.logoType === 'NumberLogo'
  const toDelivery = orderLine?.toDelivery ?? 0
  const toStorage = orderLine?.toStorage ?? 0
  let colorsElements = getColorsElements(logo);
  let highlightedMinQuantity: number | undefined = GetProductMinQuantity(prices, toDelivery + toStorage)

  const fees = cartSummary?.prices?.filter((p) => p.relatedTo == logo.product?.sku) ?? []
  return <>
    <div className={"grid grid-cols-2 gap-4"}>
      <div className={"grid grid-cols-2 gap-4"}>
        <div className={'block text-base font-bold uppercase'}>Details</div>

        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Logo Name</p>
          <p className={'uppercase inline'}>{orderLine?.title ?? baseLogo?.title}</p>
        </span>

        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Size</p>
          <p className={'uppercase inline'}>{size}</p>
        </span>

        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Type</p>
          <p className={'uppercase inline'}>{type}</p>
        </span>

        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Method</p>
          <p className={'uppercase inline'}>{method}</p>
        </span>

        <span className='block pt-1 text-xs flex flex-row'>
          <p className={'font-bold capitalize inline pr-1'}>Colors</p>
          {colorsElements}
        </span>

        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>In Storage</p>
          <p className={'uppercase inline'}>{storage}</p>
        </span>
      </div>

      <div>
        <div className={'block text-base font-bold uppercase'}>Prices</div>
        <div className={"grid grid-rows-5 grid-flow-col gap-0"}>
          {prices.map((p, i) => (
            <span key={i} className={`pt-1 text-xs capitalize inline pr-1 ${highlightedMinQuantity == p.minQuantity ? "font-bold" : ""}`}>
              {PriceLine({ price: p })}
            </span>
          ))}
        </div>
      </div>

      <LogoQuantitySelector
        logo={logo}
        orderLine={orderLine}
      />

      {!isNumeric && fees.length > 0 && <div>
        <div className={'block text-base font-bold uppercase'}>EXTRA FEES</div>
        <ol>{fees.map((fee, i) =>
          <li key={`${fee.productNo}_${i}`} >
            {fee.productName} ({fee.price} DKK x {fee.toDelivery})
          </li>)}
        </ol>
      </div>}
    </div>
  </>
}

export function getColorsElements(logo: BaseLogo) {
  const { colors: colorOptions, colorsEmbroidery } = useLogoOptionsContext()
  const colors = getLogoColors(logo, colorOptions, colorsEmbroidery)

  const colorsElements = isDigital(logo) ? <DigitalColorElement /> :
    colors.map(co => <VectorColorElement key={co.id} showName={false} color={co} />)

  return (
    <div className='flex flex-row'>
      {colorsElements}
    </div>
  )
}

export function getMethodDisplay(logo: BaseLogo) {
  const transferMethods = [(logo.transferMethod.method1.toLocaleLowerCase() ?? 'N/A'), logo.transferMethod.method2.toLocaleLowerCase(), logo.transferMethod.method3.toLocaleLowerCase()].filter(x => x?.length > 0)
  return <span className="capitalize">{transferMethods.join(", ")}</span>
}
