import {
  BaseLogo,
  getColors,
  getLogoState,
  isDigital,
  LogoState,
  LogoType
} from '../../../../core/models/entities/BaseLogo'
import { createRef, Dispatch, JSX, SetStateAction, useEffect, useState } from 'react'
import IconButton from '../../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faList, faScaleUnbalanced, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faNoteSticky, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { ColumnTypography, TypographyR } from '../../../components/Typographies/SectionHeaderTypography'
import { formatDate } from '../../../../utils/functions/DateUtil'
import { LogoStatusElement } from '../../../components/Logo/LogoStatusElement'
import { LogoPrices, SimplePriceLine } from '../../../components/Logo/LogoPrices'
import { LogoTitleHeader } from '../../../components/Logo/LogoTitleHeader'
import { LogoStorage } from '../../../components/Logo/LogoStorage'
import { LogoTransferMethod } from '../../../components/Logo/LogoTransferMethod'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { DigitalColorElement } from '../../../components/Logo/DigitalColorElement'
import { VectorColorElement } from '../../../components/Logo/VectorColorElement'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { ApprovalVoting, Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { LogoDimensions } from '../../../components/Logo/LogoDimensions'
import { Role } from '../../../../core/models/entities/Role'
import LoadingElement from '../../../components/loading-element/loading-element'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { BoxModalContent } from '../../../components/BoxView/BoxModalContent'
import { Stepper } from '../../../components/Buttons/Stepper'
import { EditIcon } from '../../../../resources/icons/Icons'
import { useLogoActionHandler } from '../hooks/useLogoActionsHandler'
import { CustomInput } from '../../../components/Input/CustomInput'
import { useTranslation } from 'react-i18next'

interface LogoDetails_VM {
  logo: BaseLogo
  onPlaceVerdict: (logo: BaseLogo, verdict: Verdict) => void
  onAddToCart: (logo: BaseLogo, quantity: number) => void
  onEditLogo: (logo: BaseLogo) => void
  onTitleChange: (title: string) => void
  onDeleteLogo: (logo: BaseLogo) => void
  onCopyLogo: (logo: BaseLogo) => void
  onViewDesigns: (logo: BaseLogo) => void
  onShowRejectionNote: (logo: BaseLogo) => void
  onSkipApproval: (logo: BaseLogo) => void
  isLoading: boolean
  onRejectAndDelete: (logo: BaseLogo, note?: string) => Promise<void>
}

export function LogoDetails(props: LogoDetails_VM) {
  const [orderQuantity, setOrderQuantity] = useState<number>(10)
  const { handleUpdateLogo } = useLogoActionHandler()
  const { t } = useTranslation()
  const { colors: colorOptions } = useLogoOptionsContext()
  const { role } = useProfileContext()

  const logo = props.logo

  function handleAddToCart() {
    props.onAddToCart(logo, orderQuantity)
  }

  const colors = getColors(logo)
  const pantoneColors = colorOptions.filter((co) => colors.find((c) => c === co.id))
  let colorsElements = isDigital(logo) ? (
    <DigitalColorElement />
  ) : (
    pantoneColors.map((co) => <VectorColorElement key={co.id} showName={true} color={co} />)
  )

  const additionalFees = logo.product?.additionalFees ?? []
  additionalFees.sort((a, b) => a.quantity - b.quantity)

  const calculateQuantityRange = (current: number, next: number) =>
    current + (!isNaN(next) && next > 0 && next > current ? ' - ' + (next - 1) : '+')

  return (
    <>
      {props.isLoading && <LoadingElement transparent />}
      <div className={''}>
        <BoxHeader2>
          <LogoTitleHeader logo={logo} />
        </BoxHeader2>
        <BoxModalContent className={'w-[1000px] min-h-0 pb-8'}>
          <StrucRow>
            <StrucCol size={8} offset={2} className={'py-14'}>
              <LogoImage
                logo={logo}
                useHighRes={true}
                className={'bg-white object-contain rounded max-h-[30rem]'}
              />
            </StrucCol>
          </StrucRow>

          <StrucRow>
            <StrucCol className={'!justify-start space-y-2'}>
              <StrucRow>
                <StrucCol size={3}>
                  <ColumnTypography>DETAILS</ColumnTypography>
                </StrucCol>
                <StrucCol size={2}>
                  <ColumnTypography>COLORS</ColumnTypography>
                </StrucCol>
                <StrucCol size={1.5}>
                  <ColumnTypography>STATUS</ColumnTypography>
                </StrucCol>
                <StrucCol size={1.5}>
                  <ColumnTypography>PRICE</ColumnTypography>
                </StrucCol>
                <StrucCol size={1.5}>
                  <ColumnTypography>IN STORAGE</ColumnTypography>
                </StrucCol>
                <StrucCol size={1.5}>
                  <ColumnTypography>EXTRA FEES</ColumnTypography>
                </StrucCol>
              </StrucRow>
              <StrucRow>
                <StrucCol
                  className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis space-y-2'}
                  size={3}>
                  <EditableLogoTitle logo={logo} onTitleChange={props.onTitleChange} />
                  <TypographyR>
                    <b>Size </b>
                    {LogoDimensions({ logo })}
                  </TypographyR>
                  <TypographyR>
                    <b>Method </b>
                    <LogoTransferMethod logo={logo} />
                  </TypographyR>
                  <TypographyR>
                    <b>Customer </b>
                    {logo.customer.name}
                  </TypographyR>
                  {/*<TypographyR>
                <b>Customer Branch </b>
                {logo.customer.name} ??
              </TypographyR>*/}
                  <TypographyR>
                    <b>Creation Date </b>
                    {formatDate(logo.createdAtUtc)}
                  </TypographyR>
                </StrucCol>
                <StrucCol className={'!justify-start'} size={2}>
                  <div className="flex flex-wrap flex-col ">{colorsElements}</div>
                </StrucCol>
                <StrucCol className={'!justify-start'} size={1.5}>
                  <LogoStatusElement logo={logo} />
                </StrucCol>
                <StrucCol className={'!justify-start'} size={1.5}>
                  <LogoPrices logo={logo} nLines={6} />
                </StrucCol>
                <StrucCol className={'!justify-start'} size={1.5}>
                  <LogoStorage logo={logo} />
                </StrucCol>
                <StrucCol className={'!justify-start'} size={2}>
                  {additionalFees.map((fee, i) => (<div key={i}>
                    <div>{fee.name}</div>
                    {fee.prices.map((p, j) =>
                      <SimplePriceLine
                        key={j}
                        price={p.adjustedPrice}
                        quantity={fee.prices.length <= 1 ? '' : `${calculateQuantityRange(p.minQuantity, fee.prices[j + 1]?.minQuantity) + ' ' + t('stk')}`}
                        currency={p.currency}
                      />
                    )}
                  </div>))}
                </StrucCol>
              </StrucRow>
            </StrucCol>
            <StrucCol size={1.7} className={'!justify-start space-y-3'}>
              <ButtonLayout {...props} handleAddToCart={handleAddToCart} quantity={orderQuantity} setOrderQuantity={setOrderQuantity} />
            </StrucCol>
          </StrucRow>
        </BoxModalContent>
      </div>
    </>
  )
}

function EditableLogoTitle({ logo, onTitleChange }: { logo: BaseLogo, onTitleChange: (title: string) => void }) {
  const [title, setTitle] = useState(logo?.title ?? '')
  const [editable, setEditable] = useState(false)

  const toggle = () => {
    if (editable) onTitleChange(title)
    setEditable(!editable)
  }

  return (
    <TypographyR className="editable-title-field">
      <b>Logo Name </b> {editable ?
        <input
          className="editable-title-field__input"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        : <span className="editable-title-field__label">{logo.title}</span>}
      <EditIcon onClick={toggle} />
    </TypographyR>
  )
}

interface ButtonLayoutProps extends LogoDetails_VM { role?: Role, quantity: number, handleAddToCart: () => void, setOrderQuantity: Dispatch<SetStateAction<number>> }
function ButtonLayout(props: ButtonLayoutProps): JSX.Element {

  const state = getLogoState(props.logo)
  switch (state) {
    case LogoState.Approved:
      return <ButtonLayoutStandard {...props} />
    case LogoState.Pending:
      return <ButtonLayoutPending {...props} />
    case LogoState.Rejected:
      return <ButtonLayoutRejected {...props} />
    case LogoState.Draft:
      return <ButtonLayoutDraft {...props} />
    case LogoState.Undefined:
      return <span>Undefined</span>
  }
}

function ButtonLayoutPending({ role, logo, onPlaceVerdict, onRejectAndDelete, onSkipApproval }: {
  role?: Role,
  logo: BaseLogo,
  onPlaceVerdict: (logo: BaseLogo, verdict: Verdict) => void
  onRejectAndDelete: (logo: BaseLogo, note?: string) => void
  onSkipApproval: (logo: BaseLogo) => void
}) {
  return (
    <>
      <ApprovalVoting
        placeVerdict={(verdict) => onPlaceVerdict(logo, verdict)}
        approvalGroup={logo.approval!}
        rejectDelete={(reason) => onRejectAndDelete(logo, reason)}
      />
      {(role === Role.Admin || role === Role.Vendor) && <SkipApprovalLogoButton logo={logo} onSkipApproval={onSkipApproval} />}
    </>
  )
}

function ButtonLayoutStandard({ role, logo, quantity, handleAddToCart, setOrderQuantity, onCopyLogo, onShowRejectionNote, onViewDesigns, onDeleteLogo }: {
  role?: Role,
  logo: BaseLogo,
  quantity: number,
  handleAddToCart: () => void
  setOrderQuantity: Dispatch<SetStateAction<number>>,
  onCopyLogo: (logo: BaseLogo) => void
  onShowRejectionNote: (logo: BaseLogo) => void
  onViewDesigns: (logo: BaseLogo) => void
  onDeleteLogo: (logo: BaseLogo) => void
}) {
  return <>
    <AddToOrderButton role={role} quantity={quantity} setOrderQuantity={setOrderQuantity} onClick={handleAddToCart} />
    <CopyLogoButton logo={logo} onCopyLogo={onCopyLogo} />
    <SeeNoteButton logo={logo} onShowRejectionNote={onShowRejectionNote} />
    <ViewDesign logo={logo} onViewDesigns={onViewDesigns} />
    <Delete logo={logo} onDeleteLogo={onDeleteLogo} />
  </>
}

function ButtonLayoutRejected({ role, logo, onEditLogo, onCopyLogo, onShowRejectionNote, onViewDesigns, onDeleteLogo, onSkipApproval }: {
  role?: Role,
  logo: BaseLogo,
  setOrderQuantity: Dispatch<SetStateAction<number>>,
  onEditLogo: (logo: BaseLogo) => void
  onCopyLogo: (logo: BaseLogo) => void
  onShowRejectionNote: (logo: BaseLogo) => void
  onViewDesigns: (logo: BaseLogo) => void
  onDeleteLogo: (logo: BaseLogo) => void
  onSkipApproval: (logo: BaseLogo) => void
}) {
  return <>
    {(role === Role.Admin || role === Role.Vendor) && <SkipApprovalLogoButton logo={logo} onSkipApproval={onSkipApproval} />}
    <CopyLogoButton logo={logo} onCopyLogo={onCopyLogo} />
    <SeeNoteButton logo={logo} onShowRejectionNote={onShowRejectionNote} />
    <EditLogoButton logo={logo} onEditLogo={onEditLogo} />
    <ViewDesign logo={logo} onViewDesigns={onViewDesigns} />
    <Delete logo={logo} onDeleteLogo={onDeleteLogo} />
  </>
}

function ButtonLayoutDraft({ logo, onEditLogo, onViewDesigns }: {
  logo: BaseLogo,
  onViewDesigns: (logo: BaseLogo) => void,
  onEditLogo: (logo: BaseLogo) => void
}) {
  return <>
    <EditLogoButton logo={logo} onEditLogo={onEditLogo} />
    <ViewDesign logo={logo} onViewDesigns={onViewDesigns} />
  </>
}

function AddToOrderButton({ role, quantity, setOrderQuantity, onClick }: {
  role?: Role,
  quantity: number,
  setOrderQuantity: Dispatch<SetStateAction<number>>,
  onClick: () => void
}) {
  if (role === Role.Admin) {
    return <></>
  }

  return (
    <>
      <Stepper onValueChange={setOrderQuantity} min={0} value={quantity} />
      <IconButton
        key={'add-order'}
        onClick={onClick}
        variant="success"
        disabled={quantity <= 0}
        icon={<FontAwesomeIcon icon={faCartPlus} />}>
        ADD TO ORDER
      </IconButton>
    </>
  )
}

function ViewDesign({ logo, onViewDesigns }: { logo: BaseLogo, onViewDesigns: (logo: BaseLogo) => void }) {
  if (logo.designCount === 0) {
    return <></>
  }

  return <IconButton disabled={logo.designCount === 0} key={'view-designs'} onClick={() => onViewDesigns(logo)} className={logo.designCount > 0 ? 'iconButtonHoverGreen' : ''}
    icon={<FontAwesomeIcon icon={faList} />}>VIEW DESIGNS</IconButton>
}

function CopyLogoButton({ logo, onCopyLogo }: { logo: BaseLogo, onCopyLogo: (logo: BaseLogo) => void }) {
  if (logo.logoType !== LogoType.ImageLogo) return <></>

  return (
    <IconButton
      key={'copy-logo'}
      onClick={() => onCopyLogo(logo)}
      icon={<FontAwesomeIcon icon={faPenToSquare} />}>
      CLONE LOGO
    </IconButton>
  )
}

function EditLogoButton({ logo, onEditLogo }: { logo: BaseLogo, onEditLogo: (logo: BaseLogo) => void }) {
  return (
    <IconButton
      key={'edit-logo'}
      onClick={() => onEditLogo(logo)}
      variant="success"
      icon={<FontAwesomeIcon icon={faPenToSquare} />}>
      EDIT LOGO
    </IconButton>
  )
}

function SkipApprovalLogoButton({ logo, onSkipApproval }: { logo: BaseLogo, onSkipApproval: (logo: BaseLogo) => void }) {
  return (
    <IconButton
      key={'skip-logo'}
      onClick={() => onSkipApproval(logo)}
      variant="success"
      icon={<FontAwesomeIcon icon={faPenToSquare} />}>
      SKIP APPROVAL
    </IconButton>
  )
}

function SeeNoteButton({ logo, onShowRejectionNote }: { logo: BaseLogo, onShowRejectionNote: (logo: BaseLogo) => void }) {
  return (
    <IconButton
      key={'see-note'}
      onClick={() => onShowRejectionNote(logo)}
      icon={<FontAwesomeIcon icon={faNoteSticky} />}>
      SEE NOTE
    </IconButton>
  )
}

function Delete({ logo, onDeleteLogo }: { logo: BaseLogo, onDeleteLogo: (logo: BaseLogo) => void }) {
  return (
    <IconButton
      key={'new-logo'}
      onClick={() => onDeleteLogo(logo)}
      variant="error"
      icon={<FontAwesomeIcon icon={faTrash} />}>
      DELETE LOGO
    </IconButton>
  )
}