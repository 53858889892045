import { Order } from '../../../../core/models/entities/Order'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { useOrderActionHandler } from '../../order-create/hooks/useOrderActionsHandler'
import LoadingElement from '../../../components/loading-element/loading-element'
import { OrderLineList } from '../../order-details/subcomponents/OrderLineList'
import { PriceTotal } from '../../order-details/subcomponents/PriceTotal'
import { OrderForm } from '../../order-confirmation/subcomponents/OrderDeliveryForm'
import { useOrderDetailsViewModel } from '../../order-details/useOrderDetailsViewModel'

export function OrderDetails({ order, onClose }: {
  order: Order
  onShowRejectionNote: (order: Order) => void
  onClose: () => void
}) {

  const actionHandler = useOrderActionHandler()
  const vm = useOrderDetailsViewModel(order);

  return <>
    {actionHandler.isLoading && <LoadingElement transparent />}
    <BoxHeader2>
      <OrderTitleHeader order={order} />
    </BoxHeader2>
    <BoxContent className={'w-[1200px] min-h-0 pb-8'}>
      <div className={'h-full w-full flex flex-1 flex-col'}>
        <OrderLineList orderlines={vm.orderlines} />
        <PriceTotal subTotal={vm.subTotal} vat={vm.vat} totalAmount={vm.totalAmount} />
      </div>
      <OrderForm form={vm.form} readonly={true} onFormChange={vm.handleOrderFormUpdate} deliveryTypeOptionList={vm.deliveryTypeGroupList} packagingOptionList={vm.packagingGroupList} />
      <StrucRow>
        <StrucCol size={8} className={'space-y-4'}>
          <StrucRow>
            <div className={'w-full flex justify-between pt-5'}>
              <SecondaryButton onClick={onClose}>Close</SecondaryButton>
              <div className={'space-x-4 flex'}>
                {/* {buttonBar} */}
              </div>
            </div>
          </StrucRow>
        </StrucCol>
        <StrucCol size={4}>
        </StrucCol>
      </StrucRow>
    </BoxContent>
  </>
}

function OrderTitleHeader({ order }: { order: Order, }) {
  return (
    <div className={'flex items-center space-x-4'}>
      <BoxHeaderTypography>
        Order - <span className={'font-medium normal-case'}>{order.orderNumber ?? ''}</span>
      </BoxHeaderTypography>
      {/*<ApprovalStatusHeader status={status} />*/}
    </div>
  )
}
