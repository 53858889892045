import { useEffect, useState } from 'react';
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext';
import { BaseLogo, GetProductMinQuantity, getColors, isDigital } from '../../../../core/models/entities/BaseLogo';
import { DigitalColorElement } from '../../../components/Logo/DigitalColorElement';
import { PriceLine } from '../../../components/Logo/LogoPrices';
import { VectorColorElement } from '../../../components/Logo/VectorColorElement';
import { CartItemLogo, QuantityRow } from '../models/CartItem'
import { LogoQuantitiesColumn, LogoQuantityRow } from './LogoQuantitiesColumn';
import { useOrderActionHandler } from '../hooks/useOrderActionsHandler';
import { useUploadDialog } from './UploadDialog';
import { LogoTemplate } from '../../../../core/models/entities/DesignTemplate';
import { Stepper } from '../../../components/Buttons/Stepper';

const getEmptyQuantityRow = (): QuantityRow => ({ label: '', quantity: 1 })

export function ProductDetailsLogo({ orderLine }: { orderLine: CartItemLogo }) {
  const logo = orderLine.baseLogo

  const { handleLogoSetQuantities } = useOrderActionHandler();

  const toDelivery = orderLine.toDelivery ?? 0
  const toStorage = orderLine.toStorage ?? 0

  const size = logo.transferMethod.size;
  const type = logo.logoType;
  const method = logo.transferMethod.key;
  const storage = logo.inStorage
  const prices = logo.product?.prices.sort((a, b) => a.minQuantity - b.minQuantity) ?? []

  const isNumeric = logo.logoType === 'NumberLogo'
  const numberOrName = isNumeric ? 'Number' : 'Name'
  const hasCustomizableQuantity = ['NumberLogo', 'NameLogo'].includes(logo.logoType)

  const [logosToStorage, setLogosToStorage] = useState<number>(toStorage)
  const [logosToDelivery, setLogosToDelivery] = useState<number>(toDelivery)

  const getUpdateQuantityState = (setQuantityState: (quantity: number) => void) =>
    (value: number) => setQuantityState(value)

  let colorsElements = getColorsElements(logo);
  let highlightedMinQuantity: number | undefined = GetProductMinQuantity(prices, toDelivery + toStorage)
  const initialDeliveryRow: QuantityRow = {
    label: orderLine.value?.toString() ?? '',
    quantity: orderLine.toDelivery ?? 0
  }

  const { handleUploadLogoTemplate, updateLogoOrderLine } = useOrderActionHandler()
  const [deliveryRows, setDeliveryRows] = useState<QuantityRow[]>(orderLine.deliveryQuantities ?? [initialDeliveryRow])
  const [storageRows, setStorageRows] = useState<QuantityRow[]>(orderLine.storageQuantities ?? [])

  useEffect(() => {
    setLogosToDelivery(orderLine.toDelivery ?? 0)
    setLogosToStorage(orderLine.toStorage ?? 0)
    if (!hasCustomizableQuantity) {
      setDeliveryRows(orderLine.deliveryQuantities ?? [initialDeliveryRow])
      setStorageRows(orderLine.storageQuantities ?? [])
    }
  }, [orderLine.id])

  useEffect(() => {
    if (hasCustomizableQuantity) return
    // Two different approaches to handling quantities on different levels dictate 
    //  that we enable the appropriate quantity feature depending on the type of logo.
    handleLogoSetQuantities(logo, logosToDelivery, logosToStorage)
  }, [logosToDelivery, logosToStorage])

  const mapLogoTemplate = (template: LogoTemplate, setRowState: (rows: QuantityRow[]) => void) => {
    if (!Array.isArray(template.logos)) return
    else {
      const logos = template.logos.map((item) => ({
        label: item.value,
        quantity: item.quantity
      }))
      setRowState(logos)
      return logos
    }
  }

  const orgId = orderLine.baseLogo.customer.id
  const { component: deliveryDialog, open: openDeliveryDialog } = useUploadDialog({
    orgId,
    typeString: 'logos',
    onUpload: async (orgId, file) => {
      const output: LogoTemplate = await handleUploadLogoTemplate(orgId, file)
      const logos = mapLogoTemplate(output, setDeliveryRows)
      updateLogoOrderLine(orderLine.baseLogo, logos)
    },
  })

  const { component: storageDialog, open: openStorageDialog } = useUploadDialog({
    orgId,
    typeString: 'logos',
    onUpload: async (orgId, file) => {
      const output = await handleUploadLogoTemplate(orgId, file)
      const logos = mapLogoTemplate(output, setStorageRows)
      updateLogoOrderLine(orderLine.baseLogo, undefined, logos)
    },
  })

  const updateRowGeneric = (
    index: number,
    rowState: QuantityRow[],
    setRowState: (rows: QuantityRow[]) => void,
    update: (row: QuantityRow) => void,
  ) => {
    const row = rowState[index]
    if (!row) return
    update(row)
    const updatedRows = [...rowState]
    updatedRows[index] = row
    // Remove all rows with 0 or less quantity.
    const filteredRows = updatedRows.filter((row) => row.quantity > 0)
    setRowState(filteredRows)
    return filteredRows
  }

  const updateDeliveryRow = (index: number, update: (row: QuantityRow) => void) => {
    const rows = updateRowGeneric(index, deliveryRows, setDeliveryRows, update)
    updateLogoOrderLine(logo, rows, undefined)
  }

  const updateStorageRow = (index: number, update: (row: QuantityRow) => void) => {
    const rows = updateRowGeneric(index, storageRows, setStorageRows, update)
    updateLogoOrderLine(logo, undefined, rows)
  }

  const getAddRow = (
    rowState: QuantityRow[],
    setRowState: (rows: QuantityRow[]) => void,
  ) => () => {
    setRowState([
      ...rowState,
      getEmptyQuantityRow(),
    ])
  }

  const addStorageRow = getAddRow(storageRows, setStorageRows)
  const addDeliveryRow = getAddRow(deliveryRows, setDeliveryRows)

  return <>
    {deliveryDialog}
    {storageDialog}
    <div className={"grid grid-cols-2 gap-4"}>
      <div>
        <div className={'block text-base font-bold uppercase'}>Details</div>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Logo Name</p>
          <p className={'uppercase inline'}>{orderLine.title}</p>
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Size</p>
          <p className={'uppercase inline'}>{size}</p>
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Type</p>
          <p className={'uppercase inline'}>{type}</p>
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Method</p>
          <p className={'uppercase inline'}>{method}</p>
        </span>
        <span className='block pt-1 text-xs flex flex-row'>
          <p className={'font-bold capitalize inline pr-1'}>Colors</p>
          {colorsElements}
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>In Storage</p>
          <p className={'uppercase inline'}>{storage}</p>
        </span>
      </div>

      <div>
        <div className={'block text-base font-bold uppercase'}>Prices</div>
        <div className={"grid grid-rows-5 grid-flow-col gap-0"}>
          {prices.map((p, i) => (
            <span key={i} className={`pt-1 text-xs capitalize inline pr-1 ${highlightedMinQuantity == p.minQuantity ? "font-bold" : ""}`}>
              {PriceLine({ price: p })}
            </span>
          ))}
        </div>
      </div>

      {hasCustomizableQuantity && <div>
        <span className="block pt-1 text-xs mb-2">
          <p className="block text-base font-bold uppercase">To Delivery</p>
        </span>
        <LogoQuantitiesColumn
          title={numberOrName}
          actionLabel={'Add ' + numberOrName}
          onAction={addDeliveryRow}
          onUpload={openDeliveryDialog}
        >
          {deliveryRows.map((item, index) =>
            <LogoQuantityRow
              key={index}
              isNumeric={isNumeric}
              label={item.label}
              onLabelChange={(label) => updateDeliveryRow(index, (row) => {
                row.label = label
              })}
              quantity={item.quantity}
              onQuantityChange={(q) => updateDeliveryRow(index, (row) => {
                row.quantity = q
              })}
            />
          )}
        </LogoQuantitiesColumn>
      </div>}

      {hasCustomizableQuantity && !isNumeric && <div>
        <span className="block pt-1 text-xs mb-2">
          <p className="block text-base font-bold uppercase">To Storage</p>
        </span>
        <LogoQuantitiesColumn
          title={numberOrName}
          actionLabel={'Add ' + numberOrName}
          onAction={addStorageRow}
          onUpload={openStorageDialog}
        >
          {storageRows.map((item, index) =>
            <LogoQuantityRow
              key={index}
              isNumeric={isNumeric}
              label={item.label}
              onLabelChange={(label) => updateStorageRow(index, (row) => {
                row.label = label
              })}
              quantity={item.quantity}
              onQuantityChange={(q) => updateStorageRow(index, (row) => {
                row.quantity = q
              })}
            />
          )}
        </LogoQuantitiesColumn>
      </div>}

      {!hasCustomizableQuantity && <div>
        <div className={'block text-base font-bold uppercase'}>QTY</div>
        <span className='block pt-1 text-xs'>
          <p className={'uppercase inline pr-1'}>Logos to storage</p>
        </span>
        <Stepper onValueChange={getUpdateQuantityState(setLogosToStorage)} min={0} value={logosToStorage} />
        <span className='block pt-1 text-xs'>
          <p className={'uppercase inline pr-1'}>Logos to delivery</p>
        </span>
        <Stepper onValueChange={getUpdateQuantityState(setLogosToDelivery)} min={0} value={logosToDelivery} />
      </div>}

      {(logo.product?.additionalFees?.length ?? 0) > 0 && <div>
        <div className={'block text-base font-bold uppercase'}>EXTRA FEES</div>
        <ol>{(logo.product?.additionalFees ?? []).map((fee, i) =>
          <li key={`${fee.itemNo}_${i}`} >{fee.name} ({fee.unitPrice} DKK x {fee.quantity})</li>)}
        </ol>
      </div>}
    </div>
  </>
}

export function getColorsElements(logo: BaseLogo) {
  const { colors: colorOptions } = useLogoOptionsContext()
  const colors = getColors(logo)
  const pantoneColors = colorOptions.filter(co => colors.find(c => c === co.id))
  const colorsElements = isDigital(logo) ? <DigitalColorElement /> :
    pantoneColors.map(co => <VectorColorElement key={co.id} showName={false} color={co} />)

  return (
    <div className='flex flex-row'>
      {colorsElements}
    </div>
  )
}

export function getMethodDisplay(logo: BaseLogo) {
  const transferMethods = [(logo.transferMethod.method1.toLocaleLowerCase() ?? 'N/A'), logo.transferMethod.method2.toLocaleLowerCase(), logo.transferMethod.method3.toLocaleLowerCase()].filter(x => x?.length > 0)
  return <span className="capitalize">{transferMethods.join(", ")}</span>
}

// export const DetailsHeader = ({children}: HTMLElement) =>
//   <div className={'block text-base font-bold uppercase'}>{children}</div>