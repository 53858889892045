import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import SortByFieldEvent from '../Models/sortByFieldEvent'
import { SortDirection } from '../Models/sortDirection'
import { JSX } from 'react'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'

export interface ColumnField {
  value: string
  canSortBy: boolean
  displayValue: string
  weight: number
  hide?: boolean
}

enum SortState {
  ASC, DESC, OFF
}

const disabledColor = 'text-gray-200'
const defaultColor = 'text-gray-600'

function matchColorsToState(state: SortState, enabeledColor: string) {
  switch (state) {
    case SortState.ASC:
      return {
        up: enabeledColor,
        down: disabledColor
      }
    case SortState.DESC:
      return {
        up: disabledColor,
        down: enabeledColor
      }
    case SortState.OFF:
      return {
        up: defaultColor,
        down: defaultColor
      }
  }
}


function getStateBySortBy(column: ColumnField, sortBy: SortByFieldEvent): SortState {
  if (sortBy.value === column.value && sortBy.sortDirection === SortDirection.DESC) {
    return SortState.DESC
  }
  if (sortBy.value === column.value && sortBy.sortDirection === SortDirection.ASC) {
    return SortState.ASC
  }
  return SortState.OFF

}

interface ColumnProps {
  column: ColumnField,
  setSortByField: (value: (((prevState: SortByFieldEvent) => SortByFieldEvent) | SortByFieldEvent)) => void | undefined,
  sortByField: SortByFieldEvent,
  onNewSort: (sortByFiled: SortByFieldEvent) => void | undefined
}

export function Column(
  {
    column,
    setSortByField,
    sortByField,
    onNewSort
  }: ColumnProps
): JSX.Element {
  const { whiteLabel } = useWhiteLabelContext()
  const state = getStateBySortBy(column, sortByField)
  const color = matchColorsToState(state, whiteLabel.colorHex)

  function sortOnField() {
    if (!column.canSortBy) {
      return
    }
    let field = column.value
    let direction = sortByField.sortDirection
    if (state === SortState.OFF) {
      direction = SortDirection.DESC
    }
    if (state === SortState.DESC) {
      direction = SortDirection.ASC
    }
    if (state === SortState.ASC) {
      direction = undefined
      field = ''
    }
    const nextSort = { value: field, sortDirection: direction }
    setSortByField(nextSort)
    onNewSort(nextSort)
  }



  return (
    <div onClick={sortOnField}
      className={`flex h flex-row w-fit items-center ${column.canSortBy ? 'cursor-pointer' : 'cursor-default'}`}>
      <p className='text-xs font-bold'>{column.displayValue.toUpperCase()}</p>
      {column.canSortBy ? <div className='flex text-xxs flex-col justify-center ml-2.5'>
        <FontAwesomeIcon color={color.up} icon={faCaretUp} />
        <FontAwesomeIcon color={color.down} icon={faCaretDown} />
      </div> : <></>}
    </div>
  )
}

const columnSlots = 12

export default Column
