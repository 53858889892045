import { ExternalApprovalFlow, LoadingFrame } from '../external-approval-design/ExternalApprovalFlow'
import { useParams, useSearchParams } from 'react-router-dom'
import { usePublicApiClient } from '../external-approval-design/use-public-api.client'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { ErrorView } from '../../../components/error-view/ErrorView'
import { InvalidRoute } from '../external-approval-design/external-approval-design.screen'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { mapToLogo } from '../../../../data/models/mappers/mapLogo'

export function ExternalApprovalLogoScreen({ readOnly }: { readOnly?: boolean }) {
  const [qp] = useSearchParams()
  const params = useParams()
  
  const code = qp.get('code')
  const logoId = params?.logoId
  
  if (!(code && logoId)) return <InvalidRoute />

  const { getLogo } = usePublicApiClient()
  const { dataTransition, state } = useComponentStateMachine()
  const { isError, isLoading, isSuccess, data } = getLogo(+logoId!, code!)
  dataTransition(isLoading, isSuccess, isError)
  
  const { placeVerdictLogo } = usePublicApiClient()
  
  async function handleVerdict(approval: boolean, note?: string) {
    if(!(code && logo)) return
    return placeVerdictLogo(logo.id, approval, note, code)
  }
  if (state === ComponentState.Failed) {
    return <ErrorView />
  }

  if (state === ComponentState.Loading) {
    return <LoadingFrame />
  }

  const logo = mapToLogo(data!)

  return <ExternalApprovalFlow readOnly={readOnly} placeVerdict={handleVerdict} subject={'Logo'}>
    <LogoImage
      className={'min-h-[325px] min-w-[450px] max-h-[650px] max-w-[900px] object-scale-down bg-white rounded-bl'}
      logo={logo} useHighRes={true}
    />
  </ExternalApprovalFlow>
}
